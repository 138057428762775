import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { createdBusiness } from "../../interfaces/business";
import BusinessSwitcherBox from "../BusinessSwitcherBox";
const BusinessSwitcher = () => {
  const location = useLocation();

  interface locationState {
    business: createdBusiness[];
  }
  const [business, setBusiness] = useState<createdBusiness[]>([]);
  useEffect(() => {
    const { business } = location.state as locationState;
    setBusiness(business);
  }, []);

  return (
    <div>
      <div className="max-h-96 h-full overflow-y-auto">
        <div className="flex items-center justify-center">
          <div className="flex flex-col space-y-6 xs:mt-6">
            {business.map((business, index) => (
              <div key={index}>
                <BusinessSwitcherBox
                  key={business.businessId}
                  logo={
                    business.business.logo.includes("http")
                      ? business.business.logo
                      : ""
                  }
                  type={business.role}
                  businessname={business.business.name}
                  businessId={business.businessId}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-center">
          <div className="text-xs text-center text-white sm:w-7/12">
            Want more visibility for your business?
            <Link
              to="/auth/register"
              className="px-1 text-blue-500 cursor-pointer"
            >
              Create a business on Varroe.
            </Link>
            <div className="mt-6">
              <p>{"Don't"} see your business?</p>
              <Link
                to="/auth/start/link"
                className="px-1 text-center text-blue-500 cursor-pointer"
              >
                Enter the business code.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSwitcher;
