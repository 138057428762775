import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import MerchantsLogo from '../../src/assets/images/merchantslogo.svg';
const SplashScreen = () => {
  return (
    <div className="relative z-50 flex flex-col items-center h-screen md:justify-center md:flex-row bg-main authlayoutbg">
      <div className="h-">
        <div className="flex flex-col justify-center w-2/5 h-screen mx-auto align-center">
          <h1 className="py-10 font-bold text-center text-white capitalize md:text-xl">
            you’re good to go!
          </h1>
          <img src={MerchantsLogo} alt="background" className="" />
          <Link
            to="/dashboard/home"
            className="flex items-center justify-center text-sm font-bold text-center text-white capitalize md:text-xl pt-44"
          >
            continue to dashboard
            <span className="pl-3">
              <Icon icon="akar-icons:arrow-right" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
