import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components/Base/PageHeader";
import SearchComponent from "../../components/SearchComponent";
import { Icon } from "@iconify/react";
import { Select } from "../../components/Base/Select";
import Pagination from "../../components/Pagination";
import TableLoadingState from "../../components/TableLoadingState";
import useCurrency from "../../hooks/useCurrency";
import { Link } from "react-router-dom";

const orderByList = [
  {
    value: "DESC",
    text: "Most Recent",
  },
  {
    value: "ASC",
    text: "Created First",
  },
];

function ServiceQuote() {
  const [keyword, setKeyword] = useState("");
  const [loading] = useState(false);
  const [quotes] = useState([]);
  const [orderBy, setOrderBy] = useState(orderByList[0].value);
  const handleSearch = (word: string) => {
    setKeyword(word);
  };

  useEffect(() => {}, [keyword]);
  return (
    <div className="h-screen flex flex-col gap-2">
      <PageHeader text={"Service Quote"} />
      <div>
        <div className="flex justify-end w-full">
          <Link to="/dashboard/service-quote/create">
            <button className="bg-[#8B0B0B] p-2 text-white flex gap-2 items-center text-center rounded-lg">
              <Icon icon={"solar:document-outline"} />
              Create Quote
            </button>
          </Link>
        </div>
        <div className="flex flex-wrap items-center justify-between pt-5 space-y-4 lg:space-y-0 mb-3">
          <div className="md:w-1/4">
            <SearchComponent search={handleSearch} />
          </div>
          <div className="ml-auto mr-10 flex items-center gap-4">
            <div className="rounded border-solid border-[1px] p-2 flex text-center justify-center gap-4 text-sm">
              <span>Awaiting Finalization</span>
              <span className={"bg-red-700 rounded badge h-full"}>5</span>
            </div>
            <Select
              options={orderByList}
              value={orderBy}
              width=" w-[11rem] h-2"
              onChange={(e) => {
                const value = e.target.value;
                setOrderBy(value);
              }}
            />
          </div>
        </div>
        <div className="inline-block w-full overflow-x-scroll border rounded-lg">
          <div className=" max-h-[68vh] overflow-auto">
            <table className="w-full text-sm text-left bg-white">
              <thead className="w-fit text-[11px] sm:text-xs font-semibold text-white md:text-sm bg-main">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Service
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>

                  <th scope="col" className="flex items-center px-6 py-3">
                    Time|Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Last Modified
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date Created
                  </th>
                </tr>
              </thead>

              {loading ? (
                <TableLoadingState text="Getting Appointments..." fetching />
              ) : !quotes.length ? (
                <TableLoadingState text="You have no Appointments" />
              ) : (
                <>
                  <tbody>
                    {quotes.length &&
                      quotes.map((content: any) => (
                        <tr
                          className="sm:text-xs text-[11px] border-t md:text-[13px] cursor-pointer"
                          key={content.id}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <th
                            scope="row"
                            className="px-4 py-2 font-medium text-gray-900 md:px-6 md:py-4 whitespace-nowrap "
                          >
                            {content.customer &&
                              content.customer?.firstName +
                                " " +
                                content.customer?.lastName}
                          </th>
                          <td className="px-4 py-2 md:px-6 md:py-4">
                            {content.service && content.service.name}
                          </td>
                          <td className="px-4 py-2 md:px-6 md:py-4">
                            {content.price && useCurrency(content.price)}
                          </td>
                          <td className="px-4 py-2 md:px-6 md:py-4">
                            {content.staff?.firstName && content.staff?.lastName
                              ? content.staff?.firstName +
                                " " +
                                content.staff?.lastName
                              : "---"}
                          </td>
                          <td className="px-4 py-2 md:px-6 md:py-4">
                            <div
                              className={`border-2 whitespace-nowrap font-semibold text-xs ${
                                content.status === "PAID"
                                  ? "bg-green-400 border-green-500"
                                  : "bg-orange-200 border-orange-500"
                              }  rounded-full p-1 px-2 text-center`}
                            >
                              {content.status === "PAID"
                                ? "ONLINE"
                                : "IN STORE"}
                            </div>
                          </td>

                          <td className="px-4 py-2 md:px-6 md:py-4">-</td>

                          <td className="flex justify-end py-2 md:py-4">-</td>
                        </tr>
                      ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
          <div className="px-4 py-2 md:px-6 md:py-4 border-t ">
            <Pagination
              next={() => {}}
              prev={() => {}}
              showFrom={0}
              totalRecords={0}
              showTo={0}
              currentPage={1}
            />
          </div>

          {/* <AppointmentList
        appointments={modalProps}
        modalOpen={modalOpen}
        selectedDate={selectedDate}
        closeModal={() => {
          setModalOpen(!modalOpen);
          setAppointmentModalOpen(false);
        }}
        openOtherModal={(appointment: appointmentsType) => {
          setAppointmentModalProps(appointment);
          setAppointmentModalOpen(true);
          setModalOpen(false);
        }}
      /> */}
        </div>
      </div>
    </div>
  );
}

export default ServiceQuote;
