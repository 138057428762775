import { Icon } from "@iconify/react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Button } from "../components/Base/Button";
import { PageHeader } from "../components/Base/PageHeader";
import DashboardReviews from "../components/DashboardReviews";
import EmptyReviews from "../components/EmptyReviews";
import HomeFeatures from "../components/HomeFeatures";
import ModalContainer from "../components/Modals/ModalContainer";
import OverlaySpinner from "../components/OverlaySpinner";
import { SkeletonImageFull } from "../components/SkeletonImage";
import Statistics from "../components/Statistics";
import { selectAppointments } from "../features/appointment/appointment";
import {
  selectDashboard,
  SET_DASHBOARD_REVIEWS,
  SET_DASHBOARD_SUMMARY,
} from "../features/pages/pages";
import useMeridem from "../hooks/useMeridem";
import { appointmentsType } from "../interfaces/appointments";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useTour } from "@reactour/tour";
import { SET_TOUR } from "../features/tour/tour";
import { UserAttributesI } from "../interfaces/user";

const Home = () => {
  const { setIsOpen } = useTour();
  const userRole = useAppSelector((state) => state.auth.role);
  useEffect(() => {
    handleTour();
  }, []);
  const dispatch = useAppDispatch();
  const handleTour = async () => {
    try {
      // check if user is authenticated
      const token = localStorage.getItem("merchant-api-token");
      if (token) {
        const {
          data: { data },
        } = await axios.get<
          unknown,
          AxiosResponse<{
            status: string;
            data: {
              user: UserAttributesI;
            };
          }>
        >(`/api/authenticate`);
        // check if the user has done the tour before
        const myTour = data.user.hints.find(
          (hint: any) => hint.role === userRole
        );
        if (!myTour) return;
        dispatch(SET_TOUR(myTour));
        if (!myTour.seen) {
          setIsOpen(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const user = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsloading] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState<
    appointmentsType[]
  >([]);

  const { formatTime } = useMeridem();
  const appointments = useAppSelector(selectAppointments);
  const storeDashboard = useAppSelector(selectDashboard);
  const getReviews = async () => {
    try {
      !storeDashboard.reviews.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/review`);
      dispatch(SET_DASHBOARD_REVIEWS(data));
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const getHomeSummary = async () => {
    try {
      !storeDashboard.summary.totalRevenue && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/analytics/dashboard`);
      dispatch(SET_DASHBOARD_SUMMARY(data));
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("merchant-api-token");

    if (!token) return;
    getReviews();
    getHomeSummary();
    setPendingAppointments(
      appointments.filter((appointment) => appointment.state !== "APPROVED")
    );
  }, []);
  const businessId = useAppSelector((state) => state.business.business.id);
  const businessName = useAppSelector((state) => state.business.business.name);
  return (
    <>
      {isLoading ? (
        <OverlaySpinner isLoading={isLoading} noBackground>
          <div className="min-h-screen"></div>
        </OverlaySpinner>
      ) : (
        <div>
          <div className="flex justify-between items-center pt-5">
            <PageHeader text={`Hi ${user.first_name}!`} />
            <div>
              <Button
                onClick={() => {
                  setShareModal(true);
                }}
              >
                <Icon icon="ph:share-network" width={25} />
                <span className="ml-3 py-1">Share your Business</span>
              </Button>
            </div>
          </div>

          <div className="flex items-center w-full space-x-3 overflow-x-scroll customscrollbar ">
            <HomeFeatures />
          </div>

          {isLoading ? (
            <div className="my-10">
              <SkeletonImageFull />
            </div>
          ) : (
            <Statistics
              appointments={pendingAppointments.length}
              summary={storeDashboard.summary}
            />
          )}
          <div>
            <div>
              <p className="text-sm font-semibold md:text-base">
                Recent Reviews
              </p>

              <div className="w-full my-3 space-y-3 overflow-x-scroll">
                {isLoading ? (
                  <SkeletonImageFull />
                ) : !storeDashboard.reviews.length ? (
                  <div className="mt-10">
                    <EmptyReviews />
                  </div>
                ) : (
                  storeDashboard.reviews.map((review, reviewIndex) => (
                    <DashboardReviews
                      key={reviewIndex}
                      rating={review.rating}
                      review={review.comment}
                      date={formatTime(review.createdAt)}
                      customername={
                        review.user.firstName + " " + review.user.lastName
                      }
                    />
                  ))
                )}
              </div>
            </div>
          </div>
          <ModalContainer
            size="small"
            modalOpen={shareModal}
            closeModal={() => {
              setShareModal(false);
              setShowCheckIcon(false);
            }}
            radius=" rounded-lg "
          >
            <div className="flex flex-col items-center justify-center">
              <p className="subscriptionFont text-3xl font-semibold mb-2">
                Expand your audience
              </p>
              <p className="interFamily text-xl">
                Share your business with your clients
              </p>
              <div className="flex space-x-9 text-secondary my-5">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(
                      `https://api.whatsapp.com/send?&text=View ${businessName} on varroe.com and book services fast and reliably  https://varroe.com/ca/${businessId}`,
                      "_blank"
                    );
                  }}
                >
                  <Icon icon="ph:whatsapp-logo-duotone" width={60} />
                </div>
                <div className="cursor-pointer">
                  <a
                    className="twitter-share-button"
                    href={`https://twitter.com/intent/tweet?text=View ${businessName} on varroe.com and book services fast and reliably https://varroe.com/ca/${businessId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon icon="akar-icons:twitter-fill" width={60} />
                  </a>
                </div>
                <div className="cursor-pointer">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://varroe.com/ca/${businessId}`}
                    className="fb-xfbml-parse-ignore"
                    rel="noreferrer"
                  >
                    <Icon icon="ic:sharp-facebook" width={60} />
                  </a>
                </div>
              </div>
              <div className="rounded-full bg-secondary  mt-3 px-6 py-2 text-white flex items-center">
                https://varroe.com/ca/{businessId}{" "}
                {showCheckIcon ? (
                  <div className="ml-3 cursor-pointer flex items-center text-green-600  ">
                    <Icon icon="material-symbols:check" width={25} />{" "}
                    <span className="text-white text-[12px] ml-1">
                      copied to clipboard
                    </span>
                  </div>
                ) : (
                  <span
                    className="ml-3 cursor-pointer "
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://varroe.com/ca/${businessId}`
                      );
                      setShowCheckIcon(true);
                    }}
                  >
                    <Icon icon="radix-icons:copy" />
                  </span>
                )}
              </div>
            </div>
          </ModalContainer>
        </div>
      )}
    </>
  );
};

export default Home;
