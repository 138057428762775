import React, { useEffect, useState } from "react";
import SideIcon from "../../src/assets/images/sidebaricon.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import NavigationRoutes, { NavProps } from "./NavigationItems";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { routeType } from "../interfaces/auth";
import axios from "axios";
import {
  getactiveBusiness,
  getMyBusiness,
  selectBusiness,
} from "../features/business/business";
import { LOGOUT, selectUser } from "../features/auth/auth";
import { SET_APPOINTMENTS } from "../features/appointment/appointment";
import { Toast } from "./Base/toast";
import { EMPTY_ALL_PAGES } from "../features/pages/pages";
import Avatar from "react-avatar";

export const Navigation = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [gettingBusiness, setGettingBusiness] = useState(false);
  const [allAppointments, setallAppointments] = useState<any>([]);
  const activeBusiness = useAppSelector(selectBusiness);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.auth.role);
  const userId = useAppSelector((state) => state.business.id);
  const [isChecked, setIsChecked] = useState(false);
  const currentLocation = useLocation();
  const loggedInUser = useAppSelector(selectUser);
  const subStatus = useAppSelector(
    (state) => state.business.business.subscription
  );
  const getAppointments = async () => {
    try {
      let url = `/api/appointment?where={"state": ["PENDING","APPROVED"],"status": ["PAID","RESERVED"]}&limit=9999`;
      if (userRole === "staff") {
        url = `/api/appointment?where={"state": ["PENDING","APPROVED"],"status": ["PAID","RESERVED"], "staffId": ["${userId}"]}&limit=9999`;
      }
      const {
        data: { data },
      } = await axios.get(url);
      setallAppointments(
        data.filter((appointment: any) => appointment.state !== "APPROVED")
      );
      dispatch(SET_APPOINTMENTS(data));
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    setIsChecked(activeBusiness.live);
    getAppointments();
  }, [activeBusiness]);
  useEffect(() => {
    getAppointments();
  }, [currentLocation]);
  const navLinkStyles = ({ isActive }: { isActive: Boolean }) => {
    return {
      boxShadow: isActive ? "inset 8px 0 0px -6px #fff" : "none",
    };
  };
  const handleChange = async (event: { target: { checked: any } }) => {
    const payload = Object.assign({}, { enabled: false });

    if (event.target.checked) {
      payload.enabled = true;
    } else {
      payload.enabled = false;
    }
    try {
      const { data } = await axios.patch(`/api/me/business/live`, payload);
      if (typeof data.data !== "boolean") {
        Toast(data.message, "warning");
        setTimeout(() => {
          window.open(data.data);
        }, 4000);
      }
    } catch (e) {
      console.error(e);
    } finally {
      const data = await dispatch(getactiveBusiness());
      setIsChecked(data.payload.business.live);
    }
  };
  const roleAccess = (route: routeType) => {
    const staffOnlyPages = ["home", "appointments"];
    // hide the staff route on the dashboard for certain users
    if (userRole === "freelancer" && route.name !== "staff") return true;
    if (userRole === "staff" && staffOnlyPages.includes(route.name))
      return true;
    if (userRole === "merchant") return true;
    else {
      return false;
    }
  };
  const handleLogout = () => {
    dispatch(LOGOUT());
  };
  const getBusiness = async () => {
    try {
      setGettingBusiness(true);

      const response = await dispatch(getMyBusiness());

      // has multiple businesses
      dispatch(EMPTY_ALL_PAGES());
      navigate("/auth/start/businesses", {
        state: { business: response.payload },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setGettingBusiness(false);
    }
  };
  const showRoutes = (route: NavProps) => {
    if (route.name === "CRM") {
      // get subscrbtion status
      if (subStatus && subStatus?.plan.name.toLowerCase() === "free") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  return (
    <div
      className={`h-screen bg-main  duration-500 sidebar overflow-auto relative flex flex-col justify-between pb-3 ${
        visible ? "w-[18rem]" : "w-20"
      }`}
    >
      <div className="">
        <div className={`${visible ? "mx-5" : "mx-4"}`}>
          <div
            className={
              "flex items-center  py-4  px-3 mt-10 bg-white rounded cursor-pointer" +
              (activeBusiness.logo ? " justify-center " : " justify-start ")
            }
            onClick={() => setVisible(!visible)}
          >
            {!!activeBusiness.logo && (
              <img
                src={activeBusiness.logo ? activeBusiness.logo : SideIcon}
                alt="icon"
                className={` ${visible ? "mr-3" : ""} fixedWidth`}
              />
            )}

            <p
              className={`cursor-pointer truncate text-xs  font-semibold uppercase ${
                visible ? "  " : " hidden "
              }`}
            >
              {activeBusiness.name ? activeBusiness.name : ""}
            </p>
          </div>
          {userRole !== "staff" && (
            <div className="flex items-center mt-3">
              <div className="cursor-pointer checkbox">
                <input
                  type="checkbox"
                  id="switch"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onChange={handleChange}
                  checked={isChecked}
                />
                <label htmlFor="switch">toggle</label>
              </div>
              <p
                className={`truncate mt-6 mb-0 ml-4 text-xs text-white ${
                  visible ? "block" : "hidden"
                }`}
              >
                {isChecked ? "Store is live" : "Store is offline"}
              </p>
            </div>
          )}
        </div>
        <div className="mt-6">
          <div className="f">
            <ul className="text-white">
              {NavigationRoutes.map((item, index) => (
                <React.Fragment key={index}>
                  {showRoutes(item) && (
                    <li
                      key={index}
                      className={`${
                        item.gap ? "mt-6" : ""
                      } flex py-1 items-center ${
                        visible ? "" : "justify-center text-center "
                      } ${roleAccess(item) ? "" : "hidden"} ${item.class}`}
                    >
                      <NavLink
                        to={item.path}
                        style={navLinkStyles}
                        className={`flex cursor-pointer ${
                          visible ? "" : "justify-center"
                        } hover:bg-blue-600 w-full px-2 py-2.5`}
                      >
                        <span
                          className={`mt-1  ${
                            visible
                              ? "mr-4 mx-5"
                              : "flex justify-center text-center"
                          }`}
                        >
                          <Icon icon={item.icon} width={13} />
                        </span>
                        <span
                          className={`${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            !visible && "hidden"
                          } text-[13px] capitalize duration-300 truncate`}
                        >
                          {item.name}
                        </span>
                        {item.badge ? (
                          <div className="relative">
                            <div
                              className={`${
                                visible
                                  ? "ml-4 mx-5"
                                  : "absolute h-4 ml-0 -inset-y-2"
                              }  mt-0 text-xs bg-red-700 rounded items-center badge`}
                            >
                              {allAppointments.length}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white flex flex-col space-y-3 p-[9px] rounded-lg m-3 mt-8">
        <div
          className="flex items-center justify-center py-3  bg-main text-white rounded-lg cursor-pointer "
          onClick={() => {
            getBusiness();
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
          <div className={` ${visible && "mr-3"}`}>
            <Icon icon="icon-park-outline:switch" color="white" />
          </div>
          <p
            className={`cursor-pointer truncate text-xs ${
              visible ? "flex" : "hidden"
            }`}
          >
            {gettingBusiness ? "Loading" : "Switch Business"}
          </p>
        </div>
        <div className="flex justify-between  items-center">
          <div className="flex items-center space-x-1">
            <div>
              {loggedInUser.avatarUrl ? (
                <img
                  src={loggedInUser.avatarUrl}
                  alt="avi"
                  className="w-[39px] h-[39px]"
                />
              ) : (
                <Avatar
                  name={loggedInUser.first_name + " " + loggedInUser.last_name}
                  size="39"
                  round={true}
                  textSizeRatio={2.5}
                />
              )}
            </div>
            <div
              className={` max-w-[90px] 
              ${visible ? " flex flex-col " : "hidden"}
              `}
            >
              <p className="truncate font-semibold text-[12px]">
                {loggedInUser.first_name + " " + loggedInUser.last_name}
              </p>
              <span className="truncate text-[9px]">{loggedInUser.email}</span>
            </div>
          </div>
          <div
            className={`mr-1 cursor-pointer 
              ${visible ? " flex flex-col " : "hidden"}
              
              `}
            onClick={() => handleLogout()}
          >
            <Icon icon="tabler:logout" width="25" color="#8B0B0B" />
          </div>
        </div>
      </div>
    </div>
  );
};
