import { useEffect, useState } from "react";
import axios from "axios";
import Table from "../components/Table";
import AddStaffModal from "../components/Modals/AddStaffModal";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectStaffs, SET_STAFFS } from "../features/pages/pages";

import { StaffInvites } from "../components/StaffCard";
const Staff = () => {
  const dispatch = useAppDispatch();
  const storeStaffs = useAppSelector(selectStaffs);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [pageLimit] = useState<number>(9);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [showFrom, setShowFrom] = useState(1);
  const [showTo, setShowTo] = useState(1);
  const [recordCount, setRecordCount] = useState<number>(2);

  const getAllStaff = async () => {
    try {
      setIsloading(true);
      !storeStaffs.length && setFetch(true);
      const response = await axios.get(
        `/api/staff?limit=${pageLimit}&page=${currentPage}`
      );
      setRecordCount(response.data.metadata.pagination.totalCount);
      handlePagination();

      dispatch(SET_STAFFS(response.data.data));
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
      setFetch(false);
    }
  };
  const handlePagination = () => {
    setShowFrom((currentPage - 1) * pageLimit + 1);
  };
  const handleshowTo = () => {
    if (showFrom + pageLimit > recordCount) {
      // if the request perpage is more than the total number of record return the total number of records instead
      return recordCount;
    } else if (currentPage === 1) {
      return pageLimit;
    } else {
      return showFrom + pageLimit;
    }
  };
  useEffect(() => {
    handlePagination();
  }, [recordCount]);
  useEffect(() => {
    setShowTo(handleshowTo());
  }, [showFrom, recordCount]);
  useEffect(() => {
    getAllStaff();
  }, [refreshPage, currentPage]);

  return (
    <div className="h-full overflow-x-hidden">
      {/* <PageHeader
        text="staff"
        buttontext="new staff"
        buttonevent={() => setModalOpen(!modalOpen)}
      /> */}
      <div className="flex w-full space-x-10">
        <div className=" w-[70%]">
          <h2 className="font-sans text-lg font-semibold text-left capitalize md:text-2xl font-outfit mb-5">
            Staff
          </h2>

          <Table
            staffs={storeStaffs}
            fetch={fetch}
            isLoading={isLoading}
            refresh={() => {
              setRefreshPage((previous) => !previous);
            }}
            from={showFrom}
            to={showTo}
            records={recordCount}
            prev={() => setCurrentPage((previous) => previous - 1)}
            next={() => setCurrentPage((previous) => previous + 1)}
            currentPage={currentPage}
          />
        </div>
        <div className="flex flex-col justify-start w-[30%]">
          <h2 className="font-sans text-lg font-semibold text-left capitalize md:text-2xl font-outfit mb-5">
            Pending Invitations
          </h2>

          <StaffInvites
            reload={refreshPage}
            inviteStaff={() => {
              setModalOpen(!modalOpen);
            }}
          />
        </div>
      </div>

      <AddStaffModal
        modalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(!modalOpen);
          setRefreshPage((previous) => !previous);
        }}
      />
    </div>
  );
};

export default Staff;
