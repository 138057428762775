import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import { appointmentsType } from "../../interfaces/appointments";

// Define the initial state of the application
interface appointmentState {
  appointments: appointmentsType[];
}
const initialState: appointmentState = {
  appointments: [],
};

export const appointmentSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    SET_APPOINTMENTS: (state, action: PayloadAction<appointmentsType[]>) => {
      state.appointments = [...action.payload];
    },
  },
});

export const { SET_APPOINTMENTS } = appointmentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAppointments = (state: RootState) =>
  state.appointment.appointments;

export default appointmentSlice.reducer;
