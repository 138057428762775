/* eslint-disable no-unreachable */
import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import useCurrency from "../../hooks/useCurrency";
import { Button } from "../Base/Button";
import AddServiceModal from "./AddServiceModal";
import DeleteModal from "./DeleteModal";
import ModalContainer from "./ModalContainer";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  openOtherModal?: any;
  service?: any;
  refresh?: any;
}

const ServiceModal = (props: Props) => {
  const formatTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (totalMinutes < 60) {
      return `${totalMinutes} minutes`;
    } else {
      return `${hours} hours${minutes > 0 ? ` ${minutes} minutes` : ""}`;
    }
  };

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const saveService = async (id: number, service: any) => {
    setisUpdating(true);
    try {
      delete service.images;
      const payload = { ...service };
      await axios.put(`/api/business/service/${id}`, payload);
      setEditModalOpen(false);
      props.closeModal();
    } catch (error: any) {
      console.error("error", error.response.data.errors);
    } finally {
      props.refresh(true);
      setisUpdating(false);
    }
  };
  const deleteService = async (id: number) => {
    try {
      setSubmit(true);
      await axios.delete(`/api/business/service/${id}`);
      props.refresh(true);
      props.closeModal(true);
      setSubmit(false);
    } catch (error) {
      console.error(error);
      setSubmit(false);
    }
  };

  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="large"
    >
      <div className="mt-3 sm:mt-0 sm:text-left">
        <div className="grid grid-cols-5 gap-6">
          <div className="col-span-3 space-y-4 overflow-auto max-h-96">
            <div className="p-3 md:p-5 bg-[#f1f1f1] rounded-lg">
              <div className="w-full my-2 mr-5 md:mr-0 sm:w-auto">
                <p className="text-sm font-semibold capitalize break-words md:text-xl">
                  {props.service.name}
                </p>

                <p className="my-3 text-xs break-words sm:text-sm">
                  {props.service.description}
                </p>
              </div>
            </div>

            <div className="flex justify-between w-full">
              <div className="w-full space-y-3">
                <div className="space-y-3">
                  {props.service.addons.length <= 0 ? (
                    <p className="text-sm font-semibold">
                      You have no addons for this service.
                    </p>
                  ) : (
                    <h3 className="text-lg font-semibold ">Add Ons</h3>
                  )}

                  {props.service.addons &&
                    props.service.addons.map((addon: any) => (
                      <div className="flex justify-between" key={addon.id}>
                        <p className="text-xs font-normal">{addon.name}</p>
                        <p className="text-xs font-normal">
                          + {useCurrency(addon.price)}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full col-span-2 overflow-hidden">
            <div className="flex">
              {props.service.images.length > 0 &&
                props.service.images.map((image: any, imageIndex: number) => (
                  <img
                    key={imageIndex}
                    src={image.gallery.file}
                    alt="staff"
                    className="object-cover w-full h-44 rounded-xl"
                  />
                ))}
            </div>

            <div className="flex justify-center col-span-1 mb-4">
              <div className="w-full space-y-4">
                <div className="my-4 space-y-2 text-base">
                  <div className="flex items-center space-x-2">
                    <Icon icon="heroicons-outline:currency-dollar" />
                    <p className="text-xl font-semibold">
                      {useCurrency(props.service.price)}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 font-normal">
                    <Icon icon="bx:bx-time-five" />
                    <p>{formatTime(props.service.duration)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          modalOpen={deleteModalOpen}
          closeModal={() => {
            setDeleteModalOpen(false);
          }}
          title={props.service.name}
          type="Service"
          size="small"
          delete={() => deleteService(props.service.id)}
          isSubmitting={submit}
        />

        <AddServiceModal
          modalOpen={editModalOpen}
          closeModal={() => {
            setEditModalOpen(!editModalOpen);
          }}
          isEdit
          service={props.service}
          saveService={saveService}
          refresh={() => {
            props.refresh();
          }}
          isUpdating={isUpdating}
        />
        <div className="flex pb-5 sm:pb-0 sm:mt-4">
          <Button onClick={() => setEditModalOpen(!editModalOpen)}>Edit</Button>
          <Button
            onClick={() => setDeleteModalOpen(!deleteModalOpen)}
            variant="bg-danger ml-4"
          >
            Delete
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ServiceModal;
