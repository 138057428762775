import React, { useEffect, useState } from "react";

interface InputProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  accept?: string;
  value?: string;
  id?: string;
  name?: string;
  defaultValue?: string;
  clearState?: any;
}

export const CustomizationInput: React.FC<InputProps> = ({
  label,
  placeholder = label,
  onChange,
  type = "text",
  disabled,
  required,
  accept,
  value,
  id,
  name,
  defaultValue,
  clearState,
}) => {
  const [state, setState] = useState<any>("");
  useEffect(() => {
    setState(value);
  }, []);
  useEffect(() => {
    setState("");
  }, [clearState]);
  const handleChange = (e: any) => {
    setState(e.target.value);
    onChange && onChange(e);
  };
  return (
    <>
      <div className="flex flex-col w-full">
        <label className="mb-2 text-[10px] text-left text-black">{label}</label>
        <input
          className="w-auto h-auto px-2 py-2 text-sm border border-solid rounded-md placeholder:text-gray-400 border-darkergrey bg-lightergrey focus:outline-0 focus:border-blue-300  disabled:bg-gray-200 disabled:cursor-not-allowed"
          placeholder={placeholder}
          onChange={handleChange}
          type={type}
          disabled={disabled}
          required={required}
          accept={accept}
          value={state}
          id={id}
          name={name}
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
};
