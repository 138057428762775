/* eslint-disable react/no-children-prop */
import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthRoute from "../components/AuthRoute";
import routes from "./Routes";
import NoMatch from "../components/NoMatch";

const RouteCombiner = () => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <AuthRoute key={index} path={route.path}>
                <route.Layout children={<route.Layout />} />
              </AuthRoute>
            }
          >
            {route.modules &&
              route.modules.map((page: any) => (
                <Route
                  key={index}
                  path={page.path}
                  element={<page.Component />}
                />
              ))}
          </Route>
        );
      })}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default RouteCombiner;
