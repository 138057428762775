import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { activateBusiness } from "../features/business/business";
import { useAppDispatch } from "../store/hooks";
import OverlaySpinner from "./OverlaySpinner";
interface Props {
  businessname: string;
  logo: any;
  type: any;
  businessId: number;
}

const BusinessSwitcherBox = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setIsloading] = useState(false);

  const handleClick = async () => {
    try {
      setIsloading(true);

      await dispatch(activateBusiness(props.businessId));
      setTimeout(() => {
        navigate("/dashboard/home");
      }, 2000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <OverlaySpinner noSpinner isLoading={loading}>
      <div
        className="flex flex-col justify-center p-4 bg-white rounded cursor-pointer h-14 xs:w-96 w-80"
        onClick={() => handleClick()}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {props.logo && (
              <img src={props.logo} alt="business logo" className="w-8" />
            )}
            <p
              className={
                (props.logo ? "pl-3 " : "pl-10 ") +
                " text-xs font-[700] uppercase font-outfit"
              }
            >
              {props.businessname}
            </p>
          </div>
          <div className="flex">
            <p className="text-[9px] font-[600] uppercase">{props.type}</p>
          </div>
        </div>
      </div>
    </OverlaySpinner>
  );
};

export default BusinessSwitcherBox;
