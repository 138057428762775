import React from "react";

interface RadioInputProps {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  className?: string;
  id?: string;
  value?: string;
  checked?: boolean;
}

export const RadioInput: React.FC<RadioInputProps> = ({
  label,
  onChange,
  disabled,
  name,
  className = "text-[10px]",
  id,
  value,
  checked,
}) => {
  return (
    <>
      <div className="flex items-center space-x-2 customradio">
        <input
          type="radio"
          name={name}
          onChange={onChange}
          disabled={disabled}
          value={value}
          id={id}
          checked={checked}
        />
        <label htmlFor={id} className={`${className} cursor-pointer`}>
          {label}
        </label>
      </div>
    </>
  );
};
