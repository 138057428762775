/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { DateTime } from "ts-luxon";
import { selectAppointments } from "../features/appointment/appointment";
import { appointmentsType } from "../interfaces/appointments";
import { useAppSelector } from "../store/hooks";
import AppointmentBox from "./AppointmentBox";
import EmptyAppointments from "./EmptyAppointments";
import AppointmentModal from "./Modals/AppointmentModal";

const Appointment = () => {
  const [stateAppointments, setStateAppointments] = useState<
    appointmentsType[]
  >([]);
  const storeAppointments = useAppSelector(selectAppointments);
  const [appointmentModalProps, setAppointmentModalProps] =
    useState<appointmentsType>();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setStateAppointments([]);
    storeAppointments.forEach((content: appointmentsType) => {
      // const newDate = DateTime.fromISO(content.date).toFormat("DDD");
      const newDate = DateTime.fromISO(content.date)
        .plus({ days: 1 })
        .toFormat("DDD");
      const today = DateTime.now().toFormat("DDD");
      if (newDate === today) {
        setStateAppointments((prev) => [...prev, content]);
      }
    });
  }, [storeAppointments]);

  const showModals = (content: any) => {
    setAppointmentModalProps(content);
    setModalOpen(true);
  };

  return (
    <div className="z-10 h-[92vh] overflow-auto p-3 my-3 mr-3 bg-white lg:p-6 dropshadow rounded-xl fixed">
      <h2 className="text-sm font-semibold text-left lg:text-base">
        Today’s Appointments
      </h2>

      <div className="mt-6 space-y-3 h-full">
        {stateAppointments.length ? (
          stateAppointments.map((appointment, index) => (
            <AppointmentBox
              key={index}
              appointment={appointment}
              openModal={showModals}
            />
          ))
        ) : (
          <div className=" h-full flex items-center justify-center my-auto">
            <EmptyAppointments />
          </div>
        )}
      </div>
      <AppointmentModal
        hideCancelButton
        modalOpen={modalOpen}
        appointmentModalProps={appointmentModalProps}
        closeModal={() => setModalOpen(false)}
      />
    </div>
  );
};

export default Appointment;
