import axios from "axios";
import React, { useState } from "react";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { Textarea } from "../Base/Textarea";
import { Toast } from "../Base/toast";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: any;
  title?: string;
  modalOpen: boolean;
  refresh: () => void;
}
const FaqModal = (props: Props) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const handleCreate = async () => {
    try {
      if (!question || !answer)
        return Toast("Please enter a question and an answer", "warning");
      setisLoading(true);
      const { data } = await axios.post(`/api/faq`, {
        question,
        answer,
      });
      props.closeModal();
      props.refresh();
      await console.log("data", data);
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="small"
    >
      <form className="w-full h-full">
        <div className="p-2 sm:p-3 md:p-5 bg-[#f1f1f1] rounded-lg mr-3 sm:mr-0 flex items-center justify-center sm:justify-start">
          <div className="w-full">
            <div className="py-2">
              <Input
                placeholder="e.g Do you have other locations?"
                type="text"
                label="Question"
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
            </div>
            <div className="py-2">
              <Textarea
                label="Answer"
                placeholder="e.g No, no we do not. But we will be opening another shop in the city of  the city of New Hampshire in the fall of 2023"
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex pt-8 space-x-4">
          <Button
            onClick={() => {
              handleCreate();
            }}
            disabled={isLoading}
          >
            Save
          </Button>
          <Button
            variant="bg-danger"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default FaqModal;
