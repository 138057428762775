import { Icon } from "@iconify/react";
import { appointmentsType } from "../interfaces/appointments";
import timeFormat from "../hooks/useMeridem";
interface propsType {
  appointment: appointmentsType;
  openModal?: (appointment: appointmentsType) => void;
}
const AppointmentBox = (props: propsType) => {
  const { TwentyFourHourFormat } = timeFormat();

  return (
    <div
      className="flex w-full rounded-lg cursor-pointer"
      onClick={() => {
        props.openModal && props.openModal(props.appointment);
      }}
    >
      <div className="w-full p-3 space-y-2 rounded-l-lg xl:p-5 lg:w-9/12 bg-basegrey">
        <p className="text-[9px] sm:text-xs lg:text-[16px] font-semibold text-left">
          {props.appointment.customer.firstName}{" "}
          {props.appointment.customer.lastName}
        </p>

        {props.appointment.service?.name && (
          <p className="text-[7px] sm:text-[9px] text-left font-semibold flex items-center ">
            <span className="pr-1">
              <Icon icon="ic:outline-local-offer" />
            </span>
            {props.appointment.service?.name}
          </p>
        )}

        {props.appointment.staff?.firstName && (
          <p className="text-[7px] sm:text-[9px] text-left font-semibold flex items-center">
            <span className="pr-1">
              <Icon icon="bi:person-badge" />
            </span>
            {props.appointment.staff?.firstName}{" "}
            {props.appointment.staff?.lastName}
          </p>
        )}
      </div>
      <div className="flex items-center justify-center w-1/4 p-2 rounded-r-lg bg-main">
        <h2 className="text-[10px] sm:text-xs lg:text-[16px] text-center text-white font-semibold">
          {TwentyFourHourFormat(props.appointment.timeSlot)}
        </h2>
      </div>
    </div>
  );
};

export default AppointmentBox;
