import React from 'react';
import Spinner from './Base/Spinner';

const TableLoadingState = (props: { text?: string; fetching?: boolean }) => {
  return (
    <tbody>
      <tr className="border-b ">
        <td className="px-4 py-2 md:px-6 md:py-4 w-fit" colSpan={12}>
          <div className="flex justify-center space-x-3">
            <p className="text-xs text-center">{props.text}</p>

            {props.fetching && <Spinner />}
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default TableLoadingState;
