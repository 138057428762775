/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "@iconify/react";
import axios from "axios";
import { useRef, useState } from "react";
import useFileUpload from "../hooks/useFileUpload";
import Spinner from "./Base/Spinner";
import DeleteModal from "./Modals/DeleteModal";
import { GalleryType } from "../interfaces/gallery";
import MakeDefaultModal from "./Modals/MakeDefaultModal";
interface Props {
  preview?: GalleryType;
  refresh?: any;
}

const ImageComponent = (props: Props) => {
  const { preview, refresh } = props;
  const [modalOpen, setDeleteModalOpen] = useState(false);
  const [favouriteModalOpen, setFavouriteModalOpen] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);
  const inputFileRef = useRef<any>(null);
  const { submitFile } = useFileUpload();
  const [submittingFile, setSubmittingFile] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!modalOpen);
  };
  const toggleDefaultModal = () => {
    setFavouriteModalOpen(!favouriteModalOpen);
  };
  const deleteImage = async () => {
    setDeletingImage(true);
    try {
      await axios.delete(`/api/gallery/${preview?.id}`);
      setDeletingImage(false);
      setDeleteModalOpen(false);
      props.refresh(true);
    } catch (e) {
      console.error(e);
    }
  };
  const onChange = async (event: any) => {
    try {
      setSubmittingFile(true);
      const file = event.target.files[0];
      const imageUrl = await submitFile(file);
      if (imageUrl) {
        updateFile(imageUrl);
      }
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };
  const updateFile = async (image: string) => {
    try {
      await axios.put(`/api/gallery/${props.preview?.id}`, {
        file: image,
      });
      setSubmittingFile(false);
      props.refresh(true);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };

  const makeImageDefaultHeader = async () => {
    try {
      setSubmittingFile(true);
      await axios.put(`/api/gallery/${preview?.id}`, {
        type: "header",
        file: preview?.file,
      });
      setSubmittingFile(false);
      setFavouriteModalOpen(false);
      props.refresh(true);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };
  return (
    <div className="col-span-2 md:col-span-1">
      <div className="relative h-44">
        <img
          src={props.preview?.file}
          alt="img"
          className="object-cover w-full h-full rounded-xl"
        />

        <div className="absolute top-5 right-4">
          <div className="flex space-x-2">
            <button
              className="flex items-center p-1 bg-white rounded-sm text-[10px] gap-1 font-semibold"
              onClick={toggleDefaultModal}
              disabled={preview?.type === "header"}
            >
              <span className={"text-[10px] flex items-center font-semibold"}>
                <Icon
                  fill="text-red-900"
                  stroke="text-red-900"
                  icon={
                    preview?.type === "header"
                      ? "solar:star-bold"
                      : "solar:star-broken"
                  }
                  fontSize={"14px"}
                  className={"hover:text-red-900"}
                />
              </span>
              <span>
                {" "}
                {preview?.type === "header" ? " Default" : " Make default"}
              </span>
            </button>

            <div
              className="px-2 py-1 space-x-1 bg-white rounded-sm cursor-pointer"
              onClick={() => {
                inputFileRef.current.click();
              }}
            >
              <span className="text-black text-[10px] flex items-center font-semibold">
                {submittingFile ? (
                  <>
                    <Spinner />
                    <p className=""> Updating...</p>
                  </>
                ) : (
                  <>
                    <Icon icon="ant-design:upload-outlined" />
                    <p className="pl-1"> Update</p>
                  </>
                )}
              </span>
            </div>
            <div
              className="flex items-center p-1 bg-white rounded-sm cursor-pointer"
              onClick={toggleDeleteModal}
            >
              <span className="text-danger text-[10px] flex items-center font-semibold ">
                <Icon icon="charm:bin" />
                <span> Delete</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <input
        type="file"
        name="myImage"
        multiple
        hidden
        ref={inputFileRef}
        onChange={(event) => {
          onChange(event);
        }}
      />

      <DeleteModal
        modalOpen={modalOpen}
        closeModal={toggleDeleteModal}
        type="Photo"
        image={preview?.file}
        size="small"
        delete={deleteImage}
        isSubmitting={deletingImage}
      />
      <MakeDefaultModal
        modalOpen={favouriteModalOpen}
        closeModal={toggleDefaultModal}
        image={preview?.file ?? ""}
        actionHandler={makeImageDefaultHeader}
        isSubmitting={submittingFile}
      />
    </div>
  );
};

export default ImageComponent;
