import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";

const AnyReactComponent = ({ text }: any) => {
  return (
    <div>
      <Icon icon="material-symbols:location-on" width={50} />
      {text}
    </div>
  );
};
export default function Maps() {
  const [searchParams] = useSearchParams();

  const [location] = useState(JSON.parse(searchParams.get("location") ?? ""));
  return (
    <div className="h-screen w-screen">
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY ?? "" }}
          defaultCenter={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={location.latitude}
            lng={location.longitude}
            text="Client's Address"
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}
