import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Input } from "./Base/Input";

interface Props {
  text?: string;
  accept?: string;
  label?: string;
  pushFilesToArray?: any;
}

const FileUpload = (props: Props) => {
  const [selectedFile, setSelectedFile] = useState<any>("");
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file.name) setSelectedFile(file.name);
    props.pushFilesToArray(file);
  };
  return (
    <div className="">
      <div className="relative py-2 border-solid cursor-pointer sm:py-0 md:w-max min-w-[176px] sm:w-32 customfileinput second">
        <Input
          type="file"
          label={props.label}
          onChange={handleFileUpload}
          accept={props.accept}
        />
        {selectedFile.length < 1 ? (
          ""
        ) : (
          <p className="text-[7px] relative -left-0 py-1 flex items-center">
            {props.text ? props.text : "Document"} Uploaded!
            <span className="pl-1 text-green-500">
              <Icon icon="feather:check-circle" />
            </span>
          </p>
        )}
        <span className="font-bold text-[8px] cursor-pointer z-10 bg-lightergrey border-darkergrey border py-3 px-3 rounded-md absolute left-0">
          {selectedFile.length < 1 ? (
            <span className="cursor-pointer ">
              Upload {props.text ? props.text : "Document"}
            </span>
          ) : (
            <span className="cursor-pointer ">
              Replace {props.text ? props.text : "Document"}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default FileUpload;
