import useCurrency from "../hooks/useCurrency";
import StarRating from "./StarRating";
import Statistic from "./Statistic";
interface Props {
  appointments: number;
  summary:
    | {
        appointmentsCount?: number;
        mostBooked: string;
        rating: string;
        totalRevenue: string;
      }
    | undefined;
}
const Statistics = (props: Props) => {
  return (
    <div className="h-full p-6 my-8 overflow-auto md:my-10 md:p-8 bg-basegrey rounded-xl">
      <div className="flex items-center justify-between">
        <div className="flex flex-col text-xs font-semibold md:text-sm md:flex-row">
          <p className="mr-3 interFamily">
            Overall Rating
            <span className="text-[7px] pl-2">
              {!!props.summary?.rating &&
                `(${props.summary?.rating?.slice(0, 4)})`}
            </span>
          </p>
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <StarRating rating={Number(props.summary?.rating || 0)} />
        </div>
      </div>

      <div className="flex justify-between w-full mt-5 space-x-10 overflow-x-scroll md:flex-row md:space-y-0">
        <Statistic
          icon="carbon:task-asset-view"
          type="Total Appointments"
          value={Number(props.summary?.appointmentsCount).toLocaleString()}
        />
        <Statistic
          icon="heroicons-outline:currency-dollar"
          type=" Revenue"
          value={`${
            props.summary?.totalRevenue
              ? useCurrency(props.summary?.totalRevenue)
              : 0
          }`}
        />
        <Statistic
          icon="ic:outline-local-offer"
          type="Most Booked Service"
          value={props.summary?.mostBooked}
          smallertext
        />
      </div>
    </div>
  );
};

export default Statistics;
