import axios from "axios";
import { useState } from "react";
import { analyticDropdownType } from "../interfaces/analytics";

const AnalyticsHook = () => {
  const [dropDownOptions, setDropDownOptions] = useState<
    analyticDropdownType[]
  >([
    { label: "7 days", value: 1 },
    { label: "14 days", value: 2 },
    { label: "1 month", value: 3 },
    { label: "6 months", value: 4 },
    { label: "1 year", value: 5 },
  ]);
  const [dropDownText, setDropDownText] = useState("7 days");
  const [duration, setDuration] = useState<number>(1);
  const [fetch, setFetch] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [noData, setNoData] = useState(true);
  const getValue = (value: analyticDropdownType) => {
    setDuration(value.value);
    setDropDownText(value.label);
    setFetch((prev) => !prev);
  };

  const getData = async (
    url = `/api/appointment/analytics/?interval=${duration}`
  ) => {
    try {
      setIsloading(true);
      setNoData(false);
      const {
        data: { data },
      } = await axios.get(url);
      return data;
    } catch (e) {
      console.error(e);
      setNoData(true);
      return { error: true, object: e };
    } finally {
      setIsloading(false);
    }
  };

  return {
    dropDownOptions,
    setDropDownOptions,
    dropDownText,
    setDropDownText,
    duration,
    setDuration,
    fetch,
    setFetch,
    getValue,
    isloading,
    setIsloading,
    getData,
    noData,
    setNoData,
  };
};
export default AnalyticsHook;
