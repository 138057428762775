import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import staffAppointment from "../assets/images/staffAppointment.svg";
import staffEmail from "../assets/images/staffEmail.svg";
import staffPhone from "../assets/images/staffPhone.svg";
import { IStaffAttributes, IStaffInvite } from "../interfaces/pages";
import { Button } from "./Base/Button";
import Spinner from "./Base/Spinner";
import { Toast } from "./Base/toast";
interface Props {
  onClick: () => void;
  content: IStaffAttributes;
}
export default function StaffCard({ onClick, content }: Props) {
  return (
    <div
      className="border border-[#001C43] rounded-lg cursor-pointer bg-white"
      onClick={onClick}
    >
      <div className="flex border-b border-[#001C43]">
        <div className="w-[83px]">
          {content.profilePic ? (
            <img
              src={content.profilePic}
              alt="profile"
              className="h-[95px] w-[83px] rounded-tl-lg"
            />
          ) : (
            <Avatar
              name={content.firstName + " " + content.lastName}
              size="85"
              round={false}
              color="#001C43"
              textSizeRatio={2.5}
              className="h-[85px] w-[78px] rounded-tl-lg "
              style={{
                backgroundColor: "#001C43",
                height: "95px",
              }}
            />
          )}
        </div>
        <div className="py-2 px-4 capitalize w-[160px]">
          <p className=" text-lg font-medium truncate">
            {content.firstName + " " + content.lastName}
          </p>
          <p className=" text-gray-500 text-sm truncate">
            {content?.designation}
          </p>
        </div>
      </div>
      <div className="flex flex-col pl-4 pt-4 pb-8 space-y-3">
        <div className="flex items-center space-x-3">
          <div>
            <Icon icon="whh:appointment" width="21" />
          </div>
          <div className="text-sm ">
            {content.appointmentCount} Appointment
            {content.appointmentCount === 0
              ? "s"
              : content.appointmentCount > 1
              ? "s"
              : ""}
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Icon icon="material-symbols:alternate-email-rounded" width="21" />
          </div>
          <div className="text-sm truncate">{content.email}</div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Icon icon="ic:twotone-phone-android" width="21" />
          </div>
          <div className="text-sm ">{content.phone}</div>
        </div>
      </div>
    </div>
  );
}
export const StaffSkeleton = () => {
  return (
    <div className="border border-[#001C43] rounded-lg animate-pulse">
      <div className="flex border-b border-[#001C43]">
        <div>
          {/* <img src="" alt="" /> */}
          <Avatar
            name={"John Doe"}
            size="85"
            round={false}
            textSizeRatio={2.5}
            color="#001C43"
            className=" rounded-tl-lg "
            style={{
              backgroundColor: "#001C43",
              height: "95px",
            }}
          />
        </div>
        <div className="py-2 px-5 capitalize space-y-3">
          <div className="h-6 pt-2 bg-gray-100 rounded-full w-32 "></div>
          <div className="h-4 bg-gray-100 rounded-full w-20 "></div>
        </div>
      </div>
      <div className="flex flex-col p-3 space-y-3">
        <div className="flex items-center space-x-3">
          <div>
            <img src={staffAppointment} alt="" className="h-5 w-5 mx-auto" />
          </div>
          <div className="h-4 bg-gray-100 rounded-full w-32 "></div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <img src={staffEmail} alt="" className="h-5 w-5 mx-auto" />
          </div>
          <div className="h-4 bg-gray-100 rounded-full w-32 "></div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <img src={staffPhone} alt="" className="h-5 w-5 mx-auto" />
          </div>
          <div className="h-4 bg-gray-100 rounded-full w-32 "></div>
        </div>
      </div>
    </div>
  );
};
const StaffInviteSkeleton = () => {
  return (
    <div className=" flex min-h-[80vh] items-center justify-center py-8  animate-pulse">
      <Spinner />
    </div>
  );
};
interface staffInvitesProps {
  reload: boolean;
  inviteStaff: () => void;
}
export const StaffInvites = ({ reload, inviteStaff }: staffInvitesProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshPage, setRefreshPage] = useState(true);
  const [invites, setInvites] = useState<IStaffInvite[]>([]);
  useEffect(() => {
    const getInvites = async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`/api/staff/invitation`);
        setInvites(data.invites);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    getInvites();
  }, [reload, refreshPage]);
  return (
    <div className="">
      {isLoading ? (
        <>
          <StaffInviteSkeleton />
        </>
      ) : (
        <>
          {invites.length ? (
            <>
              <div className="mb-5">
                <Button
                  onClick={inviteStaff}
                  isSubmitting={false}
                  disabled={false}
                >
                  invite staff
                </Button>
              </div>
              <div className="border-collapse border border-[#001C43] rounded-lg">
                {invites.map((invite, inviteIndex) => {
                  return (
                    <InviteCard
                      invite={invite}
                      key={inviteIndex}
                      inviteIndex={inviteIndex}
                      refreshPage={() => {
                        setRefreshPage(!refreshPage);
                      }}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="p-3  h-[50vh] flex flex-col space-y-4 items-center justify-center">
              <p className="text-xl font-semibold">No pending invitations</p>
              <Button
                onClick={inviteStaff}
                isSubmitting={false}
                disabled={false}
              >
                invite staff
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
const InviteCard = ({
  invite,
  inviteIndex,
  refreshPage,
}: {
  invite: IStaffInvite;
  inviteIndex: number;
  refreshPage: () => void;
}) => {
  const [resending, setResending] = useState(false);
  const [revoking, setRevoking] = useState(false);
  return (
    <div
      className={
        " border-[#001C43]  p-3 flex items-center justify-between" +
        (inviteIndex !== 0 ? " border-t" : "")
      }
    >
      <div className="flex items-center space-x-2">
        <div>
          {invite.accessDetails.profilePic ? (
            <img
              src={invite.accessDetails.profilePic}
              alt="profile"
              className="h-[40px] w-[40px] rounded-full"
            />
          ) : (
            <Avatar
              name={
                invite.accessDetails.firstName +
                " " +
                invite.accessDetails.lastName
              }
              size="40"
              round={true}
              textSizeRatio={3}
            />
          )}
        </div>
        <div className="flex flex-col truncate max-w-[105px] text-sm">
          <p className="font-semibold truncate">
            {invite.accessDetails.firstName +
              " " +
              invite.accessDetails.lastName}
          </p>
          <span className="text-xs truncate">{invite.email}</span>
        </div>
      </div>

      <div className="flex text-[11px] space-x-3 font-medium">
        <span
          className="cursor-pointer flex justify-center items-center"
          onClick={async () => {
            try {
              setResending(true);
              await axios.get(`/api/staff/invitation/${invite.id}/resend`);
              Toast("Invite sent successfully", "success");
            } catch (e) {
              console.error(e);
            } finally {
              setResending(false);
            }
          }}
        >
          {resending ? <Spinner /> : "Resend Invite"}
        </span>
        <span
          className="text-[#8B0B0B] cursor-pointer flex justify-center items-center"
          onClick={async () => {
            try {
              setRevoking(true);
              await axios.delete(`/api/staff/invitation/${invite.id}`);
              Toast("Invite revoked successfully", "success");
              refreshPage();
            } catch (e) {
              console.error(e);
            } finally {
              setRevoking(false);
            }
          }}
        >
          {revoking ? <Spinner /> : "Revoke Invite"}
        </span>
      </div>
    </div>
  );
};
