import axios from "axios";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import FileUpload from "../FileUpload";
import { RadioInput } from "../Base/RadioInput";
import { Input } from "../Base/Input";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { InputValidationError } from "../../components/InputValidationError";
import { businessDocumentation } from "../../interfaces/business";
import useFileUpload from "../../hooks/useFileUpload";
import { useAppDispatch } from "../../store/hooks";
import { activateBusiness } from "../../features/business/business";
import { useState } from "react";

const Documentation = () => {
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState(false);
  const [govtId, setGovtId] = useState("");
  const [utilityBill, setUtilityBill] = useState("");
  const [license, setLicense] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { submitFile } = useFileUpload();

  const dispatch = useAppDispatch();
  const schema = yup.object().shape({
    registrationNumber: yup.string(),
    serviceName: yup.string(),
    serviceUrl: yup.string(),
  });
  const pushFilesToGovt = async (file: any) => {
    try {
      setIsLoading(true);
      const url = await submitFile(file, "governmentID");
      setGovtId(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const pushFilesToUtility = async (file: any) => {
    try {
      setIsLoading(true);
      const url = await submitFile(file, "utilityBill");
      setUtilityBill(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const pushFilesToLicense = async (file: any) => {
    try {
      setIsLoading(true);
      const url = await submitFile(file, "operatingLicense");
      setLicense(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const postDocumentation = async (form: businessDocumentation) => {
    // console.log('form', form, 'file', selectedFile, 'radio', radioValue)

    try {
      const regNum =
        getLocalBusinessType === "merchant" && form.registrationNumber !== "";
      const operatingLicenseCondition =
        getLocalBusinessType === "merchant" && license !== "";

      const {
        data: { data },
      } = await axios.post("/api/business/parameter/documentation", {
        ...(regNum && { registrationNumber: form.registrationNumber }),
        alternateService: !!radioValue,
        serviceName: form.serviceName,
        serviceUrl: form.serviceUrl,
        utilityBill,
        governmentBill: govtId,
        ...(operatingLicenseCondition && { operatingLicense: license }),
      });
      await makeActiveBusiness(data.businessId);

      navigate("/welcome");
    } catch (error) {
      console.error(error);
    }
  };
  const makeActiveBusiness = async (payload: string | number) => {
    try {
      await dispatch(activateBusiness(payload));
    } catch (error) {
      console.error(error);
    }
  };

  const getLocalBusinessType = localStorage.getItem("varroe_business_type");
  return (
    <div className="relative">
      <div className="overflow-scroll font-sans max-h-[539px]">
        <h2 className="absolute font-bold text-white uppercase -left-3 -top-16">
          Documentation
        </h2>
        <Formik
          initialValues={{
            registrationNumber: "",
            serviceName: "",
            serviceUrl: "",
          }}
          onSubmit={(data, { setSubmitting }) => {
            postDocumentation(data);
            setSubmitting(true);
          }}
          validationSchema={schema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="p-5 space-y-3 font-roboto" id="form1Documentation">
              {getLocalBusinessType === "merchant" && (
                <div className="py-2 md:py-0">
                  <Field
                    name="registrationNumber"
                    type="text"
                    as={Input}
                    label="Business Registration Number (optional)"
                    placeholder="e.g CA291939103920000"
                  />

                  {errors.registrationNumber && touched.registrationNumber ? (
                    <InputValidationError text={errors.registrationNumber} />
                  ) : null}
                </div>
              )}

              <div className="flex flex-col py-2 mb-10 space-y-8 space-x-6 sm:space-y-0 sm:flex-row sm:flex-nowrap">
                <FileUpload
                  label="Government Issued ID (optional)"
                  pushFilesToArray={pushFilesToGovt}
                />
                {getLocalBusinessType === "merchant" && (
                  <FileUpload
                    label="Operating License (optional)"
                    pushFilesToArray={pushFilesToLicense}
                  />
                )}
                <FileUpload
                  label="Utility Bill (Address Confirmation) (optional)"
                  pushFilesToArray={pushFilesToUtility}
                />
              </div>

              <div className="pt-5">
                <p className="text-[10px] font-semibold mt-4">
                  Are you registered with any other service? (optional)
                </p>
                <div className="flex justify-start mt-3 space-x-3">
                  <RadioInput
                    label="Yes"
                    name="service"
                    onChange={(e) => setRadioValue(true)}
                  />
                  <RadioInput
                    label="No"
                    name="service"
                    onChange={(e) => setRadioValue(false)}
                  />
                </div>
              </div>
              {radioValue ? (
                <div className="pt-3">
                  <div className="space-y-3 ">
                    <Field
                      name="serviceName"
                      type="text"
                      as={Input}
                      label="Service Name"
                      placeholder="eg. Merchant Circle"
                    />
                    <Field
                      name="serviceUrl"
                      type="text"
                      as={Input}
                      label="Service URL"
                      placeholder="eg. builders.mc.com"
                    />
                  </div>
                </div>
              ) : null}
              <div className="text-right">
                <p className="text-xs">This section is optional***</p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="absolute inset-x-0 -bottom-16">
        <div className="flex justify-end">
          {/* <Link
            to="/auth/register/classification"
            className="flex items-center text-xs font-semibold text-white"
          >
            <span className="pr-1">
              <Icon icon="akar-icons:arrow-left" />
            </span>
            Business Classification
          </Link> */}
          <button
            form="form1Documentation"
            className="flex items-center text-xs font-semibold text-white"
            disabled={isLoading}
          >
            {isLoading ? "loading..." : "Finish"}
            <span className="pl-1">
              <Icon icon="akar-icons:arrow-right" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
