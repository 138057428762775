import Nobusinessbox from '../Nobusinessbox';

const Nobusiness = () => {
  return (
    <div className="flex items-center justify-center mt-8">
      <div>
        <div className="space-y-5 font-semibold text-center text-white">
          <h1 className="text-xl xs:text-2xl">Hi there! Welcome to Varroe!</h1>
          <p className="text-sm">Where to next?</p>
        </div>

        <div className="flex mt-10 space-x-8 xs:mt-6">
          <Nobusinessbox
            icon="ph:rocket-launch"
            text="Onboard your business"
            to="/auth/register"
          />
          <Nobusinessbox
            to="/auth/start/link"
            icon="majesticons:door-enter-line"
            text="Join a business"
          />
        </div>
      </div>
    </div>
  );
};

export default Nobusiness;
