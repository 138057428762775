import { Icon } from "@iconify/react";
import React from "react";

interface props {
  text: any;
}

export const InputValidationError: React.FC<props> = ({ text }) => {
  return (
    <div className="flex items-center text-[9px] text-red-600 space-x-1 pt-1">
      <span className="text-red-600">
        <Icon icon="carbon:warning" width={10} />
      </span>
      <p>{text}</p>
    </div>
  );
};
