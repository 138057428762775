/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Icon } from "@iconify/react";
import { useState } from "react";
import { appointmentsType } from "../interfaces/appointments";
import ApproveAppointmentModal from "./Modals/ApproveAppointmentModal";
import DeleteModal from "./Modals/DeleteModal";
import Pagination from "./Pagination";
import TableLoadingState from "./TableLoadingState";
import { DateTime } from "ts-luxon";
import axios from "axios";
import AppointmentModal from "./Modals/AppointmentModal";
import moment from "moment-timezone";
import { useAppSelector } from "../store/hooks";
import useCurrency from "../hooks/useCurrency";

interface Props {
  items: appointmentsType[];
  toggleAction?: boolean;
  fetching?: boolean;
  showFrom: number;
  totalRecords: number;
  showTo: number;
  currentPage: number;
  next: () => void;
  prev: () => void;
  refresh: () => void;
}

const AppointmentsTable = (props: Props) => {
  moment().tz("Canada/Ottawa").format();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [approveModalProps, setApproveModalProps] =
    useState<appointmentsType>();
  const [cancelModalProps, setCancelModalProps] = useState<appointmentsType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [appointmentModalProps, setAppointmentModalProps] =
    useState<appointmentsType>();
  const [deleteModalProps, setDeleteModalProps] = useState<appointmentsType>();
  const [selectedDate, setSelectedDate] = useState("");
  const userRole = useAppSelector((state) => state.auth.role);

  const handleDelete = async () => {
    // if you click on yes
    try {
      await axios.patch(`/api/appointment/${deleteModalProps?.id}/handle`, {
        accepted: false,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setModalOpen(false);
      setDeleteModalOpen(false);
      props.refresh();
    }
  };
  const getDateTime = (content: appointmentsType) => {
    const date = new Date(content.date.split("T")[0]);
    // date.setDate(date.getDate() + 1);
    const newDate = date.toDateString();
    // moment(content.date).utc().format("ddd DD MMM YYYY");

    return newDate;
  };

  const getTimeMeridian = (time: string) => {
    return moment(`1970-01-01T${time}`).format("LT");
  };
  const handleApprove = (content: appointmentsType, e: any) => {
    e.stopPropagation();
    setApproveModalProps(content);
    setApproveModalOpen(true);
  };

  const refresh = () => {
    props.refresh();
  };
  const next = () => {
    props.next();
  };
  const prev = () => {
    props.prev();
  };

  //   useEffect(() => {
  //     setTableContent(props.items);
  //   }, [props.items]);
  const showModals = (content: any, e: any) => {
    e.stopPropagation();
    const formattedDate = DateTime.fromISO(content.date).toFormat("DDDD");
    setSelectedDate(formattedDate);

    setAppointmentModalProps(content);
    setModalOpen(true);
  };
  const showDeleteModals = (content: any, e: any) => {
    e.stopPropagation();
    setDeleteModalProps(content);
    setDeleteModalOpen(true);
  };

  return (
    <div className="inline-block w-full overflow-x-scroll border rounded-lg">
      <div className=" max-h-[68vh] overflow-auto">
        <table className="w-full text-sm text-left bg-white">
          <thead className="w-fit text-[11px] sm:text-xs font-semibold text-white md:text-sm bg-main">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Service
              </th>

              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                Payment
              </th>

              {props.toggleAction && (
                <th scope="col" className="px-6 py-3">
                  Staff
                </th>
              )}

              <th scope="col" className="flex items-center px-6 py-3">
                Time|Date
                {/* <span className="pl-2">
                <Icon icon="ant-design:caret-down-filled" />
              </span> */}
              </th>

              {props.toggleAction && (
                <>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3"></th>
                </>
              )}
            </tr>
          </thead>

          {props.fetching ? (
            <TableLoadingState text="Getting Appointments..." fetching />
          ) : !props.items.length ? (
            <TableLoadingState text="You have no Appointments" />
          ) : (
            <>
              <tbody>
                {props.items.length &&
                  props.items.map((content: any) => (
                    <tr
                      className="sm:text-xs text-[11px] border-t md:text-[13px] cursor-pointer"
                      key={content.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        showModals(content, e);
                      }}
                    >
                      <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 md:px-6 md:py-4 whitespace-nowrap "
                      >
                        {content.customer &&
                          content.customer?.firstName +
                            " " +
                            content.customer?.lastName}
                      </th>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.service && content.service.name}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.price && useCurrency(content.price)}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        <div
                          className={`border-2 whitespace-nowrap font-semibold text-xs ${
                            content.status === "PAID"
                              ? "bg-green-400 border-green-500"
                              : "bg-orange-200 border-orange-500"
                          }  rounded-full p-1 px-2 text-center`}
                        >
                          {content.status === "PAID" ? "ONLINE" : "IN STORE"}
                        </div>
                      </td>
                      {props.toggleAction && (
                        <td className="px-4 py-2 md:px-6 md:py-4">
                          {content.staff?.firstName && content.staff?.lastName
                            ? content.staff?.firstName +
                              " " +
                              content.staff?.lastName
                            : "---"}
                        </td>
                      )}

                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.timeSlot &&
                          `${getTimeMeridian(content.timeSlot)} | ${getDateTime(
                            content
                          )}`}
                      </td>

                      {props.toggleAction && (
                        <td className="flex justify-end py-2 md:py-4">
                          <div className="flex justify-end space-x-3">
                            {content.state !== "APPROVED" && (
                              <div
                                className="text-xl font-bold text-green-600 cursor-pointer"
                                onClick={(e) => {
                                  handleApprove(content, e);
                                }}
                              >
                                <span className="text-base md:text-2xl ">
                                  <Icon icon="akar-icons:check-box" />
                                </span>
                              </div>
                            )}

                            <div
                              className="text-xl font-bold cursor-pointer text-danger"
                              onClick={(e) => {
                                showDeleteModals(content, e);
                              }}
                            >
                              <span className="text-base md:text-2xl ">
                                <Icon icon="akar-icons:circle-x" />
                              </span>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                {/* <tr className="border-b ">
                  <td className="px-4 py-2 md:px-6 md:py-4 w-fit" colSpan={12}>
                    <Pagination
                      next={next}
                      prev={prev}
                      showFrom={props.showFrom}
                      totalRecords={props.totalRecords}
                      showTo={props.showTo}
                      currentPage={props.currentPage}
                    />
                  </td>
                </tr> */}
              </tbody>
            </>
          )}
        </table>
      </div>
      {!!props.items.length && (
        <div className="px-4 py-2 md:px-6 md:py-4 border-t ">
          <Pagination
            next={next}
            prev={prev}
            showFrom={props.showFrom}
            totalRecords={props.totalRecords}
            showTo={props.showTo}
            currentPage={props.currentPage}
          />
        </div>
      )}

      {/* <AppointmentList
        appointments={modalProps}
        modalOpen={modalOpen}
        selectedDate={selectedDate}
        closeModal={() => {
          setModalOpen(!modalOpen);
          setAppointmentModalOpen(false);
        }}
        openOtherModal={(appointment: appointmentsType) => {
          setAppointmentModalProps(appointment);
          setAppointmentModalOpen(true);
          setModalOpen(false);
        }}
      /> */}

      <AppointmentModal
        modalOpen={modalOpen}
        appointmentModalProps={appointmentModalProps}
        closeModal={() => {
          setModalOpen(false);
        }}
        refresh={refresh}
        openOtherModal={(appointmentModalProps: appointmentsType) => {
          setDeleteModalProps(appointmentModalProps);
          setDeleteModalOpen(true);
          setAppointmentModalOpen(false);
        }}
        hideCancelButton={userRole === "staff"}
      />
      <DeleteModal
        modalOpen={deleteModalOpen}
        cancelModalProps={cancelModalProps}
        refresh={refresh}
        closeModal={async (value: boolean) => {
          // if you click on yes
          if (value) {
            try {
              await axios.patch(
                `/api/appointment/${deleteModalProps?.id}/handle`,
                {
                  accepted: false,
                }
              );
            } catch (e) {
              console.error(e);
            } finally {
              setModalOpen(!modalOpen);
              setDeleteModalOpen(false);
            }
          } else {
            setModalOpen(false);
            setDeleteModalOpen(false);
          }
        }}
        delete={handleDelete}
        title={
          deleteModalProps?.customer.firstName +
          " " +
          deleteModalProps?.customer.lastName +
          "'s " +
          deleteModalProps?.service.name +
          " Appointment"
        }
        type="Appointment"
        size="small"
      />
      <ApproveAppointmentModal
        modalOpen={approveModalOpen}
        approveModalProps={approveModalProps}
        refresh={refresh}
        closeModal={() => {
          setApproveModalOpen(false);
        }}
      />
    </div>
  );
};

export default AppointmentsTable;
