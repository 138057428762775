import { appointmentsType } from "../../interfaces/appointments";
import { Button } from "../Base/Button";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: any;
  type: "Photo" | "Staff" | "Appointment" | "Service" | "FAQ";
  title?: string;
  subtext?: string;
  image?: string;
  modalOpen: boolean;
  size?: "large" | "small";
  delete?: any;
  isSubmitting?: boolean;
  refresh?: () => void;
  cancelModalProps?: appointmentsType;
}
const DeleteModal = (props: Props) => {
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size={props.size}
    >
      <div className="mt-3 sm:text-left">
        <div className="flex flex-col-reverse justify-between sm:flex-row">
          <div className="flex flex-col justify-between sm:w-4/6">
            <div className="flex items-center sm:mr-0 sm:justify-start">
              <div className="w-full mr-5 space-y-5 md:mr-0 sm:w-auto">
                <p className="text-sm sm:text-[14px] break-words text-left">
                  Are you sure you want to{" "}
                  {props.type === "Appointment" ? "cancel " : "delete "}
                  this {props.type}?
                </p>

                {props.type === "Photo" ? (
                  <p className="text-xs text-left break-words sm:text-sm ">
                    This photo will be removed from all active services.
                  </p>
                ) : (
                  <p className="text-sm font-semibold text-left sm:text-xl">
                    {props.title}
                  </p>
                )}
              </div>
            </div>
          </div>

          {props.type === "Photo" ? (
            <div className="flex w-2/5 mb-10 h-36 sm:mb-0 sm:justify-start rounded-xl">
              <img
                src={props.image}
                alt="staff"
                className="object-cover w-full rounded-xl"
              />
            </div>
          ) : (
            <div className="flex justify-end w-16 h-16 rounded-lg">
              <img
                src={require(`../../assets/images/${props.type}.svg`)}
                alt={props.type}
                className="object-contain w-full"
              />
            </div>
          )}
        </div>

        {props.type === "Photo" ? null : (
          <div className="my-8">
            <p className="text-xs text-left">{props.subtext}</p>
          </div>
        )}
        <div
          className={`flex items-end ${
            props.type === "Photo"
              ? "md:justify-start"
              : "mt-10  md:justify-end"
          }`}
        >
          <Button
            onClick={() => props.delete()}
            isSubmitting={props.isSubmitting}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? `Deleting ${props.type}` : "Yes"}
          </Button>
          <Button
            variant="bg-danger ml-4"
            onClick={() => {
              props.closeModal();
            }}
          >
            No
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DeleteModal;
