import React from "react";

const CardSkeleton = () => {
  return (
    <div className="h-32 p-4 border border-gray-200 rounded shadow w-44 animate-pulse md:p-6">
      <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full "></div>
      <div className="flex items-center mt-4 space-x-3"></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
export const SkeletonServices = () => {
  return (
    <>
      <div className="cursor-pointer w-44">
        <div className="flex flex-col rounded-lg ">
          <div className="h-32 p-3 space-y-7 rounded-t-lg xl:p-5 bg-basegrey">
            <div className="h-4 bg-gray-200 rounded-full  mb-2.5"></div>
            <div className="">
              <div className="h-2 bg-gray-200 rounded-full  mb-1"></div>
              <div className="h-2 bg-gray-200 rounded-full  mb-1"></div>
              <div className="h-2 bg-gray-200 rounded-full  mb-1"></div>
            </div>
          </div>
          <div className="flex items-center justify-between p-4 rounded-b-lg bg-main">
            <div className="h-4 w-9 bg-gray-200 rounded-full  mb-1"></div>
            <div className="h-4 w-9 bg-gray-200 rounded-full  mb-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSkeleton;
