import React from 'react';

const ProgressBar = ({ progressvalue }: any) => {
  return (
    <div className="flex flex-col items-center w-6/12 space-y-3 lg:space-y-0 lg:flex-row">
      <span className="mr-3 text-xs font-semibold md:w-6/12 whitespace-nowrap">
        {progressvalue}% setup tasks completed
      </span>
      <div className="w-full rounded-full h-2.5 bg-[#E7E7E7]">
        <div
          className="bg-main h-2.5 rounded-full"
          style={{ width: `${progressvalue}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
