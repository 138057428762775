import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "ts-luxon";
import useCurrency from "../../hooks/useCurrency";

import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { Select } from "../Base/Select";
import Spinner from "../Base/Spinner";
import { Toast } from "../Base/toast";
import ApproveAppointmentModal from "./ApproveAppointmentModal";
import ModalContainer from "./ModalContainer";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  openOtherModal?: any;
  refresh?: any;
  appointmentModalProps?: any;
  hideCancelButton?: boolean;
}
interface slotType {
  end: string;
  start: string;
  active: boolean;
}
const StaffModal = (props: Props) => {
  const [reschedule, setReschedule] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState("");
  const [availableSlots, setAvailableSlots] = useState<slotType[]>([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [modalsize, setModalsize] = useState<"large" | "small" | "long" | "">(
    "large"
  );
  const [allSlots, setAllSlots] = useState<
    Array<{
      slots: string[];
      staff: {
        id: number;
      };
    }>
  >([]);
  const [staffOnReschedule, setStaffOnReschedule] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [appointmentStaffs, setAppointmentStaffs] = useState([]);
  const [selectedstaff, setSelectedstaff] = useState(0);
  const [allStaffLoading, setAllStaffLoading] = useState(true);
  const handleSave = async () => {
    if (!rescheduleDate || !selectedTime)
      return Toast("Please input a date and time to reschedule", "warning");
    try {
      interface reschedulePayload {
        date: string;
        timeSlot: string;
        staffId: number | undefined;
      }
      const payload: reschedulePayload = {
        date: rescheduleDate,
        timeSlot: selectedTime,
        staffId: selectedstaff,
      };
      if (!selectedstaff || !staffOnReschedule) {
        delete payload.staffId;
      }

      await axios.patch(
        `/api/appointment/${props.appointmentModalProps?.id}`,
        payload
      );
      close();
      props.refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setReschedule(!reschedule);
    }
  };
  const getAppointmentStaffs = async () => {
    try {
      setAllStaffLoading(true);
      if (!props.appointmentModalProps) return;
      const {
        data: { data },
      } = await axios.get(
        `/api/appointment/${props.appointmentModalProps.id}/handle`
      );

      setAppointmentStaffs(
        data.map((staff: any) => ({
          value: staff.id,
          text: staff.firstName + " " + staff.lastName,
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setAllStaffLoading(false);
    }
  };
  useEffect(() => {
    getAvailableSlots();
  }, [rescheduleDate]);
  useEffect(() => {
    getAppointmentStaffs();
    if (props.appointmentModalProps?.staffId) {
      setModalsize("");
    } else {
      // setModalsize("small");
    }
  }, [props.appointmentModalProps]);

  useEffect(() => {
    setRescheduleDate("");
    setSelectedTime("");
    if (reschedule) {
      setModalsize("small");
    } else {
      setModalsize("");
    }
  }, [reschedule]);

  const getAvailableSlots = async () => {
    if (!rescheduleDate) return;
    try {
      if (!props.appointmentModalProps) return;
      const endpoints = [
        `/api/appointment/${props.appointmentModalProps.id}/slot?date=${rescheduleDate}`,
      ];
      const [slots] = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint))
      );
      if (slots.data.data) {
        if (typeof slots.data.data[0] === "string") {
          setStaffOnReschedule(false);
          setAvailableSlots(
            slots.data.data.map((slot: string) => ({
              start: slot,
              active: false,
            }))
          );
        } else {
          setStaffOnReschedule(true);
          setAvailableSlots([]);

          const onlyStaff = slots.data.data.map((slot: any) => slot.staff);
          setAllSlots(slots.data.data);
          setAppointmentStaffs(
            onlyStaff.map((staff: any) => ({
              value: staff.id,
              text: staff.firstName + " " + staff.lastName,
            }))
          );
          if (onlyStaff.length === 1) {
            setSelectedstaff(onlyStaff[0].id);
            setAvailableSlots(
              slots.data.data[0].slots.map((slot: string) => ({
                start: slot,
                active: false,
              }))
            );
          }
        }
      }
    } catch (e) {
      setAppointmentStaffs([]);
      setSelectedstaff(0);
      setStaffOnReschedule(false);
      setAvailableSlots([]);

      console.error(e);
    }
  };
  const close = () => {
    // empty all states
    props.closeModal();
    setReschedule(false);
    setSelectedstaff(0);
    setAppointmentStaffs([]);
    setStaffOnReschedule(false);
    setAvailableSlots([]);
  };
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={() => {
        close();
      }}
      size={modalsize}
    >
      <div className="mt-3 sm:mt-0 sm:text-left">
        <div
          className={
            reschedule || !props.appointmentModalProps?.staffId
              ? " "
              : " grid grid-cols-4 gap-6 "
          }
        >
          <div className="col-span-3 space-y-4">
            <div className="p-3 md:px-3 py-2 bg-[#f1f1f1] rounded-lg">
              <div className="w-full my-2 mr-5 md:mr-0 sm:w-auto">
                <p className="text-sm font-semibold capitalize break-words md:text-xl">
                  {props.appointmentModalProps?.customer.firstName +
                    " " +
                    props.appointmentModalProps?.customer.lastName}
                </p>

                <p className="mt-3 text-xs break-words sm:text-sm">
                  {/* {props.appointmentModalProps?.staff.email} */}
                </p>
                <p className="my-3 text-xs break-words sm:text-sm">
                  {props.appointmentModalProps?.customer.phoneNumber}
                </p>
              </div>
            </div>
            {reschedule ? (
              <div>
                <div className=" flex space-x-5">
                  <div className="flex items-center space-x-2 text-lg font-semibold">
                    <Icon icon="akar-icons:tag" />
                    <p>{props.appointmentModalProps?.service?.name}</p>
                  </div>
                  <div className="flex items-center space-x-2 text-lg font-semibold">
                    <Icon icon="ph:currency-circle-dollar-bold" />
                    <p>{useCurrency(props.appointmentModalProps?.price)}</p>
                  </div>
                </div>
                {!!props.appointmentModalProps?.address && (
                  <div className="flex items-center space-x-2 text-lg font-semibold my-1">
                    <Icon icon="uil:location-pin-alt" width={20} />
                    <p className="">{` ${props.appointmentModalProps.address?.street}, ${props.appointmentModalProps.address?.city}, ${props.appointmentModalProps.address?.state} `}</p>
                  </div>
                )}

                <div className="mb-5">
                  <Input
                    type="date"
                    label="Date"
                    onChange={(event) => {
                      setRescheduleDate(event.target.value);
                    }}
                  />
                </div>
                <div className="my-5">
                  {staffOnReschedule && (
                    <Select
                      options={appointmentStaffs}
                      onChange={(e) => {
                        setSelectedstaff(parseInt(e.target.value));
                        const selectedSlot: any = allSlots.find(
                          (item) => item.staff.id === parseInt(e.target.value)
                        );
                        setAvailableSlots(
                          selectedSlot.slots.map((slot: string) => ({
                            start: slot,
                            active: false,
                          }))
                        );
                      }}
                      label="Staff"
                    />
                  )}
                </div>
                <div className=" grid grid-cols-5 gap-4">
                  {availableSlots.map((slot, slotIndex) => (
                    <button
                      key={slotIndex}
                      className={
                        (slot.active ? " bg-slate-800   text-white " : " ") +
                        " border-2 font-semibold border-black py-1 px-4 cursor-pointer w-[80px] text-center rounded-xl "
                      }
                      onClick={() => {
                        const activate = availableSlots.map((slot, index) => {
                          if (index === slotIndex) {
                            return {
                              ...slot,
                              active: true,
                            };
                          } else
                            return {
                              ...slot,
                              active: false,
                            };
                        });
                        setAvailableSlots(activate);
                        setSelectedTime(slot.start);
                      }}
                    >
                      {slot.start}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <div className="space-y-3">
                    <div className="flex items-center space-x-2">
                      <p>Payment:</p>
                      <div
                        className={`border-2 whitespace-nowrap font-semibold text-sm ${
                          props.appointmentModalProps?.status === "PAID"
                            ? "bg-green-400 border-green-500"
                            : "bg-orange-200 border-orange-500"
                        }  rounded-full p-1 px-2 text-center`}
                      >
                        {props.appointmentModalProps?.status === "PAID"
                          ? "ONLINE"
                          : "IN STORE"}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 text-lg font-semibold">
                      <Icon icon="akar-icons:tag" />
                      <p>{props.appointmentModalProps?.service?.name}</p>
                    </div>
                    <div className="flex items-center space-x-2 text-lg font-semibold">
                      <Icon icon="ph:currency-circle-dollar-bold" />

                      <p>{useCurrency(props.appointmentModalProps?.price)}</p>
                    </div>
                    {!!props.appointmentModalProps?.address && (
                      <div>
                        <div className="flex items-center space-x-2 text-lg font-semibold">
                          <Icon icon="uil:location-pin-alt" />
                          <p className="">{` ${props.appointmentModalProps.address?.street}, ${props.appointmentModalProps.address?.city}, ${props.appointmentModalProps.address?.state} `}</p>
                        </div>
                        <Link
                          to={`/map?location=${JSON.stringify(
                            props.appointmentModalProps.address
                          )}`}
                          target="_blank"
                          className="text-xs flex items-center justify-end  !my-0 cursor-pointer space-x-1"
                        >
                          <Icon
                            icon="material-symbols:map-outline-sharp"
                            width={16}
                          />
                          <span>Show in maps</span>
                        </Link>
                      </div>
                    )}

                    {props.appointmentModalProps?.optionalNote && (
                      <div className="flex items-center space-x-2 text-sm font-semibold">
                        Notes:
                        <span className="ml-3 text-xs font-normal text-gray-400 capitalize">
                          {props.appointmentModalProps?.optionalNote}
                        </span>
                      </div>
                    )}
                  </div>

                  {reschedule ? (
                    <div className="relative h-40 overflow-hidden rounded-lg w-28">
                      <img
                        src={
                          props.appointmentModalProps?.staff &&
                          props.appointmentModalProps?.staff.profilePic
                        }
                        alt="staff"
                        className="object-cover w-full h-full"
                      />
                      <div className="p-2 sm:px-3  bg-[#f1f1f1] flex items-center justify-center absolute bottom-0 w-full">
                        <p className="font-semibold text-center text-[8px]">
                          {props.appointmentModalProps?.staff &&
                            props.appointmentModalProps?.staff.firstName +
                              " " +
                              props.appointmentModalProps?.staff.lastName}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className=" flex justify-between">
                  {!!props?.appointmentModalProps?.serviceSelection?.addons &&
                    !!props?.appointmentModalProps?.serviceSelection?.addons
                      .length && (
                      <div className="flex flex-col break-all max-w-[49%] max-h-[10rem] overflow-auto ">
                        <p className="font-semibold">Addons</p>
                        {props?.appointmentModalProps?.serviceSelection?.addons.map(
                          (addon: any, addonIndex: number) => (
                            <p key={addonIndex} className="m-0 p-0">
                              {addon.label}: ${addon.price}
                            </p>
                          )
                        )}
                      </div>
                    )}
                  {!!props?.appointmentModalProps?.serviceSelection
                    ?.customisations &&
                    !!props?.appointmentModalProps?.serviceSelection
                      ?.customisations.length && (
                      <div className="flex flex-col  max-w-[49%] max-h-[10rem] overflow-auto break-all">
                        <p className="font-semibold">Customisations</p>
                        {props?.appointmentModalProps?.serviceSelection?.customisations.map(
                          (customisation: any, customisationIndex: number) => (
                            <div key={customisationIndex}>
                              <span className="m-0 p-0 font-medium leading-[8px] mt-2">
                                {customisation.label}
                              </span>
                              {customisation.value.length &&
                                customisation.value.map(
                                  (value: string, valueIndex: number) => (
                                    <span key={valueIndex} className="ml-1 ">
                                      :<span className="ml-1">{value} </span>
                                      {valueIndex <
                                      customisation.value.length - 1
                                        ? "|"
                                        : ""}
                                    </span>
                                  )
                                )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                </div>
                {props.appointmentModalProps &&
                  !props.appointmentModalProps.staffId &&
                  props.appointmentModalProps.state !== "CANCELLED" && (
                    <div className="mt-5">
                      {allStaffLoading ? (
                        <Spinner />
                      ) : appointmentStaffs.length ? (
                        <Select
                          options={appointmentStaffs}
                          onChange={(e) => {
                            setSelectedstaff(parseInt(e.target.value));
                          }}
                          label="Staff"
                        />
                      ) : (
                        <div> No Available Staff </div>
                      )}
                    </div>
                  )}
              </>
            )}
          </div>

          {!reschedule && props.appointmentModalProps?.staffId && (
            <div className="flex justify-center col-span-1 mb-4">
              <div className="w-full space-y-4">
                <div className="space-y-2 text-base">
                  <div>
                    <p className="text-xs font-semibold text-left">Date</p>
                    <p className="text-sm">
                      {props.appointmentModalProps &&
                        DateTime.fromISO(
                          props.appointmentModalProps.date
                        ).toFormat("DDD")}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-left">Time</p>
                    <p className="font-semibold">
                      {props.appointmentModalProps &&
                        moment(
                          `1970-01-01T${props.appointmentModalProps.timeSlot}`
                        ).format("LT")}
                    </p>
                  </div>
                </div>
                {props.appointmentModalProps?.staff && (
                  <div className="w-full overflow-hidden rounded-lg">
                    {props.appointmentModalProps?.staff.profilePic && (
                      <img
                        src={props.appointmentModalProps?.staff.profilePic}
                        alt="staff"
                        className="object-cover w-full h-44"
                      />
                    )}
                    {props.appointmentModalProps?.staff.firstName &&
                      props.appointmentModalProps?.staff.lastName && (
                        <div className="p-2 sm:px-3  bg-[#f1f1f1] flex items-center justify-center ">
                          <p className="text-sm font-semibold text-center">
                            {props.appointmentModalProps?.staff.firstName +
                              " " +
                              props.appointmentModalProps?.staff.lastName}
                          </p>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {props.appointmentModalProps &&
          props.appointmentModalProps.state !== "CANCELLED" && (
            <div className="flex pb-5 sm:pb-0 sm:mt-4">
              {reschedule ? (
                <>
                  <Button onClick={() => handleSave()}>RESCHEDULE</Button>
                  <Button
                    onClick={() => {
                      setReschedule(!reschedule);
                    }}
                    variant="bg-danger ml-4"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <div className="mr-5">
                    {props.appointmentModalProps &&
                    (props.appointmentModalProps.state === "APPROVED" ||
                      moment(props.appointmentModalProps.date).diff(
                        moment(),
                        "days"
                      ) < 0) ? (
                      <Button onClick={() => setReschedule(!reschedule)}>
                        Reschedule
                      </Button>
                    ) : !props.appointmentModalProps.staffId &&
                      appointmentStaffs.length ? (
                      <Button onClick={() => setApproveModalOpen(true)}>
                        Assign & Approve
                      </Button>
                    ) : (
                      <Button
                        variant="bg-green-500"
                        onClick={() => setApproveModalOpen(true)}
                      >
                        Approve Appointment
                      </Button>
                    )}
                  </div>
                  {!props.hideCancelButton && (
                    <Button
                      onClick={() => {
                        props.openOtherModal
                          ? props.openOtherModal(props.appointmentModalProps)
                          : props.closeModal();
                      }}
                      variant="bg-danger"
                    >
                      Cancel Appointment
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
      </div>
      <ApproveAppointmentModal
        modalOpen={approveModalOpen}
        approveModalProps={props.appointmentModalProps}
        refresh={() => {
          close();
          props.refresh();
        }}
        closeModal={() => {
          setApproveModalOpen(false);
        }}
        staffId={selectedstaff}
      />
    </ModalContainer>
  );
};

export default StaffModal;
