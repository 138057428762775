import { Icon } from "@iconify/react";
import moment from "moment";
import Avatar from "react-avatar";
import useCurrency from "../../hooks/useCurrency";
import useMeridem from "../../hooks/useMeridem";
import { ICRMSingleClient } from "../../interfaces/crm";
interface Props {
  gotoAppointments: () => void;
  details?: ICRMSingleClient;
  isLoading: boolean;
}
export default function CRMOverview({
  gotoAppointments,
  details,
  isLoading,
}: Props) {
  const { TwentyFourHourFormat } = useMeridem();
  return (
    <div className="interFamily">
      <div className="border  border-gray-300 rounded-lg ">
        <div className="flex  space-x-5 p-5">
          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-[110px] w-[110px] bg-gray-100 rounded-md  "></div>
            </div>
          ) : (
            <>
              {details?.customer?.avatarUrl ? (
                <img
                  src={details?.customer?.avatarUrl}
                  alt="img"
                  className="rounded-md "
                  style={{
                    height: "110px",
                    width: "110px",
                  }}
                />
              ) : (
                <Avatar
                  name={
                    details?.customer?.firstName +
                    " " +
                    details?.customer?.lastName
                  }
                  color="#0C51C1"
                  className="rounded-md"
                  style={{
                    width: "110px",
                    height: "110px",
                    background: "#0C51C1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  textSizeRatio={2}
                />
              )}
            </>
          )}

          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-24 bg-gray-100  w-[16rem] "></div>
            </div>
          ) : (
            <div>
              <div className="flex items-center space-x-3">
                <p className="text-[27px] capitalize truncate font-semibold">
                  {`${details?.customer?.firstName} ${details?.customer?.lastName}`}
                </p>
                {details?.isNew && (
                  <div className="bg-[#579AF5] rounded-md p-1 px-4  h-1/2 text-[10px] text-white flex items-center  space-x-1">
                    <Icon icon="ic:sharp-star-outline" width={15} />
                    <span className="uppercase font-bold ">new</span>
                  </div>
                )}
              </div>
              <div className="flex items-center space-x-3 mt-1 text-sm ">
                {details?.customer?.email && (
                  <p className="text-gray-400 flex items-center space-x-1 ">
                    <Icon icon="material-symbols:alternate-email-rounded" />

                    <span className="truncate ">
                      {details?.customer?.email}
                    </span>
                  </p>
                )}
                {details?.customer?.phoneNumber && (
                  <p className="text-gray-400 flex items-center space-x-1">
                    <Icon icon="material-symbols:call-sharp" />

                    <span className="truncate">
                      {details?.customer?.phoneNumber}
                    </span>
                  </p>
                )}
              </div>
              <p className="text-gray-400 mt-1 flex items-center space-x-1 text-sm">
                <span className="truncate">
                  Last visited{" "}
                  {moment(details?.lastVisit).format("MMM D, YYYY")}
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="flex mt-4 border-t  border-gray-300">
          <div className="border-r border-gray-300 text-start pt-4  w-[22%]">
            <div className="mx-auto w-[65%] ">
              <p className="text-[#579AF5] text-[10px] font-medium mb-1">
                Average Spend
              </p>
              {isLoading ? (
                <div className="animate-pulse flex justify-center">
                  <div className="h-10 bg-gray-100  w-[6rem] "></div>
                </div>
              ) : (
                <p className="text-lg font-medium break-all ">
                  {details?.averageSpend
                    ? useCurrency(details?.averageSpend)
                    : " "}
                </p>
              )}
            </div>
          </div>
          <div className="border-r border-gray-300 text-start pt-4  w-[28%]">
            <div className="mx-auto w-[65%] ">
              <p className="text-[#579AF5] text-[10px] font-medium mb-1">
                Total Appointments
              </p>
              {isLoading ? (
                <div className="animate-pulse flex justify-center">
                  <div className="h-10 bg-gray-100  w-[3rem] "></div>
                </div>
              ) : (
                <p className="text-md font-medium">
                  {details?.appointmentCount}
                </p>
              )}
            </div>
          </div>
          <div className="border-r border-gray-300 text-start pt-4  w-[28%]">
            <div className="mx-auto w-[65%] ">
              <p className="text-[#579AF5] text-[10px] font-medium mb-1">
                Last Appointment
              </p>
              {isLoading ? (
                <div className="animate-pulse flex justify-center">
                  <div className="h-10 bg-gray-100  w-[3rem] "></div>
                </div>
              ) : (
                <p className="text-md font-medium">
                  {moment(details?.lastAppointment).format("MMM, D, YYYY")}
                </p>
              )}
            </div>
          </div>
          <div className=" text-start pt-4 pb-5 w-[28%]">
            <div className="mx-auto w-[65%]  ">
              <p className="text-[#579AF5] text-[10px] font-medium mb-1">
                Total Spend
              </p>
              {isLoading ? (
                <div className="animate-pulse flex justify-center">
                  <div className="h-10 bg-gray-100  w-[7rem] "></div>
                </div>
              ) : (
                <p className="text-md font-medium break-all">
                  {details?.totalSpend ? useCurrency(details?.totalSpend) : " "}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-6">
        <p className="text-[18px] capitalize truncate font-semibold">
          Upcoming Appointments
        </p>

        <div
          className="flex items-center space-x-1 cursor-pointer text-[#579AF5] text-xs"
          onClick={gotoAppointments}
        >
          <span>View all appointments</span>
          <Icon icon="mdi:keyboard-arrow-right" width={18} />
        </div>
      </div>
      <div className="flex justify-start mt-5">
        {isLoading ? (
          <div className="animate-pulse flex justify-center">
            <div className="h-36 bg-gray-100  w-[11rem] "></div>
          </div>
        ) : (
          <>
            {details?.upcomingAppointments.length ? (
              details?.upcomingAppointments.map((appointment, index) => (
                <div
                  key={index}
                  className="border border-gray-300 rounded-md p-3"
                >
                  <p className="font-medium text-[11px]">
                    {TwentyFourHourFormat(appointment?.timeSlot)} .{" "}
                    {moment(appointment?.date).format("MMMM d, YYYY")}
                  </p>
                  <p className="text-[11px] text-[#A9A9A9] mt-2">Service</p>
                  <p className="font-medium text-md">
                    {appointment?.service?.name}
                  </p>
                  <p className="text-[11px] text-[#A9A9A9] mt-2">price</p>
                  <p className="font-medium text-md">
                    {useCurrency(appointment?.price)}
                  </p>
                </div>
              ))
            ) : (
              <div className="text-center w-full">No Upcoming Appointments</div>
            )}
          </>
        )}
      </div>
      {/* <div className="flex justify-between mt-4">
        <p className="text-[18px] capitalize truncate font-semibold">Notes</p>

        <div className="flex items-center space-x-1 cursor-pointer text-[#579AF5] text-xs">
          <Icon icon="ic:baseline-plus" width={17} />

          <span>Add note</span>
        </div>
      </div>
      <div className="border border-gray-300 rounded-md p-3 mt-3">
        <div className="flex justify-between text-sm">
          <div className="flex items-center space-x-1 cursor-pointer text-sm">
            <Icon icon="fluent:note-28-filled" width={20} />

            <span>
              Note by <span className="font-semibold">Izekor Coker</span>
            </span>
          </div>
          <div className="flex items-center space-x-1 cursor-pointer text-[12px]">
            <Icon icon="mdi:calendar-today" width={20} />

            <span>Yesterday by 10:33 AM</span>
          </div>
        </div>
        <div className="w-[80%] text-sm mt-3">
          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.
          Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla,
          mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis
          tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non
        </div>
      </div> */}
    </div>
  );
}
