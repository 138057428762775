import { Icon } from "@iconify/react";
import React from "react";
interface Props {
  next: () => void;
  prev: () => void;
  showFrom: number;
  totalRecords: number;
  showTo: number;
  currentPage: number;
}
const Pagination = (props: Props) => {
  return (
    <div className="flex justify-between w-full">
      <div className="text-xs text-gray-500">
        Showing results {props.showFrom} - {props.showTo} of{" "}
        {props.totalRecords}
      </div>
      <div className="flex items-center px-4 space-x-3 text-xs text-gray-500 md:pr-6">
        {props.currentPage > 1 && (
          <p
            className="text-[8px] flex items-center cursor-pointer"
            onClick={() => {
              props.prev();
            }}
          >
            <Icon icon="akar-icons:arrow-left" />
            <span className="pl-1"> Prev</span>
          </p>
        )}

        {!(props.showTo >= props.totalRecords) && (
          <p
            className="text-[8px] flex items-center cursor-pointer"
            onClick={() => {
              props.next();
            }}
          >
            <span className="pr-1"> Next</span>
            <Icon icon="akar-icons:arrow-right" />
          </p>
        )}
      </div>
    </div>
  );
};

export default Pagination;
