import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getCurrencySymbol } from "../../hooks/useCurrency";
import { paymentType, revenueType } from "../../interfaces/analytics";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface Props {
  data?: revenueType;
}
// const [setChartData]=u
const FullChart = (props: Props) => {
  const [coordinates, setCoordinates] = useState<paymentType[]>([
    {
      yaxis: 0,
      day: "",
      label: "",
    },
  ]);
  useEffect(() => {
    props.data && setCoordinates([...props.data.chart]);
    // setChartData(props.data)
  }, [props.data]);
  const data = {
    labels: coordinates.map((data) => data.day),
    datasets: [
      {
        data: coordinates.map((data: any) => data.yaxis),
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "#0066F1");
          gradient.addColorStop(0.8, "#80b3fa2d");
          return gradient;
        },
        tension: 0.3,
        borderColor: "#000",
        borderWidth: 1,
        fill: true,
      },
    ],
  };
  const filterChartTooltip = (context: any) => {
    if (props.data?.total) {
      if (props.data && props.data.chart.length) {
        const option = coordinates.find(
          (point: any, index: number) => index === context[0].dataIndex
        );
        if (option) {
          return option.label;
        } else return context[0].label;
      }
    } else {
      return context[0].label;
    }
  };
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 0,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.parsed.y;
            if (label > 999999) {
              return (
                (props.data?.total ? getCurrencySymbol() : "") +
                parseFloat((label / 1000000).toFixed(1)) +
                "M"
              );
            } else if (label > 999) {
              return (
                (props.data?.total ? getCurrencySymbol() : "") +
                parseFloat((label / 1000).toFixed(1)) +
                "K"
              );
            }

            return (props.data?.total ? getCurrencySymbol() : "") + label;
          },
          title: function (context: any) {
            let title = "default";
            title = filterChartTooltip(context);
            return title;
          },
        },
      },
    },
    tension: 0.3,

    scales: {
      y: {
        min: 0,
        display: true,
        grid: {
          drawOnChartArea: false,
        },
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          callback: (value: any) => {
            if (props.data?.total) {
              if (value > 999999) {
                return (
                  getCurrencySymbol() +
                  parseFloat((value / 1000000).toFixed(1)) +
                  "M"
                );
              } else if (value > 999) {
                return (
                  getCurrencySymbol() +
                  parseFloat((value / 1000).toFixed(1)) +
                  "K"
                );
              }

              return getCurrencySymbol() + value;
            } else {
              return value;
            }
          },
        },
      },
    },
  };
  return (
    <div className="object-cover w-full h-72">
      <Line options={options} data={data} />
    </div>
  );
};

export default FullChart;
