import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Toast } from "../components/Base/toast";
import ClientModal from "../components/CRM/clientModal";
import SearchComponent from "../components/SearchComponent";
import { ICRMClient } from "../interfaces/crm";
import ImagePlaceholder from "../assets/images/empty.png";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  selectCRM,
  SET_CRM_CLIENTS,
  SET_CRM_OVERVIEW,
} from "../features/pages/pages";
import useCurrency from "../hooks/useCurrency";
import { Button } from "../components/Base/Button";
import CampaignModal from "../components/Modals/CampaignModal";

export default function CRM() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [selectedClients, setSelectedClients] = useState<ICRMClient[]>([]);
  const [search, setSearch] = useState("");
  const [modalProps, setModalProps] = useState<ICRMClient>();
  const dispatch = useAppDispatch();
  const storeCrmOverview = useAppSelector(selectCRM)?.overview;
  const storeCrmClients = useAppSelector(selectCRM)?.clients;

  const sendBulkMessage = () => {
    if (selectedClients.length === 0)
      return Toast("Please select at least one client", "warning");
    setShowCampaignModal(true);
  };

  const sendEmail = (client: ICRMClient) => {
    setSelectedClients([client]);
    setShowCampaignModal(true);
  };

  const toggleSelection = () => {
    if (selectedClients.length === storeCrmClients.length) {
      setSelectedClients([]);
    } else {
      setSelectedClients(storeCrmClients);
    }
  };

  const selectClient = (client: ICRMClient, e: React.MouseEvent) => {
    e.stopPropagation();
    if (
      selectedClients.find(
        (selected) => selected.customer.id === client.customer.id
      )
    ) {
      setSelectedClients((prev) =>
        prev.filter((selected) => selected.customer.id !== client.customer.id)
      );
    } else {
      setSelectedClients((prev) => [...prev, client]);
    }
  };

  useEffect(() => {
    const getCRM = async () => {
      try {
        !storeCrmClients?.length && setIsloading(true);
        const endpoints = ["/api/crm", `/api/crm/client?search=${search}`];
        const [overview, clients] = await Promise.all(
          endpoints.map((endpoint) => axios.get(endpoint))
        );
        if (overview.data) {
          dispatch(SET_CRM_OVERVIEW(overview.data.data));
        }
        if (clients.data) {
          dispatch(SET_CRM_CLIENTS(clients.data.data));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsloading(false);
      }
    };
    getCRM();
  }, [search]);

  return (
    <main className="h-screen overflow-hidden pt-2">
      <section className="flex space-x-5">
        <div className="p-4 bg-white rounded-lg border border-gray-300 flex flex-col w-[170px] space-y-1">
          <p className="text-xs ">Total clients</p>
          {isloading ? (
            <div className="h-12 bg-gray-100  w-10 animate-pulse "></div>
          ) : (
            <p className="font-bold text-[40px] font-outfit">
              {storeCrmOverview?.totalClients}
            </p>
          )}
        </div>
        <div className="p-4 bg-white rounded-lg border border-gray-300 flex flex-col w-[170px] space-y-1">
          <p className="text-xs ">
            <span className="text-blue-400">New</span>
            /Returning
          </p>
          {isloading ? (
            <div className="h-12 bg-gray-100  w-20 animate-pulse "></div>
          ) : (
            <p className="font-bold text-[40px] font-outfit">
              <span className="text-blue-400">
                {storeCrmOverview?.newClients}
              </span>
              /{storeCrmOverview?.returningClients}
            </p>
          )}
        </div>
      </section>
      <section className="mt-5 border  bg-white rounded-lg min-h-[78%] p-4">
        <div className="flex items-center w-full justify-between mb-0">
          <div className="flex items-center space-x-2">
            <p className="font-medium text-2xl">All Clients</p>
            {selectedClients.length > 0 ? (
              <p>
                <span className="text-xs">
                  {selectedClients.length} selected{" "}
                </span>
                <span
                  className="text-xs text-blue-400 cursor-pointer"
                  onClick={() => setSelectedClients([])}
                >
                  Clear selection
                </span>
              </p>
            ) : (
              <div onClick={toggleSelection} className="cursor-pointer">
                <span className="text-xs">Select all</span>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-[250px]">
              <SearchComponent
                crm
                search={(e: string) => {
                  setSearch(e);
                }}
              />
            </div>
            <Button onClick={sendBulkMessage}>Send Campaign</Button>
          </div>
        </div>
        <div className="overflow-auto max-h-[65vh]">
          {isloading ? (
            <table
              className="table-auto w-full text-left capitalize border-spacing-3 "
              style={{
                borderCollapse: "separate",
                borderSpacing: "0px 15px",
              }}
            >
              <thead>
                <tr className="text-[#6F6F6F] text-md text-center !font-[400]">
                  <th className="font-[400] text-left">Name</th>
                  <th className="font-[400]">Last Visit</th>
                  <th className="font-[400] text-center">Average Spent</th>
                  <th className="font-[400]">appointments</th>
                  <th className="font-[400] text-left pl-20">contact</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(6)].map((i) => (
                  <tr
                    key={i}
                    className="pt-10 cursor-pointer text-center text-xs animate-pulse"
                  >
                    <td>
                      <div className="flex items-center justify-start space-x-2">
                        <Avatar
                          name="John Deo "
                          size="40"
                          round={true}
                          color="#F1F1F1"
                          fgColor="#A9A9A9"
                          textSizeRatio={0.7}
                          className="flex items-center justify-center"
                        />

                        <div className="h-4 bg-gray-100 rounded-full w-24 "></div>
                      </div>
                    </td>
                    <td>
                      <div className="h-4 bg-gray-100 rounded-full  "></div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <div className="h-4 bg-gray-100 rounded-full w-20 "></div>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <div className="h-4 bg-gray-100 rounded-full w-10 "></div>
                      </div>
                    </td>
                    <td>
                      <div className="flex space-x-3 justify-center  ">
                        <div className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem] ">
                          <Icon
                            icon="material-symbols:alternate-email-rounded"
                            width={17}
                          />
                          <span>email</span>
                        </div>
                        <div className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem]">
                          <Icon icon="ic:outline-sms" width={17} />
                          <span>SMS</span>
                        </div>
                        <div className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem]">
                          <Icon icon="material-symbols:call-sharp" width={17} />
                          <span>call</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : storeCrmClients?.length ? (
            <table
              className="table-auto w-full text-left capitalize border-spacing-3 "
              style={{
                borderCollapse: "separate",
                borderSpacing: "0px 15px",
              }}
            >
              <thead>
                <tr className="text-[#6F6F6F] text-md text-center !font-[400]">
                  <th className="font-[400] text-left">Name</th>
                  <th className="font-[400]">Last Visit</th>
                  <th className="font-[400] text-center">Average Spend</th>
                  <th className="font-[400]">appointments</th>
                  <th className="font-[400] text-left">contact</th>
                </tr>
              </thead>
              <tbody>
                {storeCrmClients.map((client, clientIndex) => (
                  <tr
                    key={clientIndex}
                    className="pt-10 cursor-pointer text-center text-xs group"
                    onClick={() => {
                      setModalProps(client);
                      setModalOpen(true);
                    }}
                  >
                    <td>
                      <div className="flex items-center justify-start space-x-2">
                        <div
                          onClick={(e) => selectClient(client, e)}
                          className="flex flex-shrink-0 h-10 w-10 rounded-full overflow-hidden relative z-30"
                        >
                          <div
                            className={`absolute hidden group-hover:grid ${
                              selectedClients.find(
                                (selected) =>
                                  selected.customer.id === client.customer.id
                              )
                                ? "!grid bg-opacity-70"
                                : ""
                            } bg-primary group-hover:bg-opacity-50 h-full w-full place-items-center`}
                          >
                            <Icon
                              className="w-8 h-8 text-white"
                              icon={"bi:check-circle"}
                            />
                          </div>
                          {client?.customer?.avatarUrl ? (
                            <img
                              src={client?.customer?.avatarUrl}
                              className="h-full w-full object-cover"
                            />
                          ) : (
                            <Avatar
                              name={`${client?.customer?.firstName} ${client?.customer?.lastName}`}
                              size="40"
                              round={true}
                              color="#F1F1F1"
                              fgColor="#A9A9A9"
                              textSizeRatio={0.7}
                              className="flex items-center justify-center"
                            />
                          )}
                        </div>

                        <span>
                          {`${client?.customer?.firstName} ${client?.customer?.lastName}`}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {moment(client?.lastVisit).format("MMM D, YYYY")}
                      </div>
                    </td>
                    <td>
                      <div className="">
                        {client?.averageSpend
                          ? useCurrency(client?.averageSpend)
                          : " "}
                      </div>
                    </td>
                    <td>
                      <div className="">{client?.appointmentCount}</div>
                    </td>
                    <td>
                      <div className="flex space-x-3">
                        {client.customer.email && (
                          <div
                            className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem] "
                            onClick={(e) => {
                              e.stopPropagation();
                              sendEmail(client);
                            }}
                          >
                            <Icon
                              icon="material-symbols:alternate-email-rounded"
                              width={17}
                            />
                            <span>email</span>
                          </div>
                        )}
                        {client.customer.phoneNumber && (
                          <a
                            href={
                              client?.customer?.phoneNumber
                                ? `sms:${client?.customer?.phoneNumber}?&body=contact`
                                : ""
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              !client?.customer?.phoneNumber &&
                                e.preventDefault();
                              !client?.customer?.phoneNumber &&
                                Toast(
                                  "This client has no phone number",
                                  "warning"
                                );
                            }}
                          >
                            <div className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem]">
                              <Icon icon="ic:outline-sms" width={17} />
                              <span>SMS</span>
                            </div>
                          </a>
                        )}
                        {client.customer.phoneNumber && (
                          <a
                            href={
                              client?.customer?.phoneNumber
                                ? `tel:${client?.customer?.phoneNumber}`
                                : ""
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              !client?.customer?.phoneNumber &&
                                e.preventDefault();
                              !client?.customer?.phoneNumber &&
                                Toast(
                                  "This client has no phone number",
                                  "warning"
                                );
                            }}
                          >
                            <div className="bg-[#F1F1F1] p-1 px-3 flex items-center text-[#A9A9A9] space-x-1 rounded-[0.3rem]">
                              <Icon
                                icon="material-symbols:call-sharp"
                                width={17}
                              />
                              <span>call</span>
                            </div>
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="h-full w-full flex items-center justify-center">
              <div className="   text-center ">
                <img
                  src={ImagePlaceholder}
                  alt=""
                  className="object-contain mx-auto "
                />
                <p className="font-semibold mt-5">
                  Your business currently has no Clients
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        <ClientModal
          clientDetails={modalProps}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
        />
        <CampaignModal
          closeModal={() => {
            setShowCampaignModal(false);
            setSelectedClients([]);
          }}
          modalOpen={showCampaignModal}
          clients={selectedClients}
        />
      </section>
    </main>
  );
}
