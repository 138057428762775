import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  icon: any;
  to: string;
}

const Nobusinessbox = (props: Props) => {
  return (
    <Link
      to={props.to}
      className="flex flex-col justify-center items-center w-36 h-40 xs:w-48 p-5 bg-white rounded cursor-pointer xs:h-[13rem]"
    >
      <Icon icon={props.icon} width={90} />

      <p className="text-xs font-semibold text-center xs:text-sm">
        {props.text}
      </p>
    </Link>
  );
};

export default Nobusinessbox;
