import { Icon } from "@iconify/react";
interface Props {
  text: string;
  icon?: any;
  path: string;
}

const FeaturesBox = (props: Props) => {
  return (
    <div
      onClick={() => {
        window.open(props.path);
      }}
      className="flex items-center flex-none h-24 px-5 py-8 bg-blue-400 cursor-pointer w-72 rounded-xl"
    >
      <div className="flex items-center space-x-3 text-sm font-semibold text-white">
        <Icon icon={props.icon} width={23} />
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default FeaturesBox;
