import React, { useEffect, useState } from "react";
import causalLife from "../assets/merchant1.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Select } from "./Base/Select";
import useMeridem from "../hooks/useMeridem";
import { Icon } from "@iconify/react";
import { useAppSelector } from "../store/hooks";
import { selectUser } from "../features/auth/auth";
import useFileUpload from "../hooks/useFileUpload";
import Webcam from "react-webcam";
import ModalContainer from "./Modals/ModalContainer";
import { Button } from "./Base/Button";
import { Input } from "./Base/Input";
import { appointmentsTypeAddons } from "../interfaces/appointments";
import axios from "axios";
import Spinner from "./Base/Spinner";
import { Toast } from "./Base/toast";
import GoogleSearchComponent from "./GoogleSearchComponent";
import AddressCard from "./AddressCard";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { InputValidationError } from "./InputValidationError";
import {
  appointmentCustomerDetails,
  CustomisationAtrributes,
  IaddressAttributes,
  ServiceApiAttributesI,
  StaffApiAttributes,
} from "../interfaces/addappointment";

interface Props {
  openCustomise: boolean;
  handleClose: () => void;
}
export default function ServiceCustomize({
  openCustomise,
  handleClose,
}: Props) {
  useEffect(() => {
    const getAllServices = async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`/api/business/service`);
        console.log("services are", data);
        setServiceList(data);
        setServiceDropdownOptions(
          data.map((service: ServiceApiAttributesI) => ({
            text: service.label,
            value: service.id,
          }))
        );
        // use the first service as the default option
        setService(data[0]);
      } catch (e) {
        console.error(e);
      }
    };
    getAllServices();
  }, []);

  const { submitFile } = useFileUpload();
  const [selectedItem, setSelectedItem] = useState(0);
  const inputFileRef = React.useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [isStacking, setIsStacking] = useState(false);
  const [activeTime, setActiveTime] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<any>({});
  const [serviceList, setServiceList] = useState<ServiceApiAttributesI[]>([]);
  const [serviceDropdownOptions, setServiceDropdownOptions] = useState<
    Array<{
      value: number;
      text: string;
    }>
  >([]);
  const [value] = useState<Date>(new Date());
  const [newdate, setNewdate] = useState<any>();
  const { formatTime, htmlDate } = useMeridem();
  const loggedInUser = useAppSelector(selectUser);
  const [availableSlots, setAvailableSlots] = useState<any>(["10:00"]);
  const [availableStaff, setAvailableStaff] = useState<StaffApiAttributes[]>([
    {
      id: 0,
      role: "",
      active: true,
      businessId: 0,
      userId: 0,
      profilePic: "",
      firstName: "",
      lastName: "",
      phone: "",
      createdAt: "",
      updatedAt: "",
    },
  ]);
  const [apiTime, setApiTime] = useState("");
  const [selectedCustomizations, setSelectedCustomizations] = useState<any>([]);
  const [selectedAddons, setSelectedAddons] = useState<any>([]);
  const [openCamera, setopenCamera] = useState(false);
  const [submittingFile, setSubmittingFile] = useState(false);
  const [issubmitting, setisSubmitting] = useState(false);
  const [appointmentImages, setAppointmentImages] = useState<string[]>([]);
  const webcamRef = React.useRef<any>(null);
  const [imgSrc, setImgSrc] = React.useState("");
  const [addressProp, setAddressProp] = useState("");
  const [address, setAddress] = useState({
    country: "",
    state: "",
    city: "",
    street: "",
    longitude: "",
    latitude: "",
  });
  const [errorText, setErrorText] = useState(false);
  const [addresses, setAddresses] = useState<IaddressAttributes>();
  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef, setImgSrc]);
  const [service, setService] = useState<ServiceApiAttributesI>();
  const submitFileHere = async (event: any) => {
    try {
      setSubmittingFile(true);
      const file = event.target.files[0];
      const imageUrl = await submitFile(file);
      setAppointmentImages([...appointmentImages, imageUrl]);
      setGalleryModal(false);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    } finally {
      setSubmittingFile(false);
    }
  };
  function srcToFile(src: string, fileName: string, mimeType: string) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const closeThisModal = () => {
    setSelectedItem(0);
    handleClose();
    setAvailableSlots([]);
    setSelectedStaff({});
    setAvailableStaff([]);
    setSelectedCustomizations([]);
    setSelectedAddons([]);
    setAppointmentImages([]);
    setService(serviceList[0]);
    setAddresses(undefined);
    setActiveTime("");
    setAddressProp("deletethefield");
    setAdditionalNotes("");
    setCustomer(undefined);
  };
  const handleBook = async (data: appointmentCustomerDetails) => {
    try {
      setIsStacking(true);
      const payload: any = {
        appointmentInfo: {
          serviceId: service?.id,
          staffId: selectedStaff.id ? selectedStaff.id : "",
          serviceSelection: {
            addons: selectedAddons,
            customisations: selectedCustomizations,
          },
          timeSlot: activeTime,
          address: {
            ...addresses,
            latitude: addresses?.latitude.toString(),
            longitude: addresses?.longitude.toString(),
          },
          images: appointmentImages.filter((image) => image),
          date: apiTime,
          ...(additionalNotes ? { optionalNote: additionalNotes } : null),
        },

        customerInfo: {
          ...data,
          phoneNumber: data.phoneNumber ? data.phoneNumber.toString() : "",
        },
      };
      setCustomer(data);
      !service?.isHome && delete payload.appointmentInfo.address;

      if (!selectedStaff.id) delete payload.appointmentInfo.staffId;
      await axios.post(`/api/appointment`, payload);
      setSelectedItem(5);
      setAdditionalNotes("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsStacking(false);
    }
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("Client's first name  is required"),
    lastName: yup.string().required("Client's last name  is required"),
    email: yup
      .string()
      .required("Client's email address is required")
      .email("Please enter a valid email address"),
    phoneNumber: yup.string(),
  });
  const [customer, setCustomer] = useState<appointmentCustomerDetails>();
  return (
    <ModalContainer
      modalOpen={openCustomise}
      closeModal={() => {
        closeThisModal();
      }}
      noPadding
      size="large"
    >
      <div>
        <div className="h-[30rem]">
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            emulateTouch={false}
            selectedItem={selectedItem}
            className="h-full"
            renderIndicator={(
              clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
              isSelected: boolean
            ) => (
              <div
                className={
                  (isSelected ? "bg-gray-800 w-[50px] " : "bg-gray-600 w-2 ") +
                  "rounded-full h-2 mx-1.5 inline-block"
                }
              ></div>
            )}
          >
            {/* first one  */}
            <div className=" min-h-[15rem] max-h-[28rem] overflow-y-hidden  text-start font-inter p-8 h-full">
              <div className="flex items-center space-x-5 mb-2">
                <p className="text-xl  ">Select then customize the service</p>
                <div className="w-[10rem]">
                  <Select
                    options={serviceDropdownOptions}
                    label=""
                    onChange={(e) => {
                      const selectedService = serviceList.find(
                        (service) => service.id === Number(e.target.value)
                      );
                      if (selectedService) setService(selectedService);
                    }}
                  />
                </div>
              </div>
              {!!service && (
                <div className=" grid grid-cols-5 gap-6 h-full">
                  <div className="col-span-2 flex flex-col space-y-3 break-words">
                    {service.images.length ? (
                      <div className=" md:max-h-[18rem] overflow-hidden">
                        <img
                          src={service.images[0].gallery.file}
                          alt={service.name}
                          width={500}
                          height={600}
                          className="object-cover w-full h-full rounded-xl"
                        />
                      </div>
                    ) : null}
                    <div>
                      <p className="font-inter font-semibold text-2xl">
                        {service.name}
                      </p>
                      <p className="font-inter text-xl font-semibold">
                        ${service.price ? service.price.toLocaleString() : " "}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-3 flex flex-col ">
                    <div className="flex flex-wrap overflow-auto max-h-[70%] ">
                      {service.customisations.length ? (
                        <>
                          {service.customisations.map(
                            (
                              customisation: CustomisationAtrributes,
                              customisationIndex
                            ) => (
                              <div
                                key={customisationIndex}
                                className="m-1  md:w-[48%]"
                              >
                                {customisation.value.length ? (
                                  <Select
                                    options={customisation.value}
                                    width="py-1 h-[2.7rem]"
                                    showSelect
                                    label={customisation.name}
                                    onChange={(e) => {
                                      const newFormat: any = {
                                        ...customisation,
                                        value: [e.target.value],
                                      };

                                      !selectedCustomizations.some(
                                        (current: any) =>
                                          current.id === newFormat.id
                                      ) &&
                                        setSelectedCustomizations([
                                          ...selectedCustomizations,
                                          newFormat,
                                        ]);
                                    }}
                                  />
                                ) : (
                                  <div className="flex flex-col">
                                    <label
                                      className={
                                        "text-[10px] text-sm text-left"
                                      }
                                    >
                                      {customisation.name}
                                    </label>

                                    <input
                                      className="w-full h-[3rem] bg-white py-2 px-4 border border-solid text-black rounded-md placeholder:text-gray-400 border-gray-300 focus:outline-0 focus:border-purple-600 disabled:bg-slate-200"
                                      placeholder={"Enter option"}
                                      onChange={(e) => {
                                        const newFormat: any = {
                                          ...customisation,
                                          value: [e.target.value],
                                        };
                                        selectedCustomizations.some(
                                          (current: any) =>
                                            current.id === newFormat.id
                                        )
                                          ? setSelectedCustomizations(
                                              selectedCustomizations.map(
                                                (each: any) => {
                                                  if (
                                                    each.id === newFormat.id
                                                  ) {
                                                    return newFormat;
                                                  } else {
                                                    return each;
                                                  }
                                                }
                                              )
                                            )
                                          : setSelectedCustomizations([
                                              ...selectedCustomizations,
                                              newFormat,
                                            ]);
                                      }}
                                      type={"text"}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <div className="h-full flex flex-col items-center justify-center">
                          <p className="">
                            There are no available customizations for this
                            service
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mt-auto mb-10 flex flex-col items-end  ">
                      <div>
                        <Button
                          variant="bg-black text-white font-semibold text-lg"
                          onClick={() => {
                            if (!service)
                              Toast("Please select a service", "warning");
                            setSelectedItem(1);
                          }}
                        >
                          Proceed
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* second one */}
            <div className="flex flex-col h-full max-h-[28rem] overflow-y-auto  p-5">
              <div className="flex justify-center mb-5 space-x-2 items-center  font-medium">
                <span className="float-left" onClick={() => setSelectedItem(0)}>
                  <Icon
                    icon="akar-icons:arrow-left"
                    width="14"
                    className=" cursor-pointer"
                  />
                </span>
                <p className="text-xl m-0 font-inter ">
                  {!service?.isHome
                    ? "When would you like the client to come in"
                    : "Please select a preferred day"}
                </p>
                <span className="float-left" onClick={() => setSelectedItem(2)}>
                  <Icon
                    icon="akar-icons:arrow-right"
                    width="14"
                    className=" cursor-pointer"
                  />
                </span>
              </div>
              <div className="flex flex-col items-center justify-center">
                <Calendar
                  tileDisabled={({ date }) =>
                    date < new Date(Date.now() - 86400000)
                  }
                  value={value}
                  prev2Label=""
                  next2Label=""
                  minDate={new Date()}
                  onChange={async (e: any) => {
                    // request already in progress
                    if (isLoading) return;
                    setNewdate(formatTime(e.toISOString()));
                    const apiFormat = htmlDate(e.toISOString());
                    setApiTime(apiFormat);
                    try {
                      setIsLoading(true);
                      const {
                        data: { data },
                      } = await axios.get(
                        `api/appointment/schedule?serviceId=${service?.id}&date=${apiFormat}`
                      );
                      if (typeof data[0] === "string") {
                        setAvailableSlots(data);
                        setSelectedStaff({});
                      } else {
                        setAvailableSlots(data[0].slots);
                        setSelectedStaff(data[0]);
                        setAvailableStaff(data);
                      }
                      setSelectedItem(2);
                    } catch (e: any) {
                      console.error(e.response.data.message);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                />
              </div>
            </div>
            {/* third one  */}
            <div className="flex flex-col-reverse sm:flex-row h-full  justify-center overflow-auto">
              <div
                className={
                  (selectedStaff.id ? "sm:w-[53%]  " : " ") +
                  " flex flex-col p-8"
                }
              >
                <div
                  className="flex justify-start space-x-2 items-center  font-medium"
                  onClick={() => {
                    setActiveTime("");
                    setSelectedItem(1);
                  }}
                >
                  <Icon
                    icon="akar-icons:arrow-left"
                    width="14"
                    className=" cursor-pointer"
                  />
                  <p className="text-sm  font-inter cursor-pointer ">
                    {newdate}
                  </p>
                </div>

                <div className=" mt-2">
                  <div className="font-inter mt-2 mb-4">
                    {selectedStaff.id ? (
                      <p className="text-left">
                        <span className="font-semibold">
                          {selectedStaff.firstName}&apos;s
                        </span>{" "}
                        available time slots
                      </p>
                    ) : (
                      <p className="text-center">Available slots</p>
                    )}
                  </div>

                  <div className="grid grid-cols-4 gap-3 max-h-[20rem]  overflow-y-auto ">
                    {availableSlots.map((time: string, timeIndex: number) => (
                      <button
                        key={timeIndex}
                        className={
                          (activeTime === time
                            ? "border-[#011128]  text-white bg-[#011128] "
                            : "border-black ") +
                          " border-2 font-semibold  py-1 px-4 cursor-pointer text-center rounded-lg hover:border-[#011128]  hover:text-white hover:bg-[#011128] dark:border-white"
                        }
                        onClick={() => {
                          setAddressProp("");
                          console.log("clicked");
                          setActiveTime(time);
                          setSelectedItem(service?.isHome ? 3 : 4);
                        }}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {selectedStaff.id && (
                <div className="bg-black sm:w-[54%] p-8 text-white">
                  <div className="flex justify-end pr-4">
                    <div className="cursor-pointer" onClick={closeThisModal}>
                      <Icon icon="bx:bx-x" width="30" />
                    </div>
                  </div>
                  <div>
                    <p className="text-lg  text-left font-semibold">
                      Available Staff
                    </p>
                    <p className="text-xs  text-left leading-7">on {newdate}</p>
                  </div>
                  <div className="grid grid-cols-3 gap-4 mt-4 overflow-auto">
                    {availableStaff.map(
                      (staff: StaffApiAttributes, staffIndex) => (
                        <div
                          key={staffIndex}
                          className="bg-white rounded-md text-black w-full cursor-pointer overflow-hidden"
                          onClick={() => {
                            setAvailableSlots(staff.slots);

                            setSelectedStaff(staff);
                          }}
                        >
                          <div className="h-[7rem] w-full">
                            <img
                              src={causalLife}
                              alt="question"
                              className="object-cover w-full h-full"
                            />
                          </div>
                          <div className="relative">
                            {selectedStaff === staff && (
                              <div className="flex items-center justify-center w-[25px] h-[25px] absolute -top-[10px] right-1 rounded-full bg-white">
                                <Icon icon="feather:check-circle" />
                              </div>
                            )}
                            <div className="p-2">
                              <p className={"font-medium  text-sm text-center"}>
                                {staff.firstName}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* fourth optional  */}
            <>
              {!!service?.isHome && (
                <div className="flex flex-col justify-between  mx-[29px] md:mx-[49px] h-full max-h-[90%]">
                  <div>
                    <div
                      className="flex justify-start space-x-2 mt-4 items-center  font-semibold"
                      onClick={() => {
                        setAddresses(undefined);
                        setAddressProp("deletethefield");
                        setSelectedItem(2);
                      }}
                    >
                      <Icon
                        icon="akar-icons:arrow-left"
                        width="20"
                        className=" cursor-pointer"
                      />
                    </div>
                    <p className=" font-inter">
                      Where would you like this done?
                    </p>
                    <div className="flex items-center relative mt-[27px] justify-between  md:w-full md:px-[47px] ">
                      <div className=" w-full relative">
                        <GoogleSearchComponent
                          address={addressProp}
                          selected={async (place) => {
                            // Get formatted address, city, state, country from latitude & longitude when
                            // the below parser will work for most of the countries
                            let city, state, country, street;
                            for (
                              let i = 0;
                              i < place.address_components.length;
                              i++
                            ) {
                              for (
                                let j = 0;
                                j < place.address_components[i].types.length;
                                j++
                              ) {
                                switch (place.address_components[i].types[j]) {
                                  case "locality":
                                    city =
                                      place.address_components[i].long_name;
                                    break;
                                  case "postal_town":
                                    city =
                                      place.address_components[i].long_name;
                                    break;
                                  case "administrative_area_level_1":
                                    state =
                                      place.address_components[i].long_name;
                                    break;
                                  case "country":
                                    country =
                                      place.address_components[i].long_name;
                                    break;
                                  case "street_address":
                                    street =
                                      place.address_components[i].long_name;
                                    break;
                                  case "route":
                                    street =
                                      place.address_components[i].long_name;
                                }
                              }
                            }

                            setAddresses({
                              country: country ?? "",
                              state: state ?? "",
                              city: city ?? "",
                              street: street ?? "",
                              longitude: place.geometry.location.lng(),
                              latitude: place.geometry.location.lat(),
                            });

                            setAddress({
                              longitude: place.geometry.location.lng(),
                              latitude: place.geometry.location.lat(),
                              state: state ?? "",
                              city: city ?? "",
                              street: street ?? "",
                              country: country ?? "",
                            });
                          }}
                        />
                      </div>

                      {errorText && (
                        <div className="absolute -bottom-6 pl-2 text-red-600">
                          This address has no city, please try again
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        " w-full mt-[40px] absolute " +
                        (selectedItem === 3 ? " " : " hidden ")
                      }
                    >
                      {!!addresses && (
                        <div className=" overflow-x-auto flex space-x-7 pb-2 ">
                          <div
                            className={
                              " h-full cursor-pointer rounded-xl border-2 border-[#5C0F8F]"
                            }
                          >
                            <AddressCard
                              item={addresses}
                              handleEdit={(e) => {
                                setErrorText(false);
                                setAddressProp(
                                  `${e.street}, ${e.city}, ${e.state}, ${e.country}`
                                );
                              }}
                              viewOnly
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" flex justify-end pb-3">
                    <Button
                      variant="bg-black text-white font-semibold text-lg"
                      onClick={() => {
                        if (!address) {
                          Toast("please inpuyt an address", "warning");
                        }
                        setSelectedItem(4);
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              )}
            </>

            {/* fourth one */}

            <div className="flex flex-col-reverse    sm:flex-row h-full  max-h-[30rem]  overflow-auto">
              <div className="flex flex-col sm:w-[60%]  max-h-[28rem] overflow-auto p-8">
                <div
                  className="flex justify-start space-x-2 items-center  font-semibold"
                  onClick={() => setSelectedItem(service?.isHome ? 3 : 2)}
                >
                  <Icon
                    icon="akar-icons:arrow-left"
                    width="20"
                    className=" cursor-pointer"
                  />
                </div>

                <div className=" mt-2">
                  {service?.addons.length ? (
                    <div className="font-inter text-left ">
                      <p className="font-medium">Add ons</p>
                      <div className=" max-h-[3.5rem] overflow-auto">
                        {service.addons.map(
                          (addOn: appointmentsTypeAddons, addOnIndex) => (
                            <div
                              key={addOnIndex}
                              className="flex justify-between items-center"
                            >
                              <label className="inline-flex px-1 items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="w-3 h-3  rounded-full"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedAddons([
                                        ...selectedAddons,
                                        addOn,
                                      ]);
                                    } else {
                                      setSelectedAddons(
                                        selectedAddons.filter(
                                          (item: any) => item.id !== addOn.id
                                        )
                                      );
                                    }
                                  }}
                                />

                                <span className="ml-2 text-sm">
                                  {addOn.name}{" "}
                                </span>
                              </label>
                              <span className="text-lg">
                                + {addOn.price.toLocaleString()}${" "}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className=" font-inter text-left mt-4">
                  <p className="font-medium ">Additional Notes</p>
                  <div className=" ">
                    <Input
                      type="textarea"
                      value={additionalNotes}
                      onChange={(e) => setAdditionalNotes(e.target.value)}
                      placeholder="Is there anything you would like to add?"
                    />
                  </div>
                  <div>
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                      }}
                      onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        (async () => {
                          handleBook(data);
                        })();
                      }}
                      validationSchema={schema}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="">
                            <div className="flex justify-between ">
                              <div className="w-[48%]">
                                <Field
                                  name="email"
                                  as={Input}
                                  placeholder="Client Email "
                                />

                                {errors.email && touched.email && (
                                  <InputValidationError text={errors.email} />
                                )}
                              </div>
                              <div className="w-[48%]">
                                <Field
                                  name="phoneNumber"
                                  type="number"
                                  as={Input}
                                  placeholder="Client Phone number "
                                />

                                {errors.phoneNumber && touched.phoneNumber && (
                                  <InputValidationError
                                    text={errors.phoneNumber}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="flex justify-between space-x-5">
                              <div className="w-[48%]">
                                <Field
                                  name="firstName"
                                  as={Input}
                                  placeholder="Client First Name "
                                />

                                {errors.firstName && touched.firstName && (
                                  <InputValidationError
                                    text={errors.firstName}
                                  />
                                )}
                              </div>
                              <div className="w-[48%]">
                                <Field
                                  name="lastName"
                                  as={Input}
                                  placeholder="Client Last Name "
                                />

                                {errors.lastName && touched.lastName && (
                                  <InputValidationError
                                    text={errors.lastName}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="  text-left mb-2 mt-2">
                              <p className="font-inter text-md ">
                                Describing Images (optional)
                              </p>

                              <div className="my-3">
                                <div className="flex  space-x-2 overflow-auto">
                                  <div
                                    className="flex flex-col  items-center text-white justify-center w-[5rem] h-[4.5rem] p-3 rounded-lg cursor-pointer bg-[#251D2A]"
                                    onClick={() => {
                                      setGalleryModal(true);
                                      // inputFileRef.current.click();
                                    }}
                                  >
                                    <Icon
                                      icon="mdi:image-add-outline"
                                      color="white"
                                      width={35}
                                    />
                                  </div>
                                  {!!appointmentImages.length &&
                                    appointmentImages.map(
                                      (
                                        selected: string,
                                        selectedIndex: number
                                      ) => (
                                        <div
                                          key={selectedIndex}
                                          className="flex items-center  justify-center   "
                                        >
                                          <div className="relative   w-[5rem] h-[4.5rem]  ">
                                            <>
                                              <img
                                                src={selected}
                                                alt=""
                                                style={{
                                                  border: selected
                                                    ? " "
                                                    : "1px solid black",
                                                }}
                                                className="object-cover  rounded-xl overflow-hidden"
                                              />
                                            </>
                                          </div>
                                        </div>
                                      )
                                    )}

                                  <input
                                    type="file"
                                    name="image"
                                    multiple
                                    hidden
                                    ref={inputFileRef}
                                    onChange={(event) => {
                                      submitFileHere(event);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" flex justify-center space-x-5 ">
                              <Button
                                variant={
                                  "bg-black text-white uppercase whitespace-nowrap"
                                }
                                type="submit"
                                isSubmitting={isStacking}
                              >
                                Book Appointment
                              </Button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="bg-black sm:w-[40%] p-8 text-white">
                <div className="flex justify-end ">
                  <div className="cursor-pointer" onClick={closeThisModal}>
                    <Icon icon="bx:bx-x" width="30" />
                  </div>
                </div>
                <div>
                  <p className="text-lg  text-left font-semibold mb-4">
                    Appointment Details
                  </p>
                  <div>
                    <p className="text-xs  text-left leading-7">Date</p>
                    <p className="text-sm  text-left  font-semibold">
                      {newdate}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs  text-left leading-7">Time</p>
                    <p className="text-sm  text-left  font-semibold">
                      {activeTime}
                    </p>
                  </div>
                </div>
                <div className="mt-5 text-left">
                  <p className="text-lg font-semibold">Service Details</p>
                  <div className="flex justify-between font-semibold mt-2">
                    <p className="text-2xl">{service?.name}</p>
                    <p className="text-2xl">
                      ${service?.price.toLocaleString()}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-sm ">{service?.description}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* fifth one */}
            <div className="flex flex-col-reverse  sm:flex-row h-full  overflow-auto">
              <div className="flex flex-col sm:w-[60%] h-full items-center justify-center p-8">
                <div className="flex flex-col justify-center items-center space-y-4">
                  <div>
                    <Icon
                      icon="feather:check-circle"
                      width="130"
                      className="text-green-600"
                    />
                  </div>

                  <div className="text-lg font-medium">
                    &quot;{service?.name}&quot; Appointment has been booked
                    succesfully
                  </div>
                  <div className="mt-5 flex justify-end space-x-5">
                    <Button
                      variant="bg-black text-white whitespace-nowrap"
                      onClick={() => {
                        closeThisModal();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              <div className="bg-black sm:w-[40%] p-8 text-white">
                <div className="flex justify-end ">
                  <div className="cursor-pointer" onClick={closeThisModal}>
                    <Icon icon="bx:bx-x" width="30" />
                  </div>
                </div>
                <div>
                  <p className="text-lg  text-left font-semibold mb-4">
                    Appointment Details
                  </p>
                  <div>
                    <p className="text-xs  text-left leading-7">Date</p>
                    <p className="text-sm  text-left  font-semibold">
                      {newdate}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs  text-left leading-7">Time</p>
                    <p className="text-sm  text-left  font-semibold">
                      {" "}
                      {activeTime}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs  text-left leading-7">Client</p>
                    <p className="text-sm  text-left  font-semibold">
                      {" "}
                      {customer?.firstName} {customer?.lastName}
                    </p>
                    <p className="text-left">{customer?.email}</p>
                  </div>
                </div>
                <div className="mt-5 text-left">
                  <p className="text-lg font-semibold">Service Details</p>
                  <div className="flex justify-between font-semibold mt-2">
                    <p className="text-2xl">{service?.name}</p>
                    <p className="text-2xl">
                      ${service?.price.toLocaleString()}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-sm ">{service?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <ModalContainer
        modalOpen={galleryModal}
        closeModal={() => {
          setGalleryModal(false);
          setopenCamera(false);
        }}
        size="large"
      >
        <div
          className={
            openCamera
              ? " min-h-[22rem] md:min-h-[42rem] flex flex-col justify-between "
              : " "
          }
        >
          {openCamera ? (
            <>
              {imgSrc ? (
                <div className="relative w-full h-[17rem] md:h-[38rem] ">
                  <img alt="img" src={imgSrc} />
                </div>
              ) : (
                <div className="">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    width={1280}
                    screenshotFormat="image/jpeg"
                  />
                </div>
              )}
              {imgSrc ? (
                <div className="flex justify-between md:w-1/2 space-x-5 mx-auto py-3">
                  <Button
                    onClick={async () => {
                      try {
                        if (!loggedInUser.createdAt) {
                          setAppointmentImages([...appointmentImages, imgSrc]);
                          setopenCamera(false);
                          setImgSrc("");
                          setGalleryModal(false);
                          return;
                        }
                        setisSubmitting(true);
                        const file = await srcToFile(
                          imgSrc,
                          `image1${Math.floor(Math.random() * 1000)}`,
                          "image/png"
                        );
                        const imageUrl = await submitFile(file);
                        setAppointmentImages([...appointmentImages, imageUrl]);
                        setopenCamera(false);
                        setImgSrc("");
                        setGalleryModal(false);
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setisSubmitting(false);
                      }
                    }}
                    isSubmitting={issubmitting}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => setImgSrc("")}>Retake</Button>
                  <Button
                    onClick={() => {
                      setopenCamera(false);
                      setImgSrc("");
                    }}
                    variant=" bg-red-600 text-white font-bold "
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div className="flex mt-auto justify-between md:w-1/2 space-x-5 mx-auto py-3">
                  <Button onClick={capture}>Capture photo</Button>
                  <Button
                    onClick={() => {
                      setopenCamera(false);
                      setImgSrc("");
                    }}
                    variant=" bg-red-600 text-white font-bold "
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="p-5 flex justify-between w-[20rem] mx-auto">
              <div
                className="cursor flex flex-col items-center  cursor-pointer"
                onClick={() => {
                  inputFileRef.current.click();
                }}
              >
                <div>
                  <Icon
                    icon="material-symbols:file-copy-outline-sharp"
                    width={45}
                  />
                </div>
                <div className="w-[9rem] flex justify-center ">
                  {submittingFile ? (
                    <div className="mt-3  ">
                      <Spinner />
                    </div>
                  ) : (
                    <span className="text-sm mt-2  ">Upload From Device</span>
                  )}
                </div>
              </div>{" "}
              <div
                className="cursor flex flex-col items-center  cursor-pointer"
                onClick={() => {
                  setopenCamera(true);
                }}
              >
                <Icon
                  icon="material-symbols:camera-enhance-outline-sharp"
                  width={45}
                />
                <span className="text-sm mt-2">Use Camera</span>
              </div>{" "}
            </div>
          )}
        </div>
      </ModalContainer>
    </ModalContainer>
  );
}
