import { Icon } from "@iconify/react";
import { useState } from "react";
import { IaddressAttributes } from "../interfaces/addappointment";
import Spinner from "./Base/Spinner";
import ModalContainer from "./Modals/ModalContainer";
interface Props {
  item: IaddressAttributes;
  handleEdit: (e: IaddressAttributes) => void;
  viewOnly?: boolean;
}
export default function AddressCard({ item, handleEdit, viewOnly }: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorText, setErrorText] = useState("");

  return (
    <>
      <div
        className={
          " flex flex-col items-between rounded-xl overflow-hidden " +
          (viewOnly ? "   " : " border-slate-300  border ")
        }
      >
        <div className="mb-3">
          <div className="flex justify-between px-4 pt-2 ">
            <span className="text-xl font-bold">Client Address</span>
          </div>
          <div className="flex justify-between  px-4">
            <span
              className={
                "text-xl font-semibold  break-all  " +
                (viewOnly ? " truncate " : "  ")
              }
            >
              {item.state}, {item.country}
            </span>
            {!viewOnly && (
              <div className=" flex items-end ">
                <span
                  className="  text-[10px] cursor-pointer font-semibold"
                  onClick={() => handleEdit(item)}
                >
                  Edit Address
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="bg-[#F0F0F0] mt-auto dark:bg-black p-3  flex items-center justify-between">
          <span className="text-sm  break-all truncate">{item.street}</span>

          {!viewOnly && (
            <span
              className="text-red-800 cursor-pointer ml-2"
              onClick={() => setShowDeleteModal(true)}
            >
              <Icon icon="mdi:selection-ellipse-remove" width={23} />
            </span>
          )}
        </div>
      </div>
      <ModalContainer
        modalOpen={showDeleteModal}
        closeModal={() => {
          setErrorText("");
          setShowDeleteModal(false);
        }}
      >
        <div className=" ">
          <div className="py-10 px-20 text-center ">
            {errorText && <p className="text-red-700 text-sm">{errorText}</p>}
            <p className=" font-semibold ">
              Are you sure you want to delete this address
            </p>
          </div>

          <div className="flex w-full">
            <div
              className="bg-black text-white w-1/2 flex items-center justify-center font-semibold cursor-pointer"
              onClick={() => {
                setShowDeleteModal(false);
                setErrorText("");
              }}
            >
              NO
            </div>
            <div
              className="bg-red-800 text-white w-1/2 flex items-center justify-center font-semibold p-3  cursor-pointer"
              onClick={async () => {
                try {
                  setIsDeleting(true);
                  setShowDeleteModal(false);
                } catch (e: any) {
                  console.error(e);
                  setErrorText(e.response?.data?.message);
                } finally {
                  setIsDeleting(false);
                }
              }}
            >
              {isDeleting ? <Spinner /> : "Yes"}
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
