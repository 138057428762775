/* eslint-disable @typescript-eslint/no-unused-vars */
// import axios from "axios";
import { useEffect, useState } from "react";

import { PageHeader } from "../../components/Base/PageHeader";
import { Link } from "react-router-dom";
import { Avatar } from "flowbite-react";
import axios, { AxiosResponse } from "axios";
import {
  LoyalCustomerI,
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Spinner from "../../components/Base/Spinner";
import moment from "moment";
import EmptyState from "../../components/EmptyState";

const LoyaltyProgram = () => {
  const dispatch = useAppDispatch();
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);

  const [loyalCustomers, setLoyalCustomers] = useState<LoyalCustomerI[]>([]);
  const [loading, setIsloading] = useState(false);

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getProgramPoints = async () => {
    try {
      // !loyaltyPoints && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`);
      dispatch(SET_LOYALTY_POINT(data));
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error(e);
    }
  };

  const getProgramCustomer = async () => {
    try {
      !loyalCustomers.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get<
        unknown,
        AxiosResponse<{
          data: {
            result: LoyalCustomerI[];
          };
          message: string;
          status: number;
        }>
      >(`/api/point`);
      setLoyalCustomers(data.result);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    getProgramPoints();
    getProgramCustomer();
  }, []);

  console.log(loyaltyPoints);

  // Helper to calculate rotation degree for circular progress
  const progressPercentage = Math.min((loyaltyPoints / 1000) * 100, 100);

  return (
    <div className="h-screen w-full">
      <div className="h-full overflow-y-scroll flex flex-col gap-14">
        <PageHeader text="Varroe Points " />
        <div className="w-full flex ml-50 justify-center ">
          <div className="flex items-center justify-between space-x-20">
            {/* Square Background with Circular Progress */}
            <div
              className="relative w-[400px] h-[400px] bg-gray-200 rounded-2xl flex items-center justify-center "
              style={{
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)", // Custom darker shadow
              }}
            >
              <div className="relative w-[250px] h-[250px]">
                <div className="absolute top-0 left-0 w-full h-full rounded-full border-[10px] border-gray-300"></div>
                <div
                  className="absolute top-0 left-0 w-full h-full rounded-full border-[10px] border-green-500"
                  style={{
                    clipPath: `polygon(0 0, ${progressPercentage}% 0, ${progressPercentage}% 100%, 0 100%)`,
                  }}
                ></div>
                <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
                  {loyaltyPoints} Vp
                </div>
              </div>
            </div>

            {/* Redemption Points Text */}
            {loyaltyPoints < 1000 && (
              <div className="text-center">
                <p className="text-gray-600 font-bold text-lg">
                  {1000 - loyaltyPoints} points till you can redeem
                </p>
              </div>
            )}

            {/* Conditional Redeem Button */}
            {loyaltyPoints >= 1000 && (
              <Link to="/dashboard/loyalty-program/how-to-redeem">
                <button
                  className="rounded-md border-2 border-black text-black px-10 py-3 font-semibold hover:bg-gray-200 active:bg-gray-300"
                  disabled={loyaltyPoints < 1000}
                >
                  REDEEM
                </button>
              </Link>
            )}
          </div>
        </div>

        {/* Customer List Table */}
        <table className="mt-10 table-auto border-separate border-spacing-y-3 mb-32">
          <thead className="text-gray-600">
            <tr>
              <th>
                <div className="flex justify-start">Name</div>
              </th>
              <th>Last Visited</th>
              <th>Average Spend</th>
              <th>Appointments</th>
              <th>Varroe Points</th>
            </tr>
          </thead>
          <tbody>
            {loyalCustomers.length
              ? loyalCustomers.map((loyalCustomer, index) => {
                  if (!loyalCustomer || !Object.keys(loyalCustomer).length) {
                    return null;
                  }
                  const {
                    customer,
                    lastVisit,
                    averageSpend,
                    appointmentCount,
                    pointsTotal,
                  } = loyalCustomer;
                  const fullName = customer.firstName
                    ? customer.fullName
                    : "Anonymous Customer";
                  return (
                    <tr key={index}>
                      <td>
                        <div className="flex gap-4 justify-start items-center">
                          <Avatar rounded placeholder={fullName} size={"sm"} />
                          <span>{fullName}</span>
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {lastVisit ? moment(lastVisit).format("DDMMYY") : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {USDollar.format(Number(averageSpend)) || 0}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {appointmentCount || "-"}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {pointsTotal || "-"}
                        </div>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!loading && !loyalCustomers.length && (
          <div className="w-full">
            <EmptyState fetching={loading} text="No Loyal Customers :(" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoyaltyProgram;
