import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { Select } from "../Base/Select";
import ScheduleDate from "../ScheduleDate";
import moment from "moment";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: any;
  title?: string;
  image?: string;
  modalOpen: boolean;
  size?: "large" | "small";
  content?: any;
  refresh: any;
}
const EditStaffScheduleModal = (props: Props) => {
  const [selectedDate, setSelectedDate] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [timeSlotInterval, setTimeSlotInterval] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(
    `${timeSlotInterval}`
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const dropdown = Array.from(Array(5), (_, x) => x * timeSlotInterval).slice(
    1
  );

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const splitText = (text: string) => {
    return text.substring(0, text.indexOf("-"));
  };
  const updateSchedule = async () => {
    setIsSubmitting(true);
    try {
      const time = moment.utc(selectedTime, "HH:mm");
      await axios.patch(`/api/staff/schedule/staff/${props.content.id}`, {
        start: time,
        duration: Number(selectedDuration),
        dates: selectedDate,
      });
      props.refresh(true);
      props.closeModal(true);
      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const getValue = (value: string) => {
    if (!selectedDate.includes(value)) {
      setSelectedDate([...selectedDate, value]);
    } else if (selectedDate.includes(value)) {
      setSelectedDate(selectedDate.filter((item) => item !== value));
    }
  };

  const getDurationInterval = async () => {
    const { data } = await axios.get("/api/business/parameter/customisation");

    setTimeSlotInterval(data.data.value.timeSlotInterval);
  };

  useEffect(() => {
    getDurationInterval();
    if (props.content.schedule) {
      setSelectedDuration(props.content.schedule.duration);
      setSelectedTime(splitText(props.content.schedule.breakPeriod).trim());
      setSelectedDate(props.content.schedule.dates);
    }
  }, []);
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="small"
    >
      <div className="flex justify-center mt-3 sm:mt-0 sm:text-left">
        <div className="w-full">
          <div className="p-2 sm:p-3 md:p-5 bg-[#f1f1f1] rounded-lg mr-3 sm:mr-0 sm:h-2/5 lg:h-32 flex items-center justify-center sm:justify-start">
            <div className="w-full my-2 mr-5 md:mr-0 sm:w-auto">
              <p className="text-sm font-semibold capitalize break-words md:text-xl">
                {props.content.firstName} {props.content.lastName}
              </p>

              <p className="mt-3 text-xs break-words sm:text-sm">
                {/* rachelgreen@gmail.com */}
              </p>
              <p className="my-3 text-xs break-words sm:text-sm">
                {props.content.phone}
              </p>
            </div>
          </div>

          {props.content.schedule ? (
            <>
              <div className="my-8 space-x-4">
                {days.map((day: string, index: number) => (
                  <ScheduleDate
                    color={`${
                      props.content.schedule
                        ? props.content.schedule.dates.map((date: string) =>
                            date === day ? ` bg-maingreen ` : null
                          )
                        : "bg-danger"
                    } bg-danger`}
                    getValue={(value: string) => getValue(value)}
                    text={day}
                    key={index}
                  />
                ))}
              </div>
              <div className="flex justify-between w-7/12 space-x-3">
                <Input
                  label="Break start time"
                  type="time"
                  value={
                    selectedTime ||
                    splitText(props.content.schedule.breakPeriod).trim()
                  }
                  onChange={(e) => setSelectedTime(e.target.value)}
                />

                <div className="w-full">
                  <Select
                    options={dropdown}
                    label="Break duration (minutes)"
                    value={selectedDuration || props.content.schedule.duration}
                    onChange={(e) => setSelectedDuration(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="my-8 ">
              <div className="space-x-4">
                {days.map((day, index) => (
                  <ScheduleDate
                    key={index}
                    color="bg-danger"
                    text={day}
                    getValue={(value: string) => getValue(value)}
                  />
                ))}
              </div>

              <div className="flex justify-between w-7/12 mt-8 space-x-3">
                <Input
                  label="Break start time"
                  type="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                />

                <div className="w-full">
                  <Select
                    options={dropdown}
                    label="Break duration(minutes)"
                    value={selectedDuration}
                    onChange={(e) => setSelectedDuration(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end mt-5">
            <Button onClick={updateSchedule} isSubmitting={isSubmitting}>
              Update
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditStaffScheduleModal;
