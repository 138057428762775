import axios from "axios";
import { Toast } from "../components/Base/toast";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// Request interceptor
axios.interceptors.request.use(
  function (config: any) {
    const token = localStorage.getItem("merchant-api-token");
    if (!token) return;
    const busineesId = localStorage.getItem("varroe_business_id");
    // Get token and assign it to headers here
    if (token != null) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    if (busineesId != null) {
      config.headers.common["x-business-id"] = `${busineesId}`;
    }
    // show loader here
    return config;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // show loader here
    // show error toast
    return response;
  },
  async function (error: any) {
    const ignore = ["Route not found"];

    // if (error.response.status === 500) {
    //   Toast("Something went wrong", "error");
    // }
    if (error.response?.data.errors != null) {
      error.response?.data.errors.forEach((failed: { message: string }) => {
        Toast(failed.message, "error"); // will pass in error message from api here
      });
    } else if (error.response?.data.message) {
      const message = error.response?.data.message;
      if (process.env.NODE_ENV !== "development" && ignore.includes(message)) {
        // those error messages will only show up on development
        return;
      } else {
        Toast(message, "error"); // will pass in error message from api here
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // show error message in toast here
    return await Promise.reject(error);
  }
);
