import React from "react";
import ReactDOM from "react-dom";

interface Props {
  children: React.ReactNode;
  modalOpen: true | false;
  closeModal: any;
  size?: "large" | "small" | "long" | "service" | "" | "crm";
  radius?: string;
  noPadding?: boolean;
  crm?: boolean;
}

const ModalContainer = (props: Props) => {
  return ReactDOM.createPortal(
    <>
      {!props.modalOpen ? (
        ""
      ) : (
        <div
          className={
            "fixed font-sans transition-all duration-300 ease-in-out left-4  z-[999] top-0 " +
            (props.crm ? " sm:left-[14rem] " : " sm:-left-2 ")
          }
        >
          <div
            className={
              "fixed top-0 z-20 w-screen h-screen overflow-hidden transition-opacity  " +
              (props.crm
                ? " left-[16rem]  "
                : " bg-opacity-40 bg-black left-0  ")
            }
            style={
              props.crm
                ? {
                    background: "rgba(255, 255, 255, 0.37)",
                    backdropFilter: "blur(1.5px)",
                  }
                : {}
            }
            onClick={() => {
              props.closeModal();
            }}
          ></div>
          <div
            className={
              "flex items-center content-center w-screen h-screen " +
              (props.crm
                ? " sm:justify-center pl-[15rem]  "
                : " sm:justify-center ")
            }
          >
            <div
              className={`relative z-50 w-11/12 border  ${
                props.size === "large"
                  ? "sm:w-9/12 lg:w-[816px]"
                  : props.size === "small"
                  ? "md:w-[516px] h-auto"
                  : props.size === "long"
                  ? "h-[95%] md:w-5/12"
                  : props.size === "service"
                  ? "h-[98%] md:w-[38%]"
                  : props.size === "crm"
                  ? "h-[95%] md:w-[60%]"
                  : "md:w-[716px] h-auto"
              } ${props.noPadding ? " " : " p-8 "} bg-white ${
                props.radius ? props.radius : " rounded-2xl "
              }  ${
                props.crm ? " border-[#CCCCCC] overflow-y-hidden " : " "
              } overflow-x-hidden `}
            >
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>,
    document.body
  );
};

export default ModalContainer;
