import { Button } from "../Base/Button";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: () => void;
  image: string;
  modalOpen: boolean;
  actionHandler: () => void;
  isSubmitting: boolean;
  refresh?: () => void;
}
const MakeDefaultModal = (props: Props) => {
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size={"small"}
    >
      <div className="mt-3 sm:text-left">
        <div className="flex flex-col-reverse justify-between sm:flex-row">
          <div className="flex flex-col justify-between sm:w-4/6">
            <div className="flex items-center sm:mr-0 sm:justify-start">
              <div className="w-full mr-5 space-y-5 md:mr-0 sm:w-auto">
                <p className="text-sm sm:text-[14px] break-words text-left">
                  Are you sure you want to make this image default profile
                  header?
                </p>
              </div>
            </div>
          </div>

          <div className="flex w-2/5 mb-10 h-36 sm:mb-0 sm:justify-start rounded-xl">
            <img
              src={props.image}
              alt="staff"
              className="object-cover w-full rounded-xl"
            />
          </div>
        </div>

        <div
          className={`flex items-end "md:justify-start"
          }`}
        >
          <Button
            onClick={() => props.actionHandler()}
            isSubmitting={props.isSubmitting}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? `Updating Photo` : "Yes"}
          </Button>
          <Button
            variant="bg-danger ml-4"
            onClick={() => {
              props.closeModal();
            }}
          >
            No
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default MakeDefaultModal;
