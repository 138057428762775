/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Input } from "../Base/Input";
import { Select } from "../Base/Select";
import FileUpload from "../FileUpload";
import { Button } from "../Base/Button";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { InputValidationError } from "../../components/InputValidationError";
import {
  businessCategory,
  CountryAttributes,
  ProvinceAttributes,
} from "../../interfaces/business";
import useFileUpload from "../../hooks/useFileUpload";
import { RadioButton } from "../Base/RadioButton";
import { BusinessTypeContext } from "../../context/businessType";
import { Textarea } from "../Base/Textarea";

const BusinessIdentification = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [categories, setCategories] = useState<businessCategory[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allCountries, setAllCountries] = useState<
    Array<{ text: string; value: number | string }>
  >([]);
  const [selectedCountry, setSelectedCountry] = useState(1);
  const [selectedProvince, setSelectedProvince] = useState(1);
  const [allProvinces, setAllProvinces] = useState<
    Array<{ text: string; value: number | string }>
  >([]);

  const [defaultCategory, setDefaultCategory] = useState(1);
  const [attachedlogo, setAttachedlogo] = useState("");

  const [radioValue, setRadioValue] = useState("merchant");
  const [isLoading, setIsloading] = useState(false);
  const { submitFile } = useFileUpload();

  const pushFilesToArray = async (file: any) => {
    setSelectedFile([...selectedFile, file]);
    try {
      setIsloading(true);
      const imageUrl = await submitFile(file, "logo");
      if (imageUrl) setAttachedlogo(imageUrl);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getProvince();
  }, [selectedCountry]);
  const getProvince = async () => {
    try {
      setSelectedProvince(0);
      setAllProvinces([]);
      const {
        data: { data },
      } = await axios.get(`/api/address/state/${selectedCountry}`);
      setAllProvinces(
        data.map((country: CountryAttributes) => ({
          text: country.name,
          value: country.id,
        }))
      );
      setSelectedProvince(data[0].id);
    } catch (e) {
      console.error(e);
    }
  };
  const getMeta = async () => {
    try {
      const endpoints = [`/api/business/meta`, `/api/address/country`];
      const [meta, countries] = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint))
      );
      if (meta.data) {
        setCategories(meta.data.data.categories);
        setDefaultCategory(meta.data.data.categories[0].id);
      }
      if (countries.data) {
        setAllCountries(
          countries.data.data.map((country: CountryAttributes) => ({
            text: country.name,
            value: country.id,
          }))
        );
        setSelectedCountry(countries.data.data[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postBusiness = async (form: {
    businessName: string;
    businessEmail: string;
    businessCategory: string | number;
    street: string;
    province: string | number;
    city: string;
    country: string | number;
    logo: string;
    businessType: string;
    description: string;
  }) => {
    try {
      setIsloading(true);
      const capitalizeName =
        form.businessName.charAt(0).toUpperCase() + form.businessName.slice(1);
      const {
        data: { data },
      } = await axios.post(`/api/business?businessType=${radioValue}`, {
        name: capitalizeName,
        address: {
          city: form.city,
          stateId: selectedProvince,
          street: form.street,
        },
        email: form.businessEmail,
        categoryId: form.businessCategory,
        logo: attachedlogo,
        description: form.description,
      });
      if (data.business.id) {
        localStorage.setItem("varroe_business_id", data.business.id);
      }
      if (data.access.role) {
        localStorage.setItem("varroe_business_type", data.access.role);
      }
      navigate("/auth/register/classification");
    } catch (error: any) {
      console.error("error", error.response.data.errors);
      // console.error(error);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    getMeta();
  }, []);
  const schema = yup.object().shape({
    businessName: yup.string().required("Please enter your business name"),
    businessEmail: yup
      .string()
      .required("Please enter your business email")
      .email("Please enter a valid email address"),
    province: yup.string().required("Please enter your province"),
    city: yup.string().required("Please enter your city"),
    country: yup.string().required("Please enter your country"),
    businessCategory: yup.string().required("Please enter a category"),
    street: yup.string().required("Please enter your street"),
    businessType: yup.string().required("Please enter the business type"),
    description: yup.string(),
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { businessType, setBusinessType } = useContext(BusinessTypeContext);

  const getCheckedValue = (value: string) => {
    if (setBusinessType) setBusinessType(value);
    setRadioValue(value);
  };
  return (
    <div className="relative">
      <div className="overflow-scroll font-sans ">
        <div className="absolute -left-2 sm:-left-3 -top-20">
          <h2 className="font-bold text-white uppercase ">
            welcome to varroe!
          </h2>
          <p className="pt-2 text-xs font-semibold text-white">
            Tell us a bit about your business to get started.
          </p>
        </div>

        <div className="flex justify-center pt-4">
          <div>
            <h1 className="text-xs text-center text-black">
              What would you describe yourself as?
            </h1>
          </div>
        </div>
        <Formik
          initialValues={{
            businessName: "",
            businessEmail: "",
            businessCategory: defaultCategory,
            street: "",
            province: selectedProvince,
            city: "",
            country: selectedCountry,
            logo: "",
            businessType: "merchant",
            description: "",
          }}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            (async () => {
              await postBusiness(data);
            })();
            setSubmitting(false);
          }}
          validationSchema={schema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="px-5 pt-3 pb-5 sm:space-y-5 font-roboto">
              <div className="flex justify-center">
                <div className="space-x-3 businesstypeinput">
                  <Field
                    name="businessType"
                    type="radio"
                    as={RadioButton}
                    label="MERCHANT"
                    value="merchant"
                    id="merchant"
                    defaultChecked
                    onChange={(e: { target: HTMLInputElement }) =>
                      getCheckedValue(e.target.value)
                    }
                  />
                  <Field
                    name="businessType"
                    type="radio"
                    value="freelancer"
                    as={RadioButton}
                    label="FREELANCER"
                    id="freelancer"
                    onChange={(e: { target: HTMLInputElement }) =>
                      getCheckedValue(e.target.value)
                    }
                  />

                  {errors.businessType && touched.businessType ? (
                    <span className="flex justify-center text-center">
                      <InputValidationError text={errors.businessType} />{" "}
                    </span>
                  ) : null}
                </div>
              </div>
              <div>
                <Field
                  name="businessName"
                  type="input"
                  as={Input}
                  label="What is the name of your business?"
                  placeholder="e.g Varroe"
                />

                {errors.businessName && touched.businessName ? (
                  <InputValidationError text={errors.businessName} />
                ) : null}
              </div>
              <div>
                <Field
                  name="businessEmail"
                  type="email"
                  as={Input}
                  label="What email address can people reach your business at?"
                  placeholder="e.g business@varroe.com"
                />

                {errors.businessEmail && touched.businessEmail ? (
                  <InputValidationError text={errors.businessEmail} />
                ) : null}
              </div>
              <div>
                <Field
                  name="description"
                  type="text"
                  as={Textarea}
                  rows={4}
                  label="What does your business do?"
                  placeholder="Tell us what your business is all about"
                />

                {errors.description && touched.description ? (
                  <InputValidationError text={errors.description} />
                ) : null}
              </div>
              <div className="flex flex-col justify-between mb-10 md:space-x-3 sm:flex-row sm:flex-nowrap">
                <div className="w-full py-2 sm:w-3/6 md:w-4/6 sm:py-0">
                  <div className="flex flex-col">
                    <label className="mb-2 text-[10px] text-left">
                      Select a category that best describes your business.
                    </label>
                    <div className="">
                      <Field
                        as="select"
                        name="businessCategory"
                        className={`w-full cursor-pointer appearance-none text-sm py-2 px-3 rounded-md text-black h-auto border border-solid border-darkergrey bg-lightergrey focus:outline-0  focus:border-blue-300`}
                      >
                        {categories.map((value, index) => (
                          <option value={value.id} key={index}>
                            {value.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  {errors.businessCategory && touched.businessCategory ? (
                    <InputValidationError text={errors.businessCategory} />
                  ) : null}
                </div>

                <div className="">
                  <div className="block h-14">
                    <Field
                      name="logo"
                      text="Logo"
                      as={FileUpload}
                      label="Logo"
                      pushFilesToArray={pushFilesToArray}
                      accept="image/*"
                    />
                  </div>

                  {errors.logo && !selectedFile.length ? (
                    <InputValidationError text={errors.logo} />
                  ) : null}
                </div>
              </div>
              <p className="text-[10px] font-semibold">
                Where is your business located?
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="w-full">
                  <Field
                    name="country"
                    options={allCountries}
                    as={Select}
                    label="Country"
                    value={selectedCountry}
                    onChange={(e: any) => {
                      setSelectedCountry(e.target.value);
                    }}
                  />

                  {errors.country && touched.country ? (
                    <InputValidationError text={errors.country} />
                  ) : null}
                </div>
                <div className="w-full">
                  <Field
                    name="province"
                    options={allProvinces}
                    as={Select}
                    label="Province"
                    value={selectedProvince}
                    onChange={(e: any) => {
                      setSelectedProvince(e.target.value);
                    }}
                  />

                  {errors.province && touched.province ? (
                    <InputValidationError text={errors.province} />
                  ) : null}
                </div>
                <div className="w-full">
                  <Field
                    name="city"
                    type="text"
                    as={Input}
                    label="City"
                    placeholder="Winnipeg"
                  />

                  {errors.street && touched.street ? (
                    <InputValidationError text={errors.street} />
                  ) : null}
                </div>

                <div className="w-full">
                  <Field
                    name="street"
                    type="text"
                    as={Input}
                    label="Street"
                    placeholder="114 Buildover close"
                  />

                  {errors.street && touched.street ? (
                    <InputValidationError text={errors.street} />
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end">
                <Button
                  variant={isLoading ? "bg-main" : "bg-primary"}
                  type="submit"
                  disabled={isLoading}
                  isSubmitting={isSubmitting}
                >
                  {isLoading ? "Loading..." : "PROCEED"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessIdentification;
