import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import Dropdown from "./Dropdown";
import FullChart from "./Graphs/FullChart";
import OverlaySpinner from "./OverlaySpinner";

const RevenueGrowth = () => {
  const {
    dropDownOptions,
    dropDownText,
    duration,
    fetch,
    getValue,
    isloading,
    getData,
    noData,
    setNoData,
  } = useAnalyticsHook();
  const [bookingTimes, setBookingTimes] = useState<any>([]);

  const [wholeData, setWholeData] = useState<any>({
    duration: { start: "", end: "" },
  });
  const getRevenue = async () => {
    // const data = await getData(`/api/analytics/revenue/?interval=${duration}`)

    const data = await getData(`/api/analytics/peaktimes?interval=${duration}`);
    if (!data.error) {
      const bookingTimes = data.peaktimes;
      setBookingTimes(bookingTimes);
      setWholeData(data);
      const empty = data.peaktimes.every((el: any) => el.yaxis === 0);
      setNoData(empty);
    }
  };
  useEffect(() => {
    getRevenue();
  }, [fetch]);
  return (
    <div className="flex justify-between h-auto p-6 overflow-auto bg-basegrey rounded-xl">
      <div className="w-full">
        <div className="flex flex-row justify-between space-x-8 md:mb-5 lg:space-x-0 ">
          <p className="text-lg font-semibold">Peak Booking Times</p>

          <div className="flex-1 lg:flex-none">
            <div className="flex justify-between items-center">
              <div className="mr-5">
                Showing Booking Time from {wholeData.duration.start} till{" "}
                {wholeData.duration.end}
              </div>
              <div>
                <Dropdown
                  text={dropDownText}
                  values={dropDownOptions}
                  getValue={getValue}
                />
              </div>
            </div>
          </div>
        </div>
        <OverlaySpinner isLoading={isloading} noData={noData}>
          <div className="flex-1 p-5 overflow-scroll bg-white md:p-10 xl:flex-none rounded-xl h-96">
            <div className="">
              <FullChart data={{ chart: bookingTimes }} />
            </div>
          </div>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default RevenueGrowth;
