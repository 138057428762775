/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

import { PageHeader } from "../../components/Base/PageHeader";
// import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import {
//   selectLoyaltyPoints,
//   SET_LOYALTY_POINT,
// } from "../../features/loyalty/loyalty";
import axios, { AxiosResponse } from "axios";
import { Spinner } from "flowbite-react";
import { Toast } from "../../components/Base/toast";
import { useNavigate, Link } from "react-router-dom";
import { FiPlus, FiMinus } from "react-icons/fi";

// 1000vp = $100 1
const VoucherLoyaltyProgram = () => {
  const [split, setSplit] = useState<number>(1);
  const [loading, setIsloading] = useState(false);
  const navigate = useNavigate();
  // const vp = useAppSelector(selectLoyaltyPoints);
  const totalValue = 100; // Total value of the coupon in dollars
  const valuePerCoupon = totalValue / split; // Calculate the value of each coupon

  const crementSplit = (increase: boolean) => {
    setSplit(split + (increase ? 1 : -1));
  };

  const createCoupon = async () => {
    try {
      setIsloading(true);
      const { data } = await axios.post<
        unknown,
        AxiosResponse<{
          status: number;
          message: string;
          data: string;
        }>
      >("/api/point/redeem/coupon", {
        split,
      });
      const couponCode = data?.data || "";
      Toast(`Coupon created successfully`, "success");
      navigate(`preview?couponCode=${couponCode}&split=${split}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (
            error.response as AxiosResponse<{
              status: string;
              data?: unknown;
              message?: string;
            }>
          )?.data?.message ?? error.message;
        Toast(message, "error");
      } else {
        Toast(
          "Something went wrong, please try again later or contact admin",
          "warning"
        );
      }
    }
    setIsloading(false);
  };
  return (
    <div className="h-screen w-full flex flex-col">
      <div className="px-6 py-4">
        {/* Flex container to align header text and arrow in one line */}
        <div className="flex items-center">
          {/* Back Arrow */}
          <span
            className="text-2xl font-bold cursor-pointer mr-2"
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            ⬅ {/* Thicker left arrow character */}
          </span>

          {/* Header Text */}
          <PageHeader text="Redeem as Coupon" />
        </div>
        <div className="px-6 flex flex-col gap-4">
          {/* Coupon Split Box */}
          <div className="w-full font-bold text-lg">Coupon Split</div>
          <div className="flex text-2xl font-bold">$ {totalValue}</div>
        </div>

        <div className="flex items-center justify-start gap-4 p-2 bg-gray-100 rounded-lg shadow-md mt-4 w-fit">
          <button
            className="bg-red-400 w-12 h-12 flex justify-center items-center rounded-full shadow-md"
            onClick={() => crementSplit(false)}
            disabled={split <= 1}
          >
            <FiMinus className="text-white" />
          </button>
          <span className="text-xl font-bold">{split}</span>
          <button
            className="bg-green-400 w-12 h-12 flex justify-center items-center rounded-full shadow-md"
            onClick={() => crementSplit(true)}
          >
            <FiPlus className="text-white" />
          </button>
        </div>

        <div className="flex mt-4">
          <button
            onClick={createCoupon}
            className="rounded-md bg-[#001C43] text-white px-6 py-3 font-bold"
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <span>Add Customers</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoucherLoyaltyProgram;
