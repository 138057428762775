import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useState } from "react";
import { selectBusiness } from "../features/business/business";
import { changeClientSecret } from "../features/payments/payments";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SubscriptionCheckout from "../views/SubscriptionCheckout";
import { Button } from "./Base/Button";
import { Input } from "./Base/Input";
import { Toast } from "./Base/toast";
import ModalContainer from "./Modals/ModalContainer";
interface checkType {
  check: boolean;
  text?: string;
}
interface Props {
  plan: {
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
    fee: any;
    resources: any[];
    id: number;
  };
  check?: checkType[];
  showFee?: boolean;
  showCardModal?: any;
  StaffCount?: number;
  period?: "yearly" | "monthly";
}
export default function PlanHeader({
  plan,
  showCardModal,
  showFee,
  StaffCount,
  period,
}: Props) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [showError, setShowError] = useState(false);
  const handleSelect = async () => {
    try {
      setIsloading(true);
      if (plan.name === "Custom") {
        if (!customInput) return setShowError(true);
      }

      let url = `/api/subscription`;
      let payload: any = {
        key: plan.name,
        subscriptionPeriod: period === "monthly" ? "MONTHLY" : "YEARLY",
      };
      if (plan.name === "Custom") {
        url = `/api/subscription/custom`;
        payload = {
          name: customInput,
          resourceIds: plan.resources.map((resource) => resource.resource.id),
          subscriptionPeriod: period === "monthly" ? "MONTHLY" : "YEARLY",
          staffLimit: StaffCount,
        };
      }
      const {
        data: { data },
      } = await axios.post(url, payload);
      await dispatch(changeClientSecret(data.providerPaymentSecret));
      setShowPayment(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const [confirmModal, setConfirmModal] = useState(false);
  const closeModal = () => {
    setConfirmModal(false);
    setShowPayment(false);
    setCustomInput("");
  };
  const activeBusiness = useAppSelector(selectBusiness);

  return (
    <div className=" min-h-[100px] w-[150px] flex flex-col">
      <p className="text-xl mb-1 ">{plan.name}</p>
      <div>
        <span className="text-2xl font-semibold">
          $
          {period === "monthly"
            ? typeof plan.pricePerMonth === "number"
              ? plan.pricePerMonth.toFixed(2)
              : plan.pricePerMonth
            : plan.pricePerYear}
        </span>
        <span className="text-xs font-normal">
          {period === "monthly" ? " per month" : " per year"}
        </span>
      </div>
      {showFee ? (
        <p className="text-[10px] font-normal my-2">
          {plan.name === "Free"
            ? "No transactions fees "
            : `${plan.fee} % transaction fee`}
        </p>
      ) : (
        <div className="h-[35px]"></div>
      )}

      <button
        className={
          (plan.name === "Free"
            ? "bg-brownblack "
            : activeBusiness?.subscription?.plan.name === "Free"
            ? "bg-blue-600 "
            : activeBusiness.subscription?.plan.id &&
              plan.id < activeBusiness?.subscription?.plan.id
            ? " bg-brownblack "
            : "bg-blue-600 ") +
          " rounded-md p-2 hover:opacity-70 text-white flex items-center justify-center"
        }
        onClick={() => {
          if (plan.name === "Custom" && !plan.pricePerMonth)
            return Toast(
              "You need to select more resources to use this plan",
              "warning"
            );
          setConfirmModal(true);
        }}
        disabled={isLoading}
      >
        {isLoading
          ? "Loading..."
          : plan.name === "Free"
          ? "Downgrade"
          : activeBusiness?.subscription?.plan.name === "Free"
          ? "Upgrade"
          : activeBusiness.subscription?.plan.id &&
            plan.id < activeBusiness?.subscription?.plan.id
          ? "Downgrade"
          : "Upgrade"}
      </button>
      <ModalContainer modalOpen={confirmModal} closeModal={closeModal}>
        <div className="interFamily">
          <span className="font-semibold ">Confirm your subscription</span>
          <div className="my-3">
            {plan.name !== "Custom" ? (
              <div className="flex justify-between text-3xl font-bold subscriptionFont">
                <span className="">{plan.name}</span>
                <span>
                  $
                  {period === "monthly"
                    ? plan.pricePerMonth
                    : plan.pricePerYear}
                </span>
              </div>
            ) : (
              <div className="flex justify-between items-center text-3xl font-bold subscriptionFont">
                <div className="w-1/2">
                  <Input
                    label="Name your custom plan"
                    value={customInput}
                    onChange={(e) => {
                      setCustomInput(e.target.value);
                      setShowError(false);
                    }}
                    placeholder="eg Custom"
                    bottomSpace
                  />
                  {showError && (
                    <span className="text-sm font-normal text-red-600 my-0">
                      This field is required
                    </span>
                  )}
                </div>

                <span>
                  $
                  {period === "monthly"
                    ? plan.pricePerMonth
                    : plan.pricePerYear}
                </span>
              </div>
            )}
          </div>
          <div>
            Subscription to this plan will give you access to the following
            features:
          </div>
          <div className="grid-cols-2 my-4 ">
            {plan.resources &&
              plan.resources.map((resource, resourceIndex) => (
                <div key={resourceIndex} className="inline-block mr-5 p-2">
                  <div className="flex flex-row items-center">
                    <span className="text-green-500 mr-2">
                      <Icon icon="akar-icons:check" width={20} />
                    </span>
                    <span>{resource.resource?.name}</span>
                  </div>
                </div>
              ))}
          </div>
          {showPayment ? (
            <SubscriptionCheckout
              close={() => {
                closeModal();
              }}
            />
          ) : (
            <div className="flex justify-end space-x-5 mt-5">
              <Button
                onClick={() => {
                  handleSelect();
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "SUBSCRIBE"}
              </Button>
              <Button
                variant="bg-danger"
                onClick={() => {
                  closeModal();
                }}
              >
                CLOSE{" "}
              </Button>
            </div>
          )}
        </div>
      </ModalContainer>
    </div>
  );
}
