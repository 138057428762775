import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";

interface Props {
  text: string;
  values: string[] | Array<{ label: string; value: any }>;
  getValue: any;
  size?: "small" | "large";
}

const Dropdown = (props: Props) => {
  const [dropdown, setDropdown] = useState(false);

  const dropdownRef = useRef<any>();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  });
  const handleOutsideClicks = (event: any) => {
    if (
      dropdown &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  const setValue = (value: any) => {
    props.getValue(value);
    setDropdown(false);
  };

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div
          onClick={() => {
            setDropdown(!dropdown);
          }}
          className={`justify-between bg-lightergrey capitalize cursor-pointer ${
            props.size === "small"
              ? "text-[9px] px-2 py-2 w-24"
              : "text-[11px] md:text-xs px-4 py-2.5 w-28 md:w-32 "
          }  border-darkergrey border rounded-lg text-center inline-flex items-center space-x-3`}
        >
          <span>{props.text}</span>
          <span>
            <Icon icon="ant-design:caret-down-outlined" />
          </span>
        </div>

        {dropdown && (
          <div
            id="dropdown"
            className="absolute z-10 w-32 rounded shadow bg-lightergrey md:w-44"
          >
            <ul className="text-sm text-gray-700 ">
              {props.values.map((value, i) => (
                <div key={i}>
                  {typeof value === "object" ? (
                    <li
                      className="p-3 text-xs capitalize cursor-pointer hover:bg-gray-300 "
                      onClick={() => setValue(value)}
                    >
                      {value.label}
                    </li>
                  ) : (
                    <li
                      className="p-3 text-xs capitalize cursor-pointer hover:bg-gray-300 "
                      onClick={() => setValue(value)}
                    >
                      {value}
                    </li>
                  )}
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
