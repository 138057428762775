import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import Dropdown from "./Dropdown";
import PieChart from "./Graphs/PieChart";
import OverlaySpinner from "./OverlaySpinner";

const CartRetention = () => {
  const {
    dropDownOptions,
    dropDownText,
    duration,
    fetch,
    getValue,
    isloading,
    getData,
    noData,
    setNoData,
  } = useAnalyticsHook();
  const [retention, setRetention] = useState<any>({
    retention: { abandonedCount: 0, completedCount: 0 },
  });
  const getRevenue = async () => {
    const data = await getData(`/api/analytics/cart?interval=${duration}`);
    // const data = await getData(
    //   `/api/appointment/analytics/?interval=${duration}`
    // );
    if (!data.error) {
      setRetention(data.retention);
      if (
        data.retention.abandonedCount === 0 &&
        data.retention.completedCount === 0
      )
        setNoData(true);
    }
  };
  useEffect(() => {
    getRevenue();
  }, [fetch]);
  return (
    <div className="flex justify-between h-[330px] p-6 overflow-auto bg-basegrey rounded-xl">
      <div className="w-full">
        <div className="flex justify-between mb-2">
          <p className="font-semibold md:text-lg">Cart Retention</p>
          <Dropdown
            text={dropDownText}
            values={dropDownOptions}
            getValue={getValue}
          />
        </div>
        <OverlaySpinner isLoading={isloading} noData={noData}>
          <div className="flex items-center space-x-5">
            <div className="flex items-center p-4 overflow-hidden bg-white h-60 rounded-xl">
              <div className=" w-44">
                <PieChart retension={retention} />
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 border border-green-500 rounded-[50%]"></div>
                <p className="text-xs">Checked out carts</p>
              </div>
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 border border-main rounded-[50%]"></div>
                <p className="text-xs">Abandoned carts</p>
              </div>
            </div>
          </div>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default CartRetention;
