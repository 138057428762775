import { Icon } from "@iconify/react";
import Avatar from "react-avatar";
import { ICRMSingleClient } from "../../interfaces/crm";
import { Button } from "../Base/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { appointmentsType } from "../../interfaces/appointments";
import { useState } from "react";
import AppointmentModal from "../Modals/AppointmentModal";
import { useAppSelector } from "../../store/hooks";
import DeleteModal from "../Modals/DeleteModal";
import axios from "axios";
import useMeridem from "../../hooks/useMeridem";

interface Props {
  details?: ICRMSingleClient;
  refresh: () => void;
}
export default function CRMAppointments({ details, refresh }: Props) {
  const navigate = useNavigate();
  const [appointmentModalProps, setAppointmentModalProps] =
    useState<appointmentsType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState<appointmentsType>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const userRole = useAppSelector((state) => state.auth.role);
  const handleDelete = async () => {
    // if you click on yes
    try {
      await axios.patch(`/api/appointment/${deleteModalProps?.id}/handle`, {
        accepted: false,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setModalOpen(false);
      setDeleteModalOpen(false);
      refresh();
    }
  };
  const showModals = (content: any) => {
    setAppointmentModalProps(content);
    setModalOpen(true);
  };
  const { TwentyFourHourFormat } = useMeridem();

  return (
    <div className="flex space-x-5 interFamily  overflow-scroll ">
      <div className="w-[35%] ">
        <div className="flex flex-col space-y-5 border border-[#CCCCCC] rounded-lg p-5">
          {details?.customer?.avatarUrl ? (
            <div>
              <img
                src={details?.customer?.avatarUrl}
                alt="img"
                className="rounded-md h-[120px] w-full object-cover"
              />
            </div>
          ) : (
            <Avatar
              name={
                details?.customer?.firstName + " " + details?.customer?.lastName
              }
              color="#0C51C1"
              className="rounded-md w-full"
              style={{
                width: "100%",
                background: "#0C51C1",
                display: "flex",
                justifyContent: "center",
              }}
              textSizeRatio={2}
            />
          )}

          {details?.isNew && (
            <div className="bg-[#579AF5]  text-xs text-white flex items-center w-[62px] rounded-md p-1   space-x-1">
              <Icon icon="ic:sharp-star-outline" width={15} />
              <span className="uppercase font-bold pt-[0.1rem]">new</span>
            </div>
          )}

          <div className="text-sm">
            <p className="text-[27px] capitalize truncate font-semibold">
              {details?.customer.firstName + " " + details?.customer.lastName}
            </p>
            {details?.customer?.email && (
              <p className="text-gray-400 flex items-center space-x-1 mt-4">
                <Icon icon="material-symbols:alternate-email-rounded" />
                <span className="truncate">{details?.customer?.email}</span>
              </p>
            )}
            {details?.customer?.phoneNumber && (
              <p className="text-gray-400 flex items-center space-x-1 mt-1">
                <Icon icon="material-symbols:call-sharp" />
                <span className="truncate">
                  {details?.customer?.phoneNumber}
                </span>
              </p>
            )}

            <p className="text-gray-400 flex items-center space-x-1 mt-1 pl-[0.1rem]">
              <span className="truncate">
                Last visited {moment(details?.lastVisit).format("MMM D, YYYY")}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-16  px-4  flex flex-col justify-center">
          <p className="text-[11px] font-medium text-center mb-4">
            Upgrade your plan to gain access to filters & more aggregate data
          </p>
          <div className="flex justify-center">
            <Button
              onClick={() => {
                navigate("/dashboard/businessinfo/subscription");
              }}
            >
              <span className="text-[10px] text-center">
                Upgrade to Intermediate
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div className="w-[63%] flex flex-col overflow-hidden h-screen ">
        <p className="font-semibold text-[18px] mb-5">All Appointments</p>

        <div className="flex flex-col space-y-4 !overflow-scroll h-full">
          {details?.appointments.map((text: appointmentsType, index) => (
            <div
              key={index}
              className="border border-[#CCCCCC] py-[10.5px] px-3 rounded-lg flex justify-between  hover:bg-main hover:text-white cursor-pointer"
              onClick={() => {
                showModals({
                  ...text,
                  customer: details.customer,
                });
              }}
            >
              <div className="flex items-center space-x-2">
                <Icon icon="ic:outline-local-offer" width={20} />
                <span className="capitalize font-medium text-[12px]">
                  {text?.service?.name}
                </span>
              </div>
              <div className="flex items-center space-x-3 text-[7px] uppercase">
                <div className="flex space-x-1 items-center font-semibold ">
                  <Icon icon="tabler:clock-hour-2" width={13} />
                  <span> {TwentyFourHourFormat(text.timeSlot)}</span>
                </div>
                <div className="flex items-center font-semibold  space-x-1">
                  <Icon icon="mdi:calendar-today" width={13} />
                  <span> {moment(text.date).format("D MMM YYYY")}</span>
                </div>
                {text.state === "PENDING" && (
                  <div className="flex items-center font-semibold text-yellow-300 space-x-1">
                    <Icon icon="ic:round-hourglass-empty" width={13} />
                    <span>Pending</span>
                  </div>
                )}

                {text.state === "APPROVED" && (
                  <div className="flex items-center font-semibold text-green-600 space-x-1">
                    <Icon icon="ic:round-check-circle" width={13} />
                    <span>Approved</span>
                  </div>
                )}

                {text.state === "CANCELLED" && (
                  <div className="flex items-center font-semibold text-red-800 space-x-1">
                    <Icon icon="ic:round-cancel" width={13} />
                    <span>cancelled</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* modals  */}
      <AppointmentModal
        modalOpen={modalOpen}
        appointmentModalProps={appointmentModalProps}
        closeModal={() => {
          setModalOpen(false);
        }}
        refresh={refresh}
        openOtherModal={(appointmentModalProps: appointmentsType) => {
          setDeleteModalProps(appointmentModalProps);
          setDeleteModalOpen(true);
        }}
        hideCancelButton={userRole === "staff"}
      />
      <DeleteModal
        modalOpen={deleteModalOpen}
        refresh={refresh}
        closeModal={async (value: boolean) => {
          // if you click on yes
          if (value) {
            try {
              await axios.patch(
                `/api/appointment/${deleteModalProps?.id}/handle`,
                {
                  accepted: false,
                }
              );
            } catch (e) {
              console.error(e);
            } finally {
              setModalOpen(!modalOpen);
              setDeleteModalOpen(false);
            }
          } else {
            setModalOpen(!modalOpen);
            setDeleteModalOpen(false);
          }
        }}
        delete={handleDelete}
        title={
          deleteModalProps?.customer.firstName +
          " " +
          deleteModalProps?.customer.lastName +
          "'s " +
          deleteModalProps?.service.name +
          " Appointment"
        }
        type="Appointment"
        size="small"
      />
    </div>
  );
}
