import React, { useState } from "react";
import Avatar from "react-avatar";
import EditStaffScheduleModal from "./Modals/EditStaffScheduleModal";
import TableLoadingState from "./TableLoadingState";
import { scheduleType } from "../interfaces/schedule";
import ScheduleList from "./ScheduleList";
interface Props {
  items: scheduleType[];
  fetching?: boolean;
  refresh?: any;
}

const ScheduleTable = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<any>([]);

  const openModal = (content: any) => {
    setModalContent(content);
    setModalOpen(true);
  };
  const formatPeriod = (period: string) => {
    const list = period.split("-");
    const newList = list.map((time: string) => {
      if (parseInt(time) > 12) {
        const hour = parseInt(time.trim().split(":")[0]) % 12;
        const minute = time.trim().split(":")[1];
        return `${hour}:${minute} pm`;
      } else {
        return time.trim() + " am";
      }
    });
    const newPeriod = newList.join(" - ");
    return newPeriod;
  };
  return (
    <div className="inline-block w-full overflow-x-scroll border rounded-lg">
      <table className="w-full text-sm text-left bg-white">
        <thead className="w-full text-[11px] sm:text-xs font-semibold text-white md:text-sm bg-main">
          <tr>
            <th scope="col" className="px-6 py-3">
              Staff
            </th>
            <th scope="col" className="px-6 py-3" colSpan={9}>
              Schedule
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Break period
            </th>
          </tr>
        </thead>
        {!props.fetching ? (
          props.items.length ? (
            <tbody>
              {props.items.map((content: any, index: number) => (
                <tr
                  className="sm:text-xs text-[11px] border-b md:text-sm cursor-pointer"
                  key={index}
                  onClick={() => openModal(content)}
                >
                  <th
                    scope="row"
                    className="px-4 py-2 font-medium text-gray-900 md:px-6 md:py-4 whitespace-nowrap "
                  >
                    <p className="flex items-center justify-between">
                      {content.profilePic ? (
                        <img
                          src={
                            content.profilePic
                              ? content.profilePic
                              : "https://placehold.jp/40x40.png"
                          }
                          alt="staff"
                          className="object-cover w-10 h-10 rounded-[50%]"
                        />
                      ) : (
                        <Avatar
                          name={content.firstName + " " + content.lastName}
                          size="40"
                          round={true}
                          textSizeRatio={2.5}
                        />
                      )}
                      {content.firstName} {content.lastName}
                    </p>
                  </th>
                  <td className="px-4 py-2 md:px-6" colSpan={9}>
                    <div className="space-x-4">
                      <div>
                        <ScheduleList items={content} />
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 md:px-6 md:py-4">
                    {content.schedule ? (
                      formatPeriod(content.schedule.breakPeriod)
                    ) : (
                      <p className="text-center">-</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <TableLoadingState text="You have no Schedules" />
          )
        ) : (
          <TableLoadingState text="Fetching schedules..." fetching />
        )}
      </table>

      {modalOpen && (
        <EditStaffScheduleModal
          modalOpen={modalOpen}
          closeModal={() => {
            setModalOpen(false);
          }}
          content={modalContent}
          refresh={() => props.refresh(true)}
        />
      )}
    </div>
  );
};

export default ScheduleTable;
