import { Icon } from "@iconify/react";
import { BannerRedeemedCustomerI } from "../features/loyalty/loyalty";
interface propsType {
  redeemed: BannerRedeemedCustomerI;
}
const LoyaltyProgramBannerBox = (props: propsType) => {
  const {
    redeemed: {
      appointment: { customer },
    },
  } = props;
  return (
    <div className="flex w-full max-h-[80px] rounded-lg cursor-pointer text-[8px]">
      <div className="w-[32px] p-2 space-y-2 rounded-l-lg lg:w-9/12 bg-basegrey whitespace-nowrap overflow-ellipsis">
        <p className="font-semibold text-left">
          {customer && customer.firstName
            ? customer.fullName
            : "Anonymous Customer"}
        </p>

        {props.redeemed.appointment.service?.name && (
          <p className="text-left font-semibold flex items-center gap-2 w-full whitespace-nowrap">
            <Icon icon="ic:outline-local-offer" />
            <span>{props.redeemed.appointment.service?.name}</span>
          </p>
        )}

        {props.redeemed.appointment.staff?.firstName && (
          <p className="text-left font-semibold flex items-center">
            <span className="pr-1">
              <Icon icon="bi:person-badge" />
            </span>
            {props.redeemed.appointment.staff?.firstName}{" "}
            {props.redeemed.appointment.staff?.lastName}
          </p>
        )}
      </div>
      <div className="flex items-center justify-center w-1/4 p-2 rounded-r-lg bg-main">
        <h2 className="text-center text-white font-semibold">50VP</h2>
      </div>
    </div>
  );
};

export default LoyaltyProgramBannerBox;
