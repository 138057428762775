import React, { useState } from "react";
import PdfPreviewTemplate1 from "../../assets/images/pdftemplate1.png";
import moment from "moment";

const presetColors = [
  "bg-blue-500",
  "bg-purple-500",
  "bg-red-600",
  "bg-orange-500",
  "bg-yellow-500",
];

interface ServiceQuoteI {
  service?: string[];
  client?: string;
  calendar?: Date;
  staff?: string;
  timeSlot?: string;
  price?: string;
  applyDiscount?: boolean;
  discount?: string | number;
  applyTaxRate?: boolean;
  taxRate?: string | number;
  note?: string;
  paymentInstruction?: string;
}
function QuotePreview(props: ServiceQuoteI) {
  const [highlightColor, setHighlightColor] = useState(presetColors[0]);
  return (
    <div className="w-full flex flex-col gap-4 font-outfit">
      <span className="text-2xl font-bold">Quote Preview</span>
      <div className="p-6 w-full border rounded-2xl shadow-lg">
        <div
          className={`w-full ${highlightColor} rounded-2xl w-full p-6 bg-opacity-10 flex justify-between`}
        >
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 text-sm">
              <span className="text-2xl mb-2 font-bold">Invoice</span>
              <span>Billed To:</span>
              <span className="font-semibold">{props.client}</span>
              <span>Address / Contact Info:</span>
              <span className="font-semibold">
                {'"Client Address / Email"'}
              </span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 text-sm">
              <div className="flex flex-col mb-2">
                <span>Invoice No.</span>
                <span className="text-xl font-bold">#1234567</span>
              </div>
              <span>Issued on:</span>
              <span className="font-semibold">
                {moment(props.calendar).format("MMM D, YYYY")}
              </span>
              <span>Payment Due:</span>
              <span className="font-semibold">
                {moment().format("MMM D, YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 border rounded-2xl shadow-lg flex flex-col gap-4">
        <span className="text-xl font-bold">Customise Invoice Template</span>
        <span>Select Invoice Template</span>
        <div className="flex gap-4">
          <img src={PdfPreviewTemplate1} alt="pdf-preview-1" className="h-16" />
        </div>
        <div className="flex gap-4 justify-start">
          <div className="flex flex-col gap-2">
            <span>Add Logo</span>
            <div className="flex items-center justify-center">
              <label
                htmlFor="file-upload"
                className="cursor-pointer px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md shadow-lg-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Add Logo
              </label>
              <input id="file-upload" type="file" className="hidden" />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span>Highlight Color</span>
            <div className="flex gap-2">
              {presetColors.map((colorCode) => (
                <div
                  key={colorCode}
                  className={`w-8 h-8 ${colorCode} ${
                    colorCode === highlightColor && "border"
                  }`}
                  onClick={() => setHighlightColor(colorCode)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotePreview;
