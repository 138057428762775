import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PageHeader } from "../../components/Base/PageHeader";
import QuotePreview from "../../components/ServiceQuote/QuotePreview";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectServices } from "../../features/pages/pages";
import { getMyServices } from "../../features/service/service";
// import AddServiceModal from "../../components/Modals/AddServiceModal";
const FormSchema = Yup.object().shape({
  service: Yup.array().min(1, "Select at least one service").required(),
  client: Yup.string().required("Client is required"),
  calendar: Yup.date().required("Date is required"),
  timeSlot: Yup.string().required("Time slot is required"),
  price: Yup.number().required("Price is required").positive(),
  discount: Yup.string().when("applyDiscount", {
    is: true,
    then: Yup.string().required("Discount is required"),
  }),
  taxRate: Yup.string().when("applyTaxRate", {
    is: true,
    then: Yup.string().required("Tax rate is required"),
  }),
});

interface ServiceQuoteI {
  service?: string[];
  client?: string;
  calendar?: Date;
  staff?: string;
  timeSlot?: string;
  price?: string;
  applyDiscount?: boolean;
  discount?: string | number;
  applyTaxRate?: boolean;
  taxRate?: string | number;
  note?: string;
  paymentInstruction?: string;
}
function CreateServiceQuote() {
  const dispatch = useAppDispatch();
  const initialValues: ServiceQuoteI = {
    service: [],
    client: "",
    calendar: undefined,
    staff: "",
    timeSlot: "",
    price: "",
    applyDiscount: false,
    discount: "",
    applyTaxRate: false,
    taxRate: "",
    note: "",
    paymentInstruction: "",
  };
  const formikRef = useRef<FormikProps<ServiceQuoteI>>(null);

  const fetchTimeSlots = async (
    date: unknown
  ): Promise<
    Array<{
      value: string;
      label: string;
    }>
  > => {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          { value: "9:00 AM", label: "9:00 AM" },
          { value: "10:00 AM", label: "10:00 AM" },
        ]);
      }, 1000);
    });
  };

  const services = useAppSelector(selectServices);
  const [timeSlots, setTimeSlots] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);

  const servicesOptions = [
    { value: "service1", label: "Service 1" },
    { value: "service2", label: "Service 2" },
  ];

  const clientsOptions = [
    { value: "client1", label: "Client 1" },
    { value: "client2", label: "Client 2" },
  ];

  // const handleServiceModalRefresh = () => {
  //   setOpenServiceModal(!openServiceModal)
  // };

  useEffect(() => {
    if (!services || !services.length) {
      dispatch(getMyServices());
    }
  }, []);

  return (
    <div className="w-full h-screen flex justify-evenly gap-4">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          console.log("Form values", values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <>
            <div className="w-full border rounded px-12 py-3 flex flex-col">
              <PageHeader text="Quote Details" />
              <Form className="space-y-4 p-3 w-[70%]">
                {/* Service */}
                <div>
                  <label className="block text-sm font-medium">Service *</label>
                  <div className="flex gap-4 w-full items-center">
                    <div className="w-full">
                      <Select
                        options={servicesOptions}
                        isMulti
                        name="service"
                        onChange={(selected: string) =>
                          setFieldValue("service", selected)
                        }
                      />
                      {errors.service && touched.service && (
                        <p className="text-red-500 text-sm">{errors.service}</p>
                      )}
                    </div>
                    <Icon
                      className="cursor-pointer"
                      icon="material-symbols:add"
                      onClick={() => setOpenServiceModal(openServiceModal)}
                    />
                  </div>
                </div>

                {/* Client */}
                <div>
                  <label className="block text-sm font-medium">Client</label>
                  <div className="w-72">
                    <Select
                      options={clientsOptions}
                      name="client"
                      onChange={(selected: typeof clientsOptions[0]) =>
                        setFieldValue("client", selected?.value)
                      }
                    />
                    {errors.client && touched.client && (
                      <p className="text-red-500 text-sm">{errors.client}</p>
                    )}
                  </div>
                </div>

                {/* Calendar */}
                <div>
                  <label className="block text-sm font-medium">Calendar</label>
                  <DatePicker
                    selected={values.calendar}
                    onChange={async (date) => {
                      setFieldValue("calendar", date);
                      setLoadingSlots(true);
                      const slots = await fetchTimeSlots(date);
                      setTimeSlots(slots);
                      setLoadingSlots(false);
                    }}
                    className="border rounded px-2 py-1"
                  />
                  {errors.calendar && touched.calendar && (
                    <p className="text-red-500 text-sm">{errors.calendar}</p>
                  )}
                </div>

                {/* Time Slot */}
                <div>
                  <label className="block text-sm font-medium">Time Slot</label>
                  {loadingSlots ? (
                    <p>Loading...</p>
                  ) : (
                    <Select
                      options={timeSlots}
                      name="timeSlot"
                      onChange={(selected: typeof timeSlots[0]) =>
                        setFieldValue("timeSlot", selected?.value)
                      }
                    />
                  )}
                  {errors.timeSlot && touched.timeSlot && (
                    <p className="text-red-500 text-sm">{errors.timeSlot}</p>
                  )}
                </div>

                {/* Staff */}
                <div>
                  <label className="block text-sm font-medium">
                    Staff (Optional)
                  </label>
                  <Field
                    as="select"
                    name="staff"
                    className="border rounded px-2 py-1 w-full"
                  >
                    <option value="">Select Staff</option>
                    <option value="staff1">Staff 1</option>
                    <option value="staff2">Staff 2</option>
                  </Field>
                </div>

                {/* Price */}
                <div>
                  <label className="block text-sm font-medium">Price</label>
                  <Field
                    type="number"
                    name="price"
                    className="border rounded px-2 py-1 w-full"
                  />
                  {errors.price && touched.price && (
                    <p className="text-red-500 text-sm">{errors.price}</p>
                  )}
                </div>

                {/* Apply Discount */}
                <div>
                  <label className="block text-sm font-medium">
                    <Field type="checkbox" name="applyDiscount" />
                    <span className="mx-2">Apply Discount</span>
                  </label>
                  {values.applyDiscount && (
                    <Field
                      type="text"
                      name="discount"
                      className="border rounded px-2 py-1 w-full"
                      placeholder="Enter discount"
                    />
                  )}
                  {errors.discount && touched.discount && (
                    <p className="text-red-500 text-sm">{errors.discount}</p>
                  )}
                </div>

                {/* Apply Tax Rate */}
                <div>
                  <label className="block text-sm font-medium">
                    <Field type="checkbox" name="applyTaxRate" />
                    Apply Tax Rate
                  </label>
                  {values.applyTaxRate && (
                    <Field
                      type="text"
                      name="taxRate"
                      className="border rounded px-2 py-1 w-full"
                      placeholder="Enter tax rate"
                    />
                  )}
                  {errors.taxRate && touched.taxRate && (
                    <p className="text-red-500 text-sm">{errors.taxRate}</p>
                  )}
                </div>

                {/* Note */}
                <div>
                  <label className="block text-sm font-medium">Note</label>
                  <Field
                    as="textarea"
                    name="note"
                    className="border rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Payment Instruction */}
                <div>
                  <label className="block text-sm font-medium">
                    Payment Instruction
                  </label>
                  <Field
                    as="textarea"
                    name="paymentInstruction"
                    className="border rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md"
                >
                  Submit
                </button>
              </Form>
            </div>
            <QuotePreview {...values} />
          </>
        )}
      </Formik>
      {/* <AddServiceModal
          modalOpen={openServiceModal}
          closeModal={handleServiceModalRefresh}
          saveService={saveService}
          submitingService={submitingService}
        /> */}
    </div>
  );
}

export default CreateServiceQuote;
