import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import Dropdown from "./Dropdown";
import OverlaySpinner from "./OverlaySpinner";

const ProfileContent = () => {
  const {
    dropDownOptions,
    dropDownText,
    duration,
    fetch,
    getValue,
    isloading,
    getData,
  } = useAnalyticsHook();
  const [profile, setProfile] = useState<any>({ total: "4", difference: "44" });
  const getTraffic = async () => {
    const data = await getData(`/api/analytics/traffic?interval=${duration}`);
    // const data = await getData(`/api/business/analytics?interval=${duration}`)
    if (!data.error) {
      setProfile(data);
    }
  };
  useEffect(() => {
    getTraffic();
  }, [fetch]);
  const absoluteValue = (e: number) => {
    return Math.abs(e);
  };
  return (
    <div>
      <div className="flex justify-between h-[330px] p-6 overflow-auto bg-basegrey rounded-xl">
        <div className="w-full">
          <div className="flex items-center justify-between mb-2 space-x-3">
            <p className="text-[10px] font-semibold">Profile Traffic</p>
            <Dropdown
              text={dropDownText}
              values={dropDownOptions}
              getValue={getValue}
              size="small"
            />
          </div>
          <OverlaySpinner isLoading={isloading}>
            <div className="flex">
              <div className="flex items-center p-3 overflow-hidden bg-white h-60 rounded-xl">
                <div className="w-full">
                  <div className="flex my-5">
                    <h3
                      className={
                        (profile.difference < 0
                          ? "text-red-600"
                          : profile.difference === 0
                          ? "text-dark "
                          : "text-green-600") + " text-2xl font-bold"
                      }
                    >
                      {profile.percentage?.toFixed(2)}%
                    </h3>
                    {profile.difference === 0 && (
                      <span
                        className={
                          (profile.difference < 0
                            ? "text-red-600"
                            : profile.difference === 0
                            ? "text-dark "
                            : "text-green-600") + " font-bold"
                        }
                      >
                        <Icon
                          icon={`dashicons:arrow-${
                            profile.difference < 0 ? "down" : "up"
                          }-alt`}
                        />
                      </span>
                    )}
                  </div>
                  <p className="my-3 text-xs">
                    {profile.total} profile views for
                    <span className="font-semibold"> {dropDownText}</span>
                  </p>
                  {profile.difference === 0 ? (
                    <p className="text-[8px]">
                      Your traffic {"hasn't"} changed in the last {dropDownText}{" "}
                    </p>
                  ) : (
                    <p
                      className={
                        (profile.difference < 0
                          ? "text-red-600"
                          : profile.difference === 0
                          ? "text-dark "
                          : "text-green-600") + "  text-[8px] "
                      }
                    >
                      Your traffic has{" "}
                      {profile.difference < 0 ? "decreased" : "increased"} by{" "}
                      {absoluteValue(profile.difference)} views in the last{" "}
                      {dropDownText}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </OverlaySpinner>
        </div>
      </div>
    </div>
  );
};

export default ProfileContent;
