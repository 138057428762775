import React from "react";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder from "../assets/images/emptyAppointments.svg";
import { Button } from "./Base/Button";
export default function EmptyCustomerLoyaltyRedeems() {
  const navigate = useNavigate();

  return (
    <div className=" w-full  text-center pb-20 ">
      <img src={ImagePlaceholder} alt="" className="object-contain mx-auto" />
      <p className="mt-5 font-[600] interFamily">You have no points today</p>

      <div className=" mt-5 ">
        <Button
          className="bg-secondary"
          onClick={() => {
            navigate("/dashboard/loyalty-program");
          }}
        >
          <span className="text-xs my-1 subscriptionFont font-semibold">
            Go to Varroe Points
          </span>
        </Button>
      </div>
    </div>
  );
}
