import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { cartType } from "../../interfaces/appointments";
ChartJS.register(ArcElement, Tooltip, Legend);
interface Props {
  retension: cartType;
}
type cartChartType = [number,number]
const PieChart = (props: Props) => {
  const [coordinates, setCoordinates] = useState<cartChartType>([12, 19]);
  useEffect(() => {
    setCoordinates([props.retension.abandonedCount, props.retension.completedCount])

  }, [props.retension]);
 
  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 0,
        },
      },
    },
  };
  const data = {
    labels: ["Checked out carts", "Abandoned carts"],

    datasets: [
      {
        label: "",
        data: coordinates,
        backgroundColor: ["rgb(52, 168, 83)", "rgb(0, 28, 67)"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
