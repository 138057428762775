/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "@iconify/react";
import { Input } from "../Base/Input";
import { Formik, Field, Form } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "./Tabs";
import { Button } from "../Base/Button";
import { getBusinessCurrency } from "../../features/business/business";
import { useAppSelector } from "../../store/hooks";
interface Props {
  services: [];
  id?: number;
  getValue?: any;
  addData?: any;
  isEdit?: boolean;
  addOnServices?: any;
  appear?: boolean;
  editAddOnServices?: any;
  add?: any;
  delete?: (e: any) => void;
  scroll?: boolean;
}
interface arrayType {
  name: string;
  price: number;
}
const AddOns = (props: Props) => {
  const [name] = useState("");
  const [price] = useState("");
  const [array, setArray] = useState<arrayType[]>([]);
  const currency = useAppSelector((state) => getBusinessCurrency(state));

  const omo = useContext(DataContext);
  const itemToDelete = (index: number) => {
    setArray((item) => item.filter((item, i) => i !== index));
    props.delete && props.delete(index);
  };

  const saveAddOn = (data: any) => {
    // props.isEdit && props.getValue(name, price);
  };
  const hanldeAddon = () => {
    props.addData(array);
  };

  useEffect(() => {
    setArray(props.services);
  }, [props.editAddOnServices]);
  useEffect(() => {
    setTimeout(() => {
      scrolltoBottom();
    }, 300);
  }, [props.scroll]);
  useEffect(() => {
    !props.isEdit && setArray([...array, { name: "", price: 0 }]);
  }, [props.addOnServices]);
  useEffect(() => {
    hanldeAddon();
  }, [array]);
  useEffect(() => {});
  const handleName = (e: string, index: number) => {
    const formated = array;
    formated[index].name = e;
    setArray([...formated]);
  };
  const handlePrice = (e: number, index: number) => {
    const formated = array;
    formated[index].price = e;
    setArray([...formated]);
  };
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const scrolltoBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={`min-h-[210px] p-4 mt-5 space-y-4 overflow-y-scroll bg-white rounded-md max-h-56 ${
        props.appear ? "block" : "hidden"
      }`}
    >
      {array.length ? (
        array.map((service: any, index: number) => {
          return (
            <Formik
              initialValues={{
                name: service ? service.name : name,
                price: service ? service.price : price,
                id: service ? service.id : "",
              }}
              onSubmit={(data, { setSubmitting }) => {
                saveAddOn(data);
              }}
              key={index}
            >
              {({ errors, touched, submitForm, handleChange }) => (
                <Form>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-72">
                      <Field
                        name="name"
                        as={Input}
                        label="Name"
                        value={service.name ? service.name : name}
                        onChange={(e: any) => {
                          handleName(e.target.value, index);
                          setTimeout(() => {
                            submitForm();
                          });
                        }}
                      />
                    </div>
                    <div className="w-14">
                      <Field
                        name="price"
                        as={Input}
                        label={`Price (${currency ?? "$"})`}
                        type="number"
                        placeholder="0"
                        value={service.price ? service.price : price}
                        onChange={(e: any) => {
                          handlePrice(e.target.value, index);
                          setTimeout(() => {
                            submitForm();
                          });
                        }}
                      />
                    </div>

                    <div
                      onClick={() => itemToDelete(index)}
                      className="flex items-center justify-center h-6 px-1 mt-4 ml-3 text-white rounded-sm cursor-pointer bg-danger"
                    >
                      <Icon icon="ci:trash-empty" width="12" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          );
        })
      ) : (
        <div className="flex justify-center items-center  h-[160px] text-center">
          <div>
            <p className="py-2 font-[500]">You have no add ons</p>
            <Button
              onClick={() => {
                props.add();
              }}
            >
              <span>ADD ADDON</span>
            </Button>
          </div>
        </div>
      )}
      <div ref={bottomRef}></div>
    </div>
  );
};

export default AddOns;
