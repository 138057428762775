import axios from "axios";
import { Icon } from "@iconify/react";
import { Select } from "../Base/Select";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { InputValidationError } from "../../components/InputValidationError";
import { Input } from "../Base/Input";

const BusinessClassification = () => {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    businessDate: yup.date().required("Please enter a valid date"),
    openingTime: yup.string().required("Please enter your opening time"),
    closingTime: yup.string().required("Please enter your closing time"),
    businessType: yup.string().required("Please enter your type of business"),
    staff: yup.string().required("Please enter your staff size"),
    language: yup.string().required("Please enter your primary language"),
    website: yup.string(),
  });
  const postClassification = async (form: any) => {
    try {
      await axios.post("/api/business/parameter/classification", {
        date: form.businessDate,
        openingTime: form.openingTime,
        closingTime: form.closingTime,
        staffSize: form.staff,
        language: form.language,
        website: form.website,
        type: form.businessType,
      });
      navigate("/auth/register/documentation");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="relative">
      <div className="overflow-scroll font-sans max-h-[539px]">
        <h2 className="absolute font-bold text-white uppercase -left-3 -top-16">
          Business Classification
        </h2>

        <Formik
          initialValues={{
            businessDate: "",
            openingTime: "",
            closingTime: "",
            businessType: "Professionals Services",
            staff: "1 to 5 people",
            language: "English",
            website: "",
          }}
          onSubmit={(data, { setSubmitting }) => {
            console.log(data);
            postClassification(data);
            setSubmitting(true);
          }}
          validationSchema={schema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="p-5 space-y-3 font-roboto" id="form1">
              <div className="flex flex-col justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row ">
                <div className="md:w-[46%]">
                  <Field
                    name="businessDate"
                    type="date"
                    as={Input}
                    label="Business Start Date"
                  />

                  {errors.businessDate && touched.businessDate ? (
                    <InputValidationError text={errors.businessDate} />
                  ) : null}
                </div>
                <div className="md:w-[46%]">
                  <Field
                    name="website"
                    type="input"
                    as={Input}
                    label="Website (optional)"
                    placeholder="e.g varroe.com"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row ">
                <div className="md:w-[46%]">
                  <Field
                    name="openingTime"
                    type="time"
                    as={Input}
                    label="Opening Time"
                  />

                  {errors.openingTime && touched.openingTime ? (
                    <InputValidationError text={errors.openingTime} />
                  ) : null}
                </div>
                <div className="md:w-[46%]">
                  <Field
                    name="closingTime"
                    type="time"
                    as={Input}
                    label="Closing Time"
                  />
                  {errors.closingTime && touched.closingTime ? (
                    <InputValidationError text={errors.closingTime} />
                  ) : null}
                </div>
              </div>
              <div className="w-full">
                <Field
                  name="businessType"
                  options={[
                    "Professionals Services",
                    "Software Services",
                    "Training Services",
                  ]}
                  as={Select}
                  label="Type"
                />

                {errors.businessType && touched.businessType ? (
                  <InputValidationError text={errors.businessType} />
                ) : null}
              </div>

              <div className="w-full">
                <Field
                  name="staff"
                  options={[
                    "1 to 5 people",
                    "2 to 20 people",
                    "20 to 100 people",
                    "100 to 1000 people",
                  ]}
                  as={Select}
                  label="Staff Size"
                />

                {errors.staff && touched.staff ? (
                  <InputValidationError text={errors.staff} />
                ) : null}
              </div>
              <div className="w-full">
                <Field
                  name="language"
                  options={["English", "French"]}
                  as={Select}
                  label="Primary Language"
                />

                {errors.staff && touched.staff ? (
                  <InputValidationError text={errors.staff} />
                ) : null}
              </div>
              <div className="flex justify-end"></div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="absolute -inset-x-4 sm:inset-x-0 -bottom-16">
        <div className="flex justify-end">
          {/* <Link
            to="/auth/register"
            className="flex items-center text-xs font-semibold text-white"
          >
            <span className="pr-1">
              <Icon icon="akar-icons:arrow-left" />
            </span>
            Business Identification
          </Link> */}

          <button
            type="submit"
            form="form1"
            className="flex items-center text-xs font-semibold text-white"
          >
            Documentation
            <span className="pl-1">
              <Icon icon="akar-icons:arrow-right" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessClassification;
