import React from "react";

interface options {
  text: string;
  value: number | string;
}
interface ServicesSelectProps {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  required?: boolean;
  options: options[] | any;
  // remove any after refactoring the business creation pages
  width?: string;
  name?: string;
  defaultValue?: any;
  value?: any;
  getServiceId?: any;
}

export const ServicesSelect: React.FC<ServicesSelectProps> = ({
  label,
  onChange,
  disabled,
  required,
  options,
  name,
  defaultValue,
  value,
  getServiceId,
}) => {
  return (
    <>
      <div className="flex flex-col">
        <label className="mb-2 text-[10px] text-left">{label}</label>
        <div className="">
          {options && (
            <select
              name={name}
              required={required}
              disabled={disabled}
              onChange={onChange}
              value={value}
              className={`w-full cursor-pointer appearance-none text-sm py-2 px-3 rounded-md  h-auto border border-solid border-darkergrey bg-lightergrey focus:outline-0  focus:border-blue-300`}
            >
              {options.map((value: any, index: number) => (
                <option value={value.id ? value.id : value} key={index}>
                  {value.label ? value.label : value}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </>
  );
};
