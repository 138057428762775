import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../src/config/firebase";
import {
  continueWithApple,
  registerFirebase,
  signInWithGoogleAction,
} from "../../features/auth/auth";
import { useAppDispatch } from "../../store/hooks";
import { Icon } from "@iconify/react";
import { Button } from "../../components/Base/Button";
import { Input } from "../../components/Base/Input";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { InputValidationError } from "../../components/InputValidationError";
import axios from "axios";
import { activateBusiness } from "../../features/business/business";

const Signup = () => {
  const navigate = useNavigate();
  const [authFlag, setAuthFlag] = useState(true);
  const dispatch = useAppDispatch();
  onAuthStateChanged(auth, (currentUser) => {
    if (authFlag) {
      setAuthFlag(false);
    }
  });
  const getBusiness = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/me/business`);
      if (!data.length) {
        // no active business and no list of businesses
        navigate("/auth/start");
      } else if (data.length > 1) {
        // has multiple businesses
        navigate("/auth/start/businesses", { state: { business: data } });
      } else if (data.length === 1) {
        // has only one business
        await makeActiveBusiness(data[0].businessId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const makeActiveBusiness = async (payload: string | number) => {
    try {
      await dispatch(activateBusiness(payload));
      navigate("/dashboard/home");
    } catch (error) {
      console.error(error);
    }
  };
  const handleGoogle = async () => {
    try {
      const response: any = await dispatch(signInWithGoogleAction());
      if (response.error) return;
      getBusiness();
    } catch (error) {
      console.error(error);
    }
  };
  const schema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    registerEmail: yup
      .string()
      .required("Please enter your email address")
      .email("Please enter a valid email address"),
    registerPassword: yup
      .string()
      .required("Please enter your password")
      .min(6, "Passwords must be 6 or more characters"),
  });
  const handleApple = async () => {
    try {
      const response: any = await dispatch(continueWithApple());
      if (response.error) return;
      getBusiness();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="relative w-full overflow-scroll bg-white rounded-md sm:p-2">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          registerEmail: "",
          registerPassword: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          (async () => {
            try {
              const response: any = await dispatch(registerFirebase(data));
              setSubmitting(false);
              if (response.error) return;
              navigate("/auth/start");
            } catch (e) {
              console.log(e);
            }
          })();
        }}
        validationSchema={schema}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="p-5 space-y-5 font-roboto">
            <div className="flex flex-col items-center space-y-5">
              <Button auth="apple-button" onClick={() => handleApple()}>
                <Icon icon="bi:apple" color="white" width={13} />
                <span className="ml-3"> Continue with Apple</span>
              </Button>
              <Button
                auth="google-button"
                onClick={async () => await handleGoogle()}
              >
                <Icon icon="flat-color-icons:google" width={13} />
                <span className="ml-3"> Continue with Google</span>
              </Button>

              <h2 className="font-bold uppercase">OR</h2>
            </div>

            <div className="flex flex-col justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row ">
              <div className="md:w-[46%]">
                <Field
                  name="firstName"
                  type="input"
                  as={Input}
                  label="First Name"
                  placeholder="John"
                />

                {errors.firstName && touched.firstName ? (
                  <InputValidationError text={errors.firstName} />
                ) : null}
              </div>
              <div className="md:w-[46%]">
                <Field
                  name="lastName"
                  type="input"
                  as={Input}
                  label="Last Name"
                  placeholder="Doe"
                />
                {errors.lastName && touched.lastName ? (
                  <InputValidationError text={errors.lastName} />
                ) : null}
              </div>
            </div>

            <div>
              <Field
                name="registerEmail"
                type="email"
                as={Input}
                label="Email"
                placeholder="johndoe@gmail.com"
              />
              {errors.registerEmail && touched.registerEmail ? (
                <InputValidationError text={errors.registerEmail} />
              ) : null}
            </div>
            <div>
              <Field
                name="registerPassword"
                type="password"
                as={Input}
                label="Password"
                placeholder="*******"
              />

              {errors.registerPassword && touched.registerPassword ? (
                <InputValidationError text={errors.registerPassword} />
              ) : null}
            </div>
            <div className="flex justify-center">
              <Button
                variant="bg-primary"
                type="submit"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                Register
              </Button>
            </div>

            <div className="flex justify-center">
              <div className="flex items-center text-xs text-center">
                Already have an account?
                <Link to="/auth/login" className="pl-1 text-primary">
                  Login here
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Signup;
