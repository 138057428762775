import React, { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectAppointments } from "../features/appointment/appointment";
import { appointmentsType } from "../interfaces/appointments";
import AppointmentBox from "./AppointmentBox";
import AppointmentModal from "./Modals/AppointmentModal";
import { PageHeader } from "./Base/PageHeader";

const StaffAppointmentBanner = () => {
  const [upcomingAppointment, setUpcomingAppointment] = useState<
    appointmentsType[]
  >([]);
  const [appointmentModalProps, setAppointmentModalProps] =
    useState<appointmentsType>();
  const [modalOpen, setModalOpen] = useState(false);

  const storeAppointments = useAppSelector(selectAppointments);
  useEffect(() => {
    setUpcomingAppointment([]);

    const upcoming = storeAppointments.filter(
      (appoint: appointmentsType) => appoint.state !== "APPROVED"
    );
    setUpcomingAppointment(upcoming);
  }, [storeAppointments]);
  const showModals = (content: any) => {
    setAppointmentModalProps(content);
    setModalOpen(true);
  };
  const user = useAppSelector((state) => state.auth.user);

  return (
    <div>
      <PageHeader text={`Hi ${user.first_name}!`} />

      <div className="z-10 h-auto min-h-[500px] max-h-[680px]  overflow-auto p-3 my-3 mr-3 bg-[#DEDEDE] lg:p-6 dropshadow rounded-xl">
        <h2 className="text-sm font-semibold text-left lg:text-base">
          Upcoming Appointments
        </h2>
        <div className="mt-6 space-y-3">
          {upcomingAppointment.length ? (
            upcomingAppointment.map((appointment, index) => (
              <AppointmentBox
                key={index}
                appointment={appointment}
                openModal={showModals}
              />
            ))
          ) : (
            <h2>No available appointments</h2>
          )}
        </div>
      </div>
      <AppointmentModal
        hideCancelButton
        modalOpen={modalOpen}
        appointmentModalProps={appointmentModalProps}
        closeModal={() => setModalOpen(false)}
      />
    </div>
  );
};

export default StaffAppointmentBanner;
