import axios from "axios";
import { useEffect, useState } from "react";
import { getactiveBusiness } from "../../features/business/business";
import useFileUpload from "../../hooks/useFileUpload";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Button } from "../Base/Button";
import FileUpload from "../FileUpload";
import OverlaySpinner from "../OverlaySpinner";

const BusinessDocumentation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [license, setLicense] = useState("");
  const [initialLicense, setInitialLicense] = useState("");
  const [bill, setBill] = useState("");
  const [initialbill, setInitialBill] = useState("");
  const [id, setId] = useState("");
  const [initialid, setInitialId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allData, setAllData] = useState<any>();
  const dispatch = useAppDispatch();
  const { submitFile } = useFileUpload();
  const userRole = useAppSelector((state) => state.auth.role);
  const [update, setUpdate] = useState(false);

  const handleUpdateButton = () => {
    const list = [
      bill === initialbill,
      id === initialid,
      license === initialLicense,
    ];
    if (list.some((value) => !value)) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  };
  useEffect(() => {
    handleUpdateButton();
  }, [bill, id, license]);
  const getTypeParameterOwner = async () => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await axios.get(`/api/business/parameter/documentation`);
      setInitialBill(data.value.utilityBill);
      setInitialId(data.value.governmentBill);
      setInitialLicense(data.value?.operatingLicense);
      setAllData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTypeParameterOwner();
  }, []);

  const handleUpdate = async () => {
    try {
      setIsLoading(true);

      const payload = {
        ...allData.value,
        governmentBill: id || allData.value.governmentBill,
        operatingLicense: license || allData.value.operatingLicense,
        utilityBill: bill || allData.value.utilityBill,
      };
      await axios.post(`/api/business/parameter/documentation`, payload);
      dispatch(getactiveBusiness());
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const pushFilesToArray = async (file: any) => {
    try {
      setIsSubmitting(true);
      const url = await submitFile(file, "operatingLicense");
      setLicense(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  const pushFilesToArrayBill = async (file: any) => {
    try {
      setIsSubmitting(true);
      const url = await submitFile(file, "utilityBill");
      setBill(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  const pushFilesToArrayId = async (file: any) => {
    try {
      setIsSubmitting(true);
      const url = await submitFile(file, "governmentID");
      setId(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-11/12 p-8 my-10 overflow-y-scroll bg-white lg:w-7/12 dropshadow rounded-xl">
        <OverlaySpinner isLoading={isLoading}>
          <div className="flex flex-col mb-16 space-y-8 space-x-6 lg:space-y-0 md:flex-row md:flex-nowrap">
            <FileUpload
              label="Government Issued ID"
              pushFilesToArray={pushFilesToArrayId}
            />
            <FileUpload
              label="Utility Bill (Address Confirmation)"
              pushFilesToArray={pushFilesToArrayBill}
            />
            {userRole === "merchant" && (
              <FileUpload
                label="Operating License"
                pushFilesToArray={pushFilesToArray}
              />
            )}
          </div>
          <Button
            onClick={handleUpdate}
            auth={update ? " bg-main text-white " : " bg-gray-200  "}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Loading..." : "Update"}
          </Button>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default BusinessDocumentation;
