import axios from "axios";
import React, { useEffect, useState } from "react";
import { getactiveBusiness } from "../../features/business/business";
import useFileUpload from "../../hooks/useFileUpload";
import { business } from "../../interfaces/business";
import { useAppDispatch } from "../../store/hooks";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import FileUpload from "../FileUpload";
import OverlaySpinner from "../OverlaySpinner";

interface Props {
  business: business;
}
const BusinessOwnerDetails = (props: Props) => {
  const [update, setUpdate] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [firstName, setFirstName] = useState("");
  const [initialFirstName, setInitialFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initialLastName, setInitialLastName] = useState("");
  const [logo, setLogo] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();

  const [allData, setAllData] = useState<any>();

  const { submitFile } = useFileUpload();
  const handleUpdateButton = () => {
    const list = [firstName === initialFirstName, lastName === initialLastName];
    if (list.some((value) => !value)) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  };
  useEffect(() => {
    handleUpdateButton();
  }, [lastName, firstName]);
  const pushFilesToArray = async (file: any) => {
    try {
      setIsloading(true);
      const url = await submitFile(file, "staffProfile");
      setLogo(url);
      setSelectedFile([...selectedFile, file]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const handleUpdate = async () => {
    try {
      setIsloading(true);
      const payload = { ...allData };
      payload.firstName = firstName;
      payload.lastName = lastName;
      payload.avatarUrl = logo || payload.avatarUrl;

      await axios.put(`api/me/profile/`, payload);
      dispatch(getactiveBusiness());
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const setValues = (data: any) => {
    setAllData(data);
    setFirstName(data.firstName);
    setInitialFirstName(data.firstName);
    setLastName(data.lastName);
    setInitialLastName(data.lastName);
  };
  const getOwner = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`api/me/profile/`);
      console.log("data", data);
      setValues(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    // setValues();
    getOwner();
  }, [props.business]);

  return (
    <div className="flex justify-center">
      <div className="w-11/12 p-6 my-10 overflow-y-scroll bg-white lg:w-7/12 md:p-8 dropshadow rounded-xl">
        <OverlaySpinner isLoading={isLoading}>
          <form className="space-y-5 font-roboto">
            <div className="flex flex-col justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row ">
              <div className="md:w-[46%]">
                <Input
                  label="First Name"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="md:w-[46%]">
                <Input
                  label="Last Name"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between pt-2 pb-10 sm:flex-row sm:flex-nowrap">
              {/* <div className="md:w-[46%] py-2  sm:py-0">
              <Input
                type="email"
                placeholder="john.doe@gmail.com"
                label="Email Address"
              />
            </div> */}
              <div className="md:w-[46%] mb-4 sm:mb-0">
                <FileUpload
                  label="Photo"
                  text="Document"
                  pushFilesToArray={pushFilesToArray}
                />
              </div>
            </div>

            <Button
              disabled={isLoading}
              onClick={handleUpdate}
              auth={update ? " bg-main text-white " : " bg-gray-200  "}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
          </form>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default BusinessOwnerDetails;
