import React from "react";
import { Navigation } from "../components/Navigation";
interface Props {
  children?: React.ReactNode;
}
const Layout: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex w-full h-screen overflow-x-hidden overflow-y-auto">
      <Navigation />
      <div className="col-span-4 space-y-5 sm:col-span-2 md:col-span-3 md:space-y-10">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
