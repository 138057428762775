import React from "react";

interface RadioButtonProps {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  id?: string;
  defaultChecked?: true | false;
  value: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  onChange,
  disabled,
  name,
  id,
  defaultChecked,
  value,
}) => {
  return (
    <>
      <input
        type="radio"
        name={name}
        onChange={onChange}
        disabled={disabled}
        id={id}
        value={value}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};
