import { Rating } from "flowbite-react";
import { reviewType } from "../interfaces/review";
import useMeridem from "../hooks/useMeridem";

interface Props {
  review: reviewType;
}
const Review = (props: Props) => {
  const { formatTime } = useMeridem();
  return (
    <div className="max-w-[280px]">
      <div className="flex flex-col rounded-lg">
        <div className="p-3 space-y-1 rounded-t-lg xl:p-3 bg-basegrey">
          <Rating>
            <Rating.Star filled={props.review.rating > 0} />
            <Rating.Star filled={props.review.rating > 1} />
            <Rating.Star filled={props.review.rating > 2} />
            <Rating.Star filled={props.review.rating > 3} />
            <Rating.Star filled={props.review.rating > 4} />
          </Rating>
          <div className=" h-[110px] overflow-auto">
            <p className="text-sm text-left  flex items-center py-1">
              {formatTime(props.review.createdAt)}
            </p>
            <p className="text-sm text-left  flex items-center pt-1">
              {props.review.comment}
            </p>
          </div>
        </div>
        <div className="flex justify-end p-3 rounded-b-lg bg-main">
          <h2 className="text-xs lg:text-[15px] text-right text-white font-semibold">
            {props.review.user.firstName} {props.review.user.lastName}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Review;
