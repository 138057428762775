import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BusinessSwitcher from '../../components/AuthComponents/BusinessSwitcher';
import NoBusiness from '../../components/AuthComponents/NoBusiness';
import LinkToBusiness from '../../components/AuthComponents/LinkToBusiness';

const AuthProcess = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<NoBusiness />} />
        <Route path="businesses" element={<BusinessSwitcher />} />
        <Route path="link" element={<LinkToBusiness />} />
      </Routes>
    </div>
  );
};

export default AuthProcess;
