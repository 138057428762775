import React, { useState } from "react";
import { Button } from "../../components/Base/Button";
import { Input } from "../../components/Base/Input";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { forgotPassword } from "../../features/auth/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();
  const dispatchForgotPassword = (e: any) => {
    e.preventDefault();
    dispatch(forgotPassword(email));
  };
  return (
    <div className="flex flex-col items-center">
      <form
        onSubmit={dispatchForgotPassword}
        className="p-5 space-y-5 font-roboto bg-white rounded-md w-full sm:w-[340px]"
      >
        <Input
          required
          type="email"
          label="Email"
          placeholder="johndoe@gmail.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <div className="flex justify-center">
          <Button type="submit" variant="bg-lightblue">
            Reset password
          </Button>
        </div>
        <div className="flex justify-center">
          <div className="text-xs">
            Remembered?
            <Link to="/auth/login" className="pl-1 text-primary">
              Login here
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
