import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import { revenueGrowthType } from "../interfaces/analytics";
import BarChart from "./Graphs/BarChart";
import OverlaySpinner from "./OverlaySpinner";

const RevenueGrowth = () => {
  const { fetch, isloading, getData, noData, setNoData } = useAnalyticsHook();
  const [growth, setGrowth] = useState<revenueGrowthType[]>([
    { year: "2022", revenue: 648 },
  ]);
  const getRevenue = async () => {
    // const data = await getData(`/api/analytics/revenue/?interval=${duration}`)

    const data = await getData(`/api/analytics/annual-growth`);
    //  const data = await getData(`/api/appointment/analytics/?interval=${duration}`)
    if (!data.error) {
      setGrowth(data);
      const empty = data.every((el: any) => el.revenue === 0);
      setNoData(empty);
    }
  };
  useEffect(() => {
    getRevenue();
  }, [fetch]);
  return (
    <div className="flex justify-between h-[330px] p-6 overflow-auto bg-basegrey rounded-xl">
      <div className="w-full">
        <div className="flex justify-between mb-2">
          <p className="font-semibold md:text-lg">
            Revenue year-on-year growth
          </p>
        </div>
        <OverlaySpinner isLoading={isloading} noData={noData}>
          <div className="flex items-center space-x-5">
            <div className="flex items-center p-4 overflow-hidden bg-white h-60 rounded-xl">
              <div className="w-96 ">
                <BarChart data={growth} />
              </div>
            </div>
          </div>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default RevenueGrowth;
