import React from "react";
import { Button } from "./Button";
import ProgressBar from "./ProgressBarComponent";

interface props {
  text: string;
  buttontext?: string;
  buttonevent?: any;
  progressvalue?: string;
  isSubmitting?: boolean;
}

export const PageHeader: React.FC<props> = ({
  text,
  buttontext,
  buttonevent,
  progressvalue,
  isSubmitting,
}) => {
  return (
    <div className="flex items-center justify-between my-4">
      <h2 className="font-sans text-lg font-semibold text-left capitalize md:text-2xl font-outfit">
        {text}
      </h2>

      {buttontext && (
        <Button
          onClick={buttonevent}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        >
          {buttontext}
        </Button>
      )}
      {progressvalue && <ProgressBar progressvalue={progressvalue} />}
    </div>
  );
};
