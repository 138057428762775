// import { Icon } from "@iconify/react";
import { useState } from "react";
import StaffModal from "./Modals/StaffModal";
import DeleteModal from "./Modals/DeleteModal";
import EditStaffModal from "./Modals/EditStaffModal";
import axios from "axios";
import StaffCard, { StaffSkeleton } from "./StaffCard";
import StaffPagination from "./staffPagination";

interface Props {
  staffs: any;
  fetch?: boolean;
  refresh?: any;
  isLoading: boolean;
  from: number;
  to: number;
  records: number;
  prev: () => void;
  next: () => void;
  currentPage: number;
}

const Table = (props: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);

  const [staffModalId, setStaffModalId] = useState(0);
  const [editModalId, setEditModalId] = useState(0);
  const [deleteModalId, setDeleteModalId] = useState(0);
  const [deleteModalName, setDeleteModalName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteStaff = async (id: number) => {
    try {
      setIsDeleting(true);
      await axios.delete(`/api/staff/${id}`);
      setIsDeleting(false);
      setDeleteModalOpen(false);
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };
  const openModals = (id: number, type: string, name: string) => {
    if (type === "staffmodal") {
      setStaffModalId(id);
      setStaffModalOpen(true);
    }
    if (type === "editmodal") {
      setEditModalId(id);
      setEditModalOpen(true);
    }
    if (type === "deletemodal") {
      setDeleteModalId(id);
      setDeleteModalName(name);
      setDeleteModalOpen(true);
    }
  };

  return (
    <div>
      <div className="">
        {!props.fetch ? (
          props.staffs.length ? (
            <div className="min-h-[83vh] flex flex-col justify-between ">
              <div className="grid grid-cols-3 gap-8">
                {props.staffs.map((content: any, index: number) => (
                  <StaffCard
                    key={index}
                    onClick={() => {
                      openModals(content.id, "staffmodal", "");
                      setStaffModalOpen(true);
                    }}
                    content={content}
                  />
                ))}
              </div>
              <div className="flex justify-center">
                <StaffPagination
                  from={props.from}
                  to={props.to}
                  records={props.records}
                  next={props.next}
                  prev={props.prev}
                  currentPage={props.currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center  p-5">
              You don&apos;t have any staff. Send an invite to add a staff to
              your business.
            </div>
          )
        ) : (
          <div className="grid grid-cols-3 gap-8">
            {[...Array(8)].map((_, staffIndex) => (
              <StaffSkeleton key={staffIndex} />
            ))}
          </div>
        )}
      </div>

      {staffModalOpen && (
        <StaffModal
          staffId={staffModalId}
          modalOpen={staffModalOpen}
          closeModal={() => {
            setStaffModalOpen(false);
          }}
          refresh={() => {
            props.refresh(true);
          }}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal
          modalOpen={deleteModalOpen}
          closeModal={() => {
            setDeleteModalOpen(false);
          }}
          type="Staff"
          title={deleteModalName}
          size="small"
          delete={() => deleteStaff(deleteModalId)}
          isSubmitting={isDeleting}
        />
      )}
      {editModalOpen && (
        <EditStaffModal
          modalOpen={editModalOpen}
          closeModal={() => {
            setEditModalOpen(false);
          }}
          staffId={editModalId}
          size="large"
          refresh={() => props.refresh(true)}
        />
      )}
    </div>
  );
};

export default Table;
