import { useEffect, useRef } from 'react';

interface Props {
  color: string;
  text: string;
  getValue: (val: string) => void;
}

const ScheduleDate = (props: Props) => {
  const colorRef = useRef<any>(null);
  const changeColor = () => {
    const newColor = colorRef.current.className;

    if (newColor.includes('bg-maingreen')) {
      colorRef.current.className = 'bg-danger schedulespan';
    } else {
      colorRef.current.className = 'bg-maingreen schedulespan';
    }
    props.getValue(props.text);
  };

  useEffect(() => {
    colorRef.current.className = props.color + ' schedulespan';
  }, []);
  return (
    <span
      ref={colorRef}
      className={``}
      onClick={() => {
        changeColor();
      }}
    >
      {props.text.slice(0, 3)}
    </span>
  );
};

export default ScheduleDate;
