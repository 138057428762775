import React from "react";
import Spinner from "./Spinner";

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: string;
  auth?: string;
  disabled?: boolean;
  children: any;
  className?: "bg-primary" | "bg-secondary" | "bg-danger";
  type?: any;
  isSubmitting?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  variant,
  auth,
  children,
  type = "button",
  isSubmitting,
}) => {
  return (
    <button
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      className={`${variant || auth ? variant : "bg-main"} 
      ${isSubmitting ? "opacity-70" : ""} 
      ${
        auth
          ? `${auth} `
          : "hover:opacity-70 text-white text-sm font-semibold uppercase"
      }
       inline-flex items-center px-6 py-2 rounded-md transition ease-in-out duration-300 font-outfit 
       ${disabled ? "cursor-not-allowed" : "cursor-pointer "}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {isSubmitting ? <Spinner /> : ""}
      {children}
    </button>
  );
};
