import ModalContainer from "./ModalContainer";
import { Icon } from "@iconify/react";
import { appointmentsType } from "../../interfaces/appointments";
import moment from "moment";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  openOtherModal?: any;
  appointments: appointmentsType[];
  selectedDate: string;
}

const getTimeMeridian = (time: string) => {
  return moment(`1970-01-01T${time}`).format("LT");
};

const AppointmentList = (props: Props) => {
  return (
    <>
      <ModalContainer
        modalOpen={props.modalOpen}
        closeModal={props.closeModal}
        size="large"
      >
        <div className="font-sans transition-all duration-300 ease-in-out cursor-pointer">
          <div className="h-full">
            <div className="overflow-y-scroll rounded-lg">
              <div className="space-y-4 ">
                <h3 className="font-semibold leading-6 text-left text-gray-900 md:text-lg">
                  {props.selectedDate}
                </h3>

                <div className="flex flex-col justify-center space-y-4">
                  {props.appointments.map((appointment) => (
                    <div
                      key={appointment.id}
                      className="px-6 py-3 bg-[#f1f1f1] rounded-lg"
                      onClick={() => {
                        props.openOtherModal(appointment);
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="w-full sm:w-auto">
                          <p className="text-sm font-semibold lg:text-lg">
                            {appointment.service.name}
                          </p>
                        </div>
                        <Icon icon="ci:dot-02-s" />
                        <div className="">
                          <span className="text-xs">Client</span>
                          <p className="text-xs font-semibold md:text-sm lg:text-lg">
                            {appointment.customer.firstName +
                              " " +
                              appointment.customer.lastName}
                          </p>
                        </div>
                        {appointment.staff &&
                          appointment.staff.firstName &&
                          appointment.staff.lastName && (
                            <div className="">
                              <span className="text-xs">Staff</span>
                              <p className="text-xs font-semibold md:text-sm lg:text-lg">
                                {appointment.staff.firstName +
                                  " " +
                                  appointment.staff.lastName}
                              </p>
                            </div>
                          )}
                        <p className="text-xs font-semibold md:text-sm lg:text-lg">
                          {getTimeMeridian(appointment.timeSlot)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default AppointmentList;
