import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { serviceInfo } from "../../interfaces/pages";
import { SET_SERVICES } from "../pages/pages";

interface CreateServiceDTO {
  name: string;
  image: string;
  price: number;
  duration: string;
  description: string;
  isLive: boolean;
  isHome: boolean;
  selectStaff: boolean;
  addons: [];
  customisations: [];
  images: [];
}

export const getMyServices = createAsyncThunk(
  "service/getMyServices",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<{ data: serviceInfo[] }>(
        "/api/business/service"
      );
      const services = data.data;
      thunkAPI.dispatch(SET_SERVICES(services));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { message, response } = error;
        return thunkAPI.rejectWithValue(
          (response?.data as any)?.message || message
        );
      } else if (error.message) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(
          "Unknown Error Response: Contact Support"
        );
      }
    }
  }
);

export const createService = createAsyncThunk(
  "service/createService",
  async (payload: CreateServiceDTO, thunkAPI) => {
    try {
      const { data } = await axios.get<{ data: serviceInfo[] }>(
        "/api/business/service"
      );
      const services = data.data;
      thunkAPI.dispatch(SET_SERVICES(services));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { message, response } = error;
        return thunkAPI.rejectWithValue(
          (response?.data as any)?.message || message
        );
      } else if (error.message) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(
          "Unknown Error Response: Contact Support"
        );
      }
    }
  }
);
