import React from 'react';
import MerchantsLogo from '../../assets/images/merchantslogo.svg';
interface Props {
  children: React.ReactNode;
}
const AuthLayout: React.FC<Props> = (props: Props) => {
  return (
    <div className="relative h-screen pt-10 overflow-auto authlayoutbg bg-main">
      <div className="flex flex-col sm:justify-center w-10/12 h-screen mx-auto sm:w-6/12 md:w-[40%] xl:w-[30%] sm:items-center space-y-4">
        <div className="flex items-center justify-center w-20 mx-auto md:pb-4 my-14 md:my-0 md:w-36">
          <img src={MerchantsLogo} alt="varroe-merchants=logo" />
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
