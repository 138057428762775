import { useEffect, useState } from "react";
import EmptyCustomerLoyaltyRedeems from "./EmptyCustomerLoyaltyRedeem";
import axios from "axios";
import { BannerRedeemedCustomerI } from "../features/loyalty/loyalty";
import LoyaltyProgramBannerBox from "./LoyaltyProgramBannerBox";
import { Spinner } from "flowbite-react";

const LoyaltyProgramBanner = () => {
  const [redeemedRecords, setRedeemedRecords] = useState<
    BannerRedeemedCustomerI[]
  >([]);
  const [loading, setIsloading] = useState(false);

  const getRedeemedRecords = async () => {
    try {
      !redeemedRecords.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/point/today`);
      setRedeemedRecords(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getRedeemedRecords();
  }, []);
  return (
    <div className="z-10 h-[90vh] w-[40vh] overflow-auto p-3 my-3 mr-3 bg-white lg:p-6 dropshadow rounded-xl fixed flex flex-col gap-6">
      <h2 className="text-sm font-semibold text-left lg:text-base">
        Today’s Points
      </h2>
      <div className="w-full flex flex-col items-center justify-center gap-6">
        {loading ? <Spinner size="xl" /> : null}
        {redeemedRecords.length
          ? redeemedRecords.map((entry, index) => (
              <LoyaltyProgramBannerBox key={index} redeemed={entry} />
            ))
          : null}
        {!loading && !redeemedRecords.length ? (
          <EmptyCustomerLoyaltyRedeems />
        ) : null}
      </div>
    </div>
  );
};

export default LoyaltyProgramBanner;
