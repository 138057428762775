/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { DateTime } from "ts-luxon";
import AddAppointmentModal from "../components/AddAppointmentModal";
import AppointmentsTable from "../components/AppointmentsTable";
import AppointmentsToggle from "../components/AppointmentsToggle";
import { PageHeader } from "../components/Base/PageHeader";
import { Select } from "../components/Base/Select";
import Calenderview from "../components/Calenderview";
import SearchComponent from "../components/SearchComponent";
import { selectBusiness } from "../features/business/business";
import {
  selectAppointments,
  SET_APPOINTMENTS_DATA,
} from "../features/pages/pages";
import { appointmentsType, eventsProp } from "../interfaces/appointments";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const Appointments = () => {
  // state
  const appointments = useAppSelector(selectAppointments);
  const [fetch, setFetch] = useState(true);
  const [search, setSearch] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [approved, setApproved] = useState<appointmentsType[]>([]);
  const [awaiting, setAwaiting] = useState<appointmentsType[]>([]);

  const [events, setEvents] = useState<eventsProp["events"]>([]);
  const [state, setState] = useState('["PENDING"]'); // api throws an error if i send an actual array

  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageLimit, setPageLimit] = useState<number>(15);
  const [recordCount, setRecordCount] = useState<number>(2);
  const [headerText, setHeaderText] = useState<string>("Pending Appointments");
  const [showFrom, setShowFrom] = useState(1);
  const [showTo, setShowTo] = useState(1);
  const [showActions, setShowActions] = useState(true);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const userId = useAppSelector((state) => state.business.id);
  const dispatch = useAppDispatch();
  // handlers
  const activeBusiness = useAppSelector(selectBusiness);

  const handlePagination = () => {
    setShowFrom((currentPage - 1) * pageLimit + 1);
  };

  const getAllAppointments = async () => {
    const staffQuery = `, "staffId": ["${userId}"]`;

    try {
      setIsloading(true);
      const approved = await axios.get(
        `/api/appointment?where={"state": ["APPROVED"]${
          userRole === "staff" ? staffQuery : ""
        }} `
      );
      const awaiting = await axios.get(
        `/api/appointment?where={"state": ["PENDING"], "status":["RESERVED","PAID"] ${
          userRole === "staff" ? staffQuery : ""
        }}`
      );
      setApproved(approved.data.data);
      setAwaiting(awaiting.data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const getAppointments = async () => {
    const staffQuery = `, "staffId": ["${userId}"]`;
    try {
      !appointments.length && setIsloading(true);
      const url = `/api/appointment?search=${search}&limit=${pageLimit}&page=${currentPage}&where={"state": ${state} ,"status":["RESERVED","PAID"]
      ${userRole === "staff" ? staffQuery : ""}
      }`;
      const {
        data: { data, metadata },
      } = await axios.get(url);

      await setRecordCount(metadata.pagination.totalCount);
      handlePagination();
      dispatch(SET_APPOINTMENTS_DATA(data));
      const newData = data.map((data: any) => {
        const date = new Date(data.date);
        date.setDate(date.getDate() + 1);
        return {
          ...data,
          date: date.toISOString(),
        };
      });
      const forEvents = newData.map((content: appointmentsType) => {
        const date = new Date(content.date.split("T")[0]);

        date.setDate(date.getDate() + 1);

        return {
          title: `${content.customer.firstName} ${content.customer.lastName}`,
          date: DateTime.fromISO(content.date).toFormat("yyyy-MM-dd"),
          status: content.state,
        };
      });
      setEvents(forEvents);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const handleshowTo = () => {
    if (showFrom + pageLimit > recordCount) {
      // if the request perpage is more than the total number of record return the total number of records instead
      return recordCount;
    } else if (currentPage === 1) {
      return pageLimit;
    } else {
      return showFrom + pageLimit;
    }
  };
  const handleCalenderView = () => {
    try {
      if (window !== undefined) {
        const view = localStorage.getItem("varroe-merchant-calender-view");
        if (view) {
          const parsed = JSON.parse(view);
          const myBusiness = parsed.find(
            (each: { businessName: string; listView: boolean }) =>
              each.businessName === activeBusiness.name
          );
          return !myBusiness.listView;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  };
  const [listview, setListView] = useState(handleCalenderView());

  const toggleView = () => {
    setListView(!listview);
    const view = localStorage.getItem("varroe-merchant-calender-view");
    if (view) {
      const parsed = JSON.parse(view);
      const myBusiness = parsed.find(
        (each: { businessName: string; listView: boolean }) =>
          each.businessName === activeBusiness.name
      );
      const myBusinessIndex = parsed.findIndex(
        (each: { businessName: string; listView: boolean }) =>
          each.businessName === activeBusiness.name
      );
      // if business is found in localstorage
      if (myBusiness) {
        const payload = { ...myBusiness, listView: listview };
        parsed[myBusinessIndex] = payload;
        const stringified = JSON.stringify(parsed);
        localStorage.setItem("varroe-merchant-calender-view", stringified);
      } else {
        const payload = {
          businessName: activeBusiness.name,
          listView: listview,
        };
        parsed.push(payload);
        const stringified = JSON.stringify(parsed);
        localStorage.setItem("varroe-merchant-calender-view", stringified);
      }
    } else {
      localStorage.setItem(
        "varroe-merchant-calender-view",
        JSON.stringify([
          {
            businessName: activeBusiness.name ? activeBusiness.name : "",
            listView: !listview,
          },
        ])
      );
    }
  };
  const handleSearch = (word: string) => {
    setSearch(word);
    refresh();
  };
  const refresh = () => {
    setFetch((prev) => !prev);
  };
  const handleNext = () => {
    if (showTo >= recordCount) return;
    if (currentPage < recordCount) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  // hooks

  useEffect(() => {
    handlePagination();
  }, [recordCount]);

  useEffect(() => {
    getAppointments();
  }, [fetch, currentPage, state]);
  useEffect(() => {
    setShowTo(handleshowTo());
  }, [showFrom, recordCount]);
  const userRole = useAppSelector((state) => state.auth.role);

  return (
    <div className="h-[90vh] flex flex-col space-y-4">
      <PageHeader text={headerText} />
      <div>
        <div className="flex flex-wrap items-center justify-between pt-5 space-y-4 lg:space-y-0">
          <div className="md:w-1/4">
            <SearchComponent search={handleSearch} />
          </div>

          <div className="ml-auto mr-10 flex items-center">
            <div
              className="mr-2 pt-1 cursor-pointer"
              onClick={() => setShowAddAppointment(true)}
            >
              <Icon icon="material-symbols:add" width={40} />
            </div>
            <Select
              options={[
                "Awaiting Approval",
                "Approved Appointments",
                "Cancelled Appointments",
              ]}
              width=" w-[13rem] "
              onChange={(e) => {
                const value = e.target.value;
                if (value === "Approved Appointments") {
                  setState('["APPROVED"]');
                  setHeaderText("Approved Appointments");
                  setShowActions(true);
                } else if (value === "Awaiting Approval") {
                  setState('["PENDING"]');
                  setHeaderText("Pending Appointments");
                  setShowActions(true);
                } else if (value === "Cancelled Appointments") {
                  setState('["CANCELLED"]');
                  setHeaderText("Cancelled Appointments");
                  setShowActions(false);
                }
                setCurrentPage(1);
              }}
            />
          </div>

          <span className="cursor-pointer" onClick={toggleView}>
            <Icon
              icon={`${listview ? "bi:calendar-week" : "bi:list-check"}`}
              width={24}
            />
          </span>
        </div>

        <div className="w-full my-8 h-full overflow-auto">
          {listview ? (
            <AppointmentsTable
              items={appointments}
              toggleAction={userRole !== "staff" && showActions}
              fetching={isLoading}
              refresh={refresh}
              next={handleNext}
              prev={handlePrev}
              showFrom={showFrom}
              totalRecords={recordCount}
              showTo={showTo}
              currentPage={currentPage}
            />
          ) : (
            <Calenderview
              events={events}
              appointments={appointments}
              refresh={refresh}
            />
          )}
        </div>
      </div>

      <AddAppointmentModal
        openCustomise={showAddAppointment}
        handleClose={() => {
          setShowAddAppointment(false);
          refresh();
        }}
      />
    </div>
  );
};

export default Appointments;
