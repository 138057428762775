import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { ServicesSelect } from "../Base/ServicesSelect";
import Spinner from "../Base/Spinner";
import Skeleton from "../Skeleton";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: any;
  title?: string;
  image?: string;
  modalOpen: boolean;
  size?: "large" | "small";
  data?: { [key: string]: any };
  data2?: { [key: string]: any };
  save?: any;
  staffId?: number;
  refresh?: any;
}

interface staffInfo {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  profilePic: string;
  services: [];
}
const EditStaffModal = (props: Props) => {
  const [staffInfo, setStaffInfo] = useState<staffInfo>({
    id: 0,
    firstName: "",
    lastName: "",
    phone: "",
    profilePic: "",
    services: [],
  });
  const [allservices, setAllServices] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [availableServices, setAvailableServices] = useState<any>([]);
  const [services, setServices] = useState([]);
  const [submit, setSubmit] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeletingService, setIsDeletingService] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [fetching, setFetching] = useState(false);
  const [selectedService, setSelectedService] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const saveEdit = async () => {
    setSubmit(true);
    try {
      await axios.put(`/api/staff/${staffInfo.id}`, {
        firstName,
        lastName,
        phone,
      });
      setSubmit(false);
      props.closeModal();
      props.refresh();
    } catch (err) {
      setSubmit(false);
      console.error(err);
    }
  };

  const getAllServices = async () => {
    try {
      const response = await axios.get(`/api/business/service`);
      setAllServices(response.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getStaff = async () => {
    try {
      // setFetching(true);
      const response = await axios.get(`/api/staff/${props.staffId}`);

      if (response) {
        setStaffInfo(response.data.data);
        setFirstName(response.data.data.firstName);
        setLastName(response.data.data.lastName);
        setPhone(response.data.data.phone);
        setServices(response.data.data.services);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetching(false);
    }
  };

  const addService = async (id: number) => {
    try {
      setIsAdding(true);
      await axios.put(`/api/staff/${staffInfo.id}/service/${id}`);
      setRefresh(!refresh);
    } catch (e) {
      console.error(e);
    } finally {
      setIsAdding(false);
    }
  };

  const deleteItem = async (id: number, index: number) => {
    try {
      setIsDeletingService(true);

      await axios.delete(`/api/staff/${staffInfo.id}/service/${id}`);

      setRefresh(!refresh);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDeletingService(false);
    }
  };

  useEffect(() => {
    getStaff();
    getAllServices();
  }, [refresh]);
  useEffect(() => {
    const staffServices = staffInfo.services.map((staff: any) => staff.service);
    const results: any = allservices.filter(
      (service: any) =>
        !staffServices.some(
          (staffService: any) => service?.id === staffService?.id
        )
    );
    setAvailableServices(results);
    if (results.length) {
      setSelectedService(results[0].id);
    }
  }, [staffInfo, allservices]);

  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="large"
    >
      <div className="max-h-[550px]">
        <div className="mt-3 sm:mt-0 sm:text-left">
          {!fetching && staffInfo ? (
            <div className="flex flex-row justify-between sm:flex-row">
              <div className="flex w-24 h-64 mb-4 md:mb-0 md:w-48 sm:justify-start">
                {!!staffInfo.profilePic && (
                  <img
                    src={staffInfo.profilePic}
                    alt="staff"
                    className="object-cover rounded-lg"
                  />
                )}
              </div>
              <div className="w-64 h-full xs:w-72 md:w-4/6">
                <div className="p-2 sm:p-3 md:p-5 bg-[#f1f1f1] rounded-lg mr-3 sm:mr-0 flex items-center justify-center sm:justify-start">
                  <form className="w-full overflow-auto ">
                    <div className="pb-5">
                      <div className="space-y-4">
                        <div className="flex justify-between">
                          <div className="md:w-[46%]">
                            <Input
                              label="First Name"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                            />
                          </div>
                          <div className="md:w-[46%]">
                            <Input
                              label="Last Name"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <Input
                            label="Phone Number"
                            type="text"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className=" max-h-64">
                        <div className="flex pt-10">
                          <p className="text-xs font-semibold">
                            Services offered
                          </p>
                        </div>

                        <div>
                          <div className="flex items-center justify-between w-full py-1">
                            <div className="w-96">
                              <ServicesSelect
                                options={availableServices}
                                onChange={(e) => {
                                  setSelectedService(parseInt(e.target.value));
                                }}
                              />
                            </div>
                            <div
                              className="flex items-center justify-center px-3 py-3 mt-2  ml-3 text-white rounded-md cursor-pointer bg-blue-900"
                              onClick={() => {
                                if (isAdding) return;
                                addService(selectedService);
                              }}
                            >
                              {isAdding ? (
                                <Spinner padding={false} />
                              ) : (
                                <Icon icon="akar-icons:plus" width="12" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap ">
                          {!!services.length &&
                            services.map((service: any, index: number) => (
                              <div
                                key={index}
                                className="p-2 md:px-4 md:py-2  my-2 bg-[#579AF5] text-white rounded-3xl mr-3 flex items-center w-fit"
                              >
                                <>
                                  {service?.service?.id && (
                                    <>
                                      <p className="text-xs capitalize ">
                                        {service?.service.label}
                                      </p>

                                      <div
                                        className={
                                          !isDeletingService
                                            ? "cursor-pointer"
                                            : "cursor-progress" +
                                              " font-extrabold "
                                        }
                                        onClick={async () => {
                                          if (isDeletingService) return;
                                          await deleteItem(
                                            service.service.id,
                                            index
                                          );
                                        }}
                                      >
                                        <Icon icon="bi:x" width={20} />
                                      </div>
                                    </>
                                  )}
                                </>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton />
          )}
          <div className="flex mt-4">
            <Button onClick={saveEdit} isSubmitting={submit} disabled={submit}>
              {submit ? "Saving..." : "Save"}
            </Button>
            <Button
              variant="bg-danger ml-4"
              onClick={() => {
                props.closeModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditStaffModal;
