import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { DateTime } from "ts-luxon";
import AppointmentList from "./Modals/AppointmentListModal";
import AppointmentModal from "./Modals/AppointmentModal";
import DeleteModal from "./Modals/DeleteModal";
import { appointmentsType, eventsProp } from "../interfaces/appointments";
import axios from "axios";

function renderEventContent(eventInfo: any) {
  return (
    <>
      <div className="flex items-center space-x-1 flex-wrap">
        <div
          className={
            "h-2 w-2 rounded-full" +
            (eventInfo.event.extendedProps.status === "PENDING"
              ? " bg-yellow-300 "
              : eventInfo.event.extendedProps.status === "CANCELLED"
              ? " bg-red-500 "
              : " bg-green-500 ")
          }
        ></div>
        <span>{eventInfo.event.title}</span>
      </div>
    </>
  );
}
const Calenderview = (props: eventsProp) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [appointmentModalProps, setAppointmentModalProps] =
    useState<appointmentsType>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState<appointmentsType>();

  const [selectedDate, setSelectedDate] = useState("");
  const [modalProps, setModalProps] = useState<appointmentsType[]>([]);

  const eventhandler = (clickInfo: EventClickArg) => {
    const formattedDate = DateTime.fromISO(clickInfo.event.startStr).toFormat(
      "DDDD"
    );
    setSelectedDate(formattedDate);
    const makeModalProps = props.appointments.filter(
      (appointment) =>
        DateTime.fromISO(appointment.date).toFormat("yyyy-MM-dd") ===
        clickInfo.event.startStr
    );
    setModalProps(makeModalProps);
    setModalOpen(true);
  };
  const handleClick = (e: any) => {
    // console.log(e)
  };
  const handleDelete = async () => {
    // if you click on yes
    try {
      await axios.patch(`/api/appointment/${deleteModalProps?.id}/handle`, {
        accepted: false,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setModalOpen(!modalOpen);
      setDeleteModalOpen(false);
    }
  };
  return (
    <div className=" h-[73vh] overflow-auto">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        fixedWeekCount={false}
        firstDay={1}
        eventClick={eventhandler}
        dateClick={handleClick}
        eventContent={renderEventContent}
        events={props.events}
        showNonCurrentDates={false}
        height="auto"
        headerToolbar={{
          left: "title  prev,next",
          right: "today",
        }}
      />

      <AppointmentList
        appointments={modalProps}
        modalOpen={modalOpen}
        selectedDate={selectedDate}
        closeModal={() => {
          setModalOpen(!modalOpen);
          setAppointmentModalOpen(false);
        }}
        openOtherModal={(appointment: appointmentsType) => {
          setAppointmentModalProps(appointment);
          setAppointmentModalOpen(true);
          setModalOpen(false);
        }}
      />
      <AppointmentModal
        modalOpen={appointmentModalOpen}
        appointmentModalProps={appointmentModalProps}
        closeModal={() => setAppointmentModalOpen(false)}
        openOtherModal={(appointmentModalProps: appointmentsType) => {
          setDeleteModalProps(appointmentModalProps);
          setDeleteModalOpen(true);
          setAppointmentModalOpen(false);
        }}
        refresh={() => {
          props.refresh();
        }}
      />
      <DeleteModal
        modalOpen={deleteModalOpen}
        closeModal={async (value: boolean) => {
          // if you click on yes
          if (value) {
            try {
              await axios.patch(
                `/api/appointment/${deleteModalProps?.id}/handle`,
                {
                  accepted: false,
                }
              );
            } catch (e) {
              console.error(e);
            } finally {
              setModalOpen(!modalOpen);
              setDeleteModalOpen(false);
            }
          } else {
            setModalOpen(!modalOpen);
            setDeleteModalOpen(false);
          }
        }}
        refresh={() => {
          props.refresh();
        }}
        delete={handleDelete}
        title={
          deleteModalProps?.customer.firstName +
          " " +
          deleteModalProps?.customer.lastName +
          "'s " +
          deleteModalProps?.service.name +
          " Appointment"
        }
        type="Appointment"
        size="small"
      />
    </div>
  );
};

export default Calenderview;
