import axios from "axios";
import { fileType } from "../interfaces/resourceUpload";
const FileUpload = () => {
  const submitFile = async (file: any, type: fileType = "gallery") => {
    try {
      const fromData = new FormData();
      fromData.append("file", file);
      fromData.append("type", type);
      const {
        data: { data },
      } = await axios.post("/api/file/upload", fromData);
      return data.location;
    } catch (e) {
      console.error(e);
    }
  };
  return { submitFile };
};
export default FileUpload;
