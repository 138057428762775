import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PageHeader } from "../../components/Base/PageHeader";
import VarroeLogo from "../../assets/VarroeLogo.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";
import { IoGiftOutline } from "react-icons/io5";
import { HiOutlineTicket } from "react-icons/hi";

const LoyaltyProgram = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);
  const [showPopup, setShowPopup] = useState(false); // State to control the popup visibility

  const getProgramPoints = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`); // API endpoint to fetch points
      dispatch(SET_LOYALTY_POINT(data));
      console.log(loyaltyPoints);
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error("Error fetching points:", e);
    }
  };
  useEffect(() => {
    getProgramPoints();
  }, []);

  return (
    <div className="h-screen w-full">
      <div className="h-screen flex flex-col gap-12">
        {/* Flex container to align header text and arrow in one line */}
        <div className="flex items-center px-6 py-4">
          {/* Back Arrow */}
          <span
            className="text-2xl font-bold cursor-pointer mr-2"
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            ⬅ {/* Thicker left arrow character */}
          </span>

          {/* Header Text */}
          <PageHeader text="Decide How You Want to Redeem" />
        </div>

        {/* Information Section with Points and Logo */}
        <div className="flex justify-center items-center gap-8">
          <div className="flex  w-[150px] h-[60px] text-base font-bold text-center items-center justify-center">
            1 Booking = 50Vp
          </div>
          <img src={VarroeLogo} alt="Varroe Logo" width={80} />
          <div className="flex  w-[150px] h-[60px] font-bold text-center items-center justify-center">
            1,000Vp = $100
          </div>
        </div>

        {/* Redemption Options */}
        <div className="flex justify-center gap-20 mt-10">
          {/* Redeem as Coupon */}
          <Link
            to={loyaltyPoints >= 1000 ? "voucher" : "#"}
            onClick={(e) => {
              if (loyaltyPoints < 1000) {
                e.preventDefault();
                setShowPopup(true);
              }
            }}
          >
            <div
              className="h-[280px] w-[280px] shadow-xl flex flex-col items-center justify-center text-center cursor-pointer"
              style={{ backgroundColor: "#7f8da0" }}
            >
              {/* Icon for Coupon */}
              <HiOutlineTicket className="text-white text-9xl mb-7" />
              <span className="text-lg font-bold text-black">COUPON</span>
            </div>
          </Link>

          {/* Redeem as Gift Card */}
          <Link
            to={loyaltyPoints >= 1000 ? "gift" : "#"}
            onClick={(e) => {
              if (loyaltyPoints < 1000) {
                e.preventDefault();
                setShowPopup(true);
              }
            }}
          >
            <div
              className="h-[280px] w-[280px] shadow-xl flex flex-col items-center justify-center text-center cursor-pointer"
              style={{ backgroundColor: "#7f8da0" }}
            >
              {/* Icon for Gift Card */}
              <IoGiftOutline className="text-white text-9xl mb-7" />
              <span className="text-lg font-bold text-black">GIFT CARD</span>
            </div>
          </Link>
        </div>

        {/* Popup Message */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div
              className="bg-white p-6 rounded-lg shadow-lg text-center"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "1000",
              }}
            >
              <p className="text-xl font-bold mb-4">
                You need a thousand points to redeem
              </p>
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2  text-white rounded-lg bg-[#7C8CE4]"
              >
                OK
              </button>
            </div>
          </div>
        )}

        {/* <div className="w-[670px] h-[109px] bg-[#D5D2F5] flex items-center justify-between rounded-2xl p-6 text-xl">
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1 Booking = 50Vp
            </div>
            <img src={VarroeLogo} alt="background" width={80} />
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1,000Vp = $100
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default LoyaltyProgram;
