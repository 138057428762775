import axios from "axios";
import React, { useState } from "react";
import OtpInput from "react18-input-otp";
import { useAppDispatch } from "../../store/hooks";
import { Button } from "../Base/Button";
import { SET_USER_ROLE } from "../../features/auth/auth";
import { useNavigate } from "react-router-dom";

const Link = () => {
  const [input, setinput] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleChange = (value: any) => setinput(value.toUpperCase());
  const handleProceed = async () => {
    try {
      const {
        data: { data },
      } = await axios.put(`/api/verification/staff`, {
        code: input,
      });
      await dispatch(SET_USER_ROLE(data.role));
      await putBusinessInStore(data.businessId);
      navigate("/dashboard");
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  };
  const putBusinessInStore = async (payload: number) => {
    try {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: { data },
      } = await axios.get(`/api/business/${payload}`);
    } catch (e) {
      console.error(e);
    } finally {
      // doafter
    }
  };

  return (
    <div>
      <p className="my-8 text-xs text-center text-white">
        Enter your business code to join as a staff
      </p>
      <div className="flex justify-center my-6">
        <OtpInput
          className="px-4 py-5 ml-4 font-semibold text-center rounded-md bg-white focus:outline-0 focus:border-blue-300"
          value={input}
          onChange={handleChange}
          numInputs={7}
          shouldAutoFocus={true}
          placeholder={"VA000000"}
          inputStyle={{
            outlineWidth: "0px",
            border: "none",
            focus: "none",
          }}
        />
      </div>

      <div className="flex justify-center mt-10">
        <Button variant="bg-primary" onClick={() => handleProceed()}>
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default Link;
