import axios from "axios";
import { useEffect, useState } from "react";
import { PageHeader } from "../components/Base/PageHeader";
import ScheduleTable from "../components/ScheduleTable";
import { selectSchedules, SET_SCHEDULES } from "../features/pages/pages";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const Schedule = () => {
  const storeSchedules = useAppSelector(selectSchedules);
  const dispatch = useAppDispatch();
  const [fetch, setFetching] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const getSchedule = async () => {
    !storeSchedules.length && setFetching(true);
    try {
      const {
        data: { data },
      } = await axios.get(`/api/staff/schedule`);
      dispatch(SET_SCHEDULES(data));
      console.log(data);
    } catch (e) {
      console.error(e);
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    getSchedule();
  }, [refreshPage]);
  return (
    <div>
      <PageHeader text="Schedule" />
      <ScheduleTable
        items={storeSchedules}
        fetching={fetch}
        refresh={() => setRefreshPage((previous) => !previous)}
      />
    </div>
  );
};

export default Schedule;
