import axios from "axios";
import { useEffect, useState } from "react";
import { CustomisationType } from "../../interfaces/business";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { Select } from "../Base/Select";
import CustomizationScheduleList from "../CustomizationScheduleList";
import OverlaySpinner from "../OverlaySpinner";

const BusinessCustomization = () => {
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState<CustomisationType>();
  const [initialAllData, setInitialAllData] = useState<CustomisationType>();
  const [accent, setAccent] = useState("");
  const [initialAccent, setInitialAccent] = useState("");
  const [minUnit, setMinUnit] = useState("");
  const [initialMinUnit, setInitialMinUnit] = useState("");
  const [minValue, setMinValue] = useState<number>(0);
  const [initialMinValue, setInitialMinValue] = useState<number>(0);
  const [recurringOptions, setRecurringOptions] = useState<string[]>([]);
  const [initialRecurringOptions, setInitialRecurringOptions] = useState<
    string[]
  >([]);
  const [duration, setDuration] = useState<number>(0);
  const [initialDuration, setInitialDuration] = useState<number>(0);

  const recurringBookingOptions = ["DAILY", "WEEKLY", "MONTHLY"];

  const setValues = (data: any) => {
    setAllData(data);
    setInitialAllData(data);
    setInitialAccent(data.value.accentColor);
    setAccent(data.value.accentColor);
    setMinUnit(data.value.minTimeToBook.unit);
    setInitialMinUnit(data.value.minTimeToBook.unit);
    setMinValue(data.value.minTimeToBook.value);
    setInitialMinValue(data.value.minTimeToBook.value);
    setDuration(data.value.timeSlotInterval);
    setInitialDuration(data.value.timeSlotInterval);
    setRecurringOptions(data.value.recurringOptions || []);
    setInitialRecurringOptions(data.value.recurringOptions || []);
  };

  const getCustomizationParameter = async () => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await axios.get(`/api/business/parameter/customisation`);
      setValues(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleReccuringOption = (option: string) => {
    const index = recurringOptions.findIndex((_option) => option === _option);
    if (index > -1) {
      const updatedOptions = [...recurringOptions];
      updatedOptions.splice(index, 1);
      setRecurringOptions(updatedOptions);
    } else {
      const updatedOptions = [...recurringOptions];
      updatedOptions.push(option);
      setRecurringOptions(updatedOptions);
    }
  };

  useEffect(() => {
    getCustomizationParameter();
  }, []);

  const handleUpdateButton = () => {
    const list = [
      accent === initialAccent,
      minUnit === initialMinUnit,
      minValue === initialMinValue,
      duration === initialDuration,
      recurringOptions === initialRecurringOptions,
    ];
    if (list.some((value) => !value)) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  };

  useEffect(() => {
    handleUpdateButton();
  }, [allData, initialAllData, accent, minUnit, minValue, duration]);

  const handleUpdate = (payload: any, index: number) => {
    if (allData) {
      allData.value.schedule[index] = {
        ...allData.value.schedule[index],
        active: payload.open,
        start: payload.start,
        end: payload.end,
      };
      setAllData({
        ...allData,
        value: {
          ...allData.value,
          schedule: allData.value.schedule.map((el, i) =>
            i === index
              ? {
                  ...el,
                  start: payload.start,
                  end: payload.end,
                  active: payload.open,
                }
              : el
          ),
        },
      });
    }
  };

  useEffect(() => {}, [allData]);

  const submitForm = async () => {
    try {
      setIsLoading(true);
      if (allData) {
        const payload = {
          ...allData.value,
          recurringOptions,
          accentColor: accent,
          minTimeToBook: {
            unit: minUnit,
            value: minValue,
          },
          timeSlotInterval: duration,
        };
        await axios.put(`/api/business/parameter/customisation`, payload);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center ">
      <div className="w-11/12 p-6 my-10 overflow-y-auto bg-white md:w-8/12 lg:p-8 dropshadow rounded-xl">
        <OverlaySpinner isLoading={isLoading}>
          <form className="lg:space-y-5 font-roboto">
            <div className="flex flex-col justify-between space-y-4 lg:flex-row lg:space-y-0">
              <div className="w-1/2 py-2 sm:py-0  flex  items-center ">
                <Input
                  label="Accent Color"
                  value={accent}
                  type="color"
                  onChange={(e) => {
                    setAccent(e.target.value);
                  }}
                  placeholder="Blue"
                />
                <div
                  className={`flex flex-col mt-6 rounded-lg ml-3 w-8 h-7`}
                  style={{ backgroundColor: accent }}
                ></div>
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm font-semibold">
                Recurring Booking Options -{" "}
                <span className="text-xs font-normal">
                  Indicate the booking frequency acceptable for your business
                </span>
              </p>
              <div className="flex items-center space-x-4">
                {recurringBookingOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => toggleReccuringOption(option)}
                    className={`rounded-lg text-white text-sm p-1.5 px-4 cursor-pointer ${
                      recurringOptions.includes(option)
                        ? "bg-primary"
                        : "bg-basegrey text-gray-500"
                    } `}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-between space-y-4 lg:flex-row lg:space-y-0">
              <div className="lg:w-5/12">
                <p className=" text-[10px] text-left text-black">
                  Minimum time before appointment
                </p>

                <div className="flex w-full space-x-3">
                  <div className="w-14 sm:py-0">
                    <Input
                      placeholder="24"
                      type="number"
                      value={minValue}
                      onChange={(e) => {
                        setMinValue(parseInt(e.target.value));
                      }}
                    />
                  </div>
                  <div className="w-[60%]">
                    <Select
                      value={minUnit}
                      options={[
                        { text: "Minutes", value: "minutes" },
                        { text: "Hours", value: "hours" },
                        { text: "Days", value: "days" },
                      ]}
                      onChange={(e) => {
                        setMinUnit(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full py-2 sm:py-0 lg:w-6/12">
                <Input
                  label="Appointments slot duration (minutes)"
                  type="number"
                  placeholder="15"
                  value={duration}
                  onChange={(e) => {
                    setDuration(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>

            <p className="pt-5 text-xs font-semibold">Business Time Schedule</p>

            <div className="space-y-4 overflow-auto ">
              {allData &&
                allData.value.schedule.map((schedule, scheduleIndex) => (
                  <CustomizationScheduleList
                    schedule={schedule}
                    key={scheduleIndex}
                    update={(e) => {
                      handleUpdate(e, scheduleIndex);
                    }}
                  />
                ))}
            </div>
            <Button
              onClick={submitForm}
              auth={update ? " bg-main text-white " : " bg-gray-200  "}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
          </form>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default BusinessCustomization;
