import { Icon } from "@iconify/react";
import React from "react";
import { debounce } from "lodash";
interface Props {
  search: any;
  crm?: boolean;
}

const SearchComponent = (props: Props) => {
  // const debounceSearch = (word:string)=>{
  //   props.search(word)
  // }
  const debounceSearch = React.useRef(
    debounce(async (word: string) => {
      await props.search(word);
    }, 1000)
  ).current;
  return (
    <div className="w-full">
      <div className="relative ">
        {!props.crm && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Icon icon="ant-design:search-outlined" />
          </div>
        )}

        <input
          type="search"
          className={
            "block w-full px-4 py-2   border rounded-lg text-dark border-darkergrey  focus:outline-0 focus:border-blue-300" +
            (props.crm
              ? " placeholder-[#6F6F6F] pl-5 "
              : "  bg-[#F0F0F0] placeholder-gray-400 text-xs font-semibold pl-10  ")
          }
          placeholder="Search"
          onChange={(event) => {
            debounceSearch(event.target.value);
          }}
          required
        />
      </div>
    </div>
  );
};

export default SearchComponent;
