import React from "react";

interface TextAreaProps {
  placeholder?: string;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
  cols?: number;
  value?: string;
  name?: string;
}

export const Textarea: React.FC<TextAreaProps> = ({
  label,
  placeholder = label,
  onChange,
  disabled,
  required,
  rows,
  cols,
  value,
  name,
}) => {
  return (
    <>
      <div className="flex flex-col">
        <label className="mb-2 text-xs text-left text-black">{label}</label>
        <textarea
          className="w-auto h-auto px-3 py-2 text-sm border border-solid rounded-md placeholder:text-gray-400 border-darkergrey bg-lightergrey focus:outline-0 focus:border-blue-300"
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          required={required}
          rows={rows}
          cols={cols}
          name={name}
          value={value}
        />
      </div>
    </>
  );
};
