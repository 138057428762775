import React from "react";
import MerchantsLogo from "../assets/images/merchantslogo.svg";

function ScreenLimiter() {
  return (
    <div className="h-screen w-screen bg-main lg:hidden lg:invisible">
      <div className="authlayoutbg">
        <div className="h-full flex items-center max-w-sm mx-auto px-6">
          <div className="flex flex-col items-center space-y-6">
            <img
              src={MerchantsLogo}
              alt="varroe-merchants-logo"
              className="w-32"
            />
            <div className="text-center text-white space-y-2">
              <p>You will need a larger screen.</p>
              <p>The contents of this application will require a larger screen for the best user experience.</p>
              <p className=" text-xs">
                Please use a device with a larger screen to get started.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreenLimiter;
