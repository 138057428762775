import React from "react";

interface CheckBoxProps {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  className?: string;
  id?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  onChange,
  disabled,
  name,
  className = "text-[10px]",
  id,
}) => {
  return (
    <>
      <div className="flex items-center space-x-2 customcheckbox">
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          disabled={disabled}
          id={id}
        />
        <label htmlFor={id} className={`${className} cursor-pointer`}>
          {label}
        </label>
      </div>
    </>
  );
};
