import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 const toastOptions = {
    autoClose: 6000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
 };
type ToastVariant = 'info' | 'success' | 'warning' | 'error' 
export const Toast = (text: string, type:ToastVariant = 'success') => {
   return toast[type](text, toastOptions); 
    }
export default ToastContainer