import React, { useState } from "react";

interface options {
  text: string;
  value: number | string;
}
interface SelectProps {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  required?: boolean;
  showSelect?: boolean;
  options: options[] | any;
  // remove any after refactoring the business creation pages
  width?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string;
}

export const Select: React.FC<SelectProps> = ({
  label,
  onChange,
  disabled,
  required,
  options,
  name,
  showSelect,
  defaultValue,
  value,
  width,
}) => {
  const [disable, setdisable] = useState(false);
  return (
    <>
      <div className="flex flex-col">
        <label className="mb-2 text-sm text-left">{label}</label>
        <div className="">
          <select
            name={name}
            required={required}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={(e) => {
              setdisable(true);
              onChange && onChange(e);
            }}
            value={value}
            className={
              `w-full cursor-pointer appearance-none text-sm py-2 truncate px-3 rounded-md text-black h-auto border border-solid border-darkergrey bg-lightergrey focus:outline-0  focus:border-blue-300 ` +
              width
            }
          >
            {!!showSelect && <option disabled={disable}>Select</option>}
            {options.map((value: any, index: number) => (
              <option value={value.value ? value.value : value} key={index}>
                {value.text ? value.text : value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
