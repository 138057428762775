import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MerchantsLogo from '../../assets/images/merchantslogo.svg';
import FreelancerLogo from '../../assets/images/freelancerlogo.svg';
import { BusinessTypeContext } from '../../context/businessType';

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = (props: Props) => {
  const [businessType, setBusinessType] = useState('');

  return (
    <div className="relative z-50 flex flex-col items-center h-screen m-auto md:justify-center md:flex-row bg-main authlayoutbg">
      <div className="mb-24 mt-14 md:mt-0 md:mb-0">
        <Link
          to={'/auth/login'}
          className="flex flex-col justify-center w-20 mx-auto h-6/12 md:h-screen md:w-44 xl:w-2/5 align-center"
        >
          <img
            src={businessType === 'freelancer' ? FreelancerLogo : MerchantsLogo}
            alt="background"
          />
        </Link>
      </div>
      <div className="flex items-center w-screen h-screen md:mt-8">
        <div className="flex flex-col justify-end w-10/12 mx-auto sm:w-8/12">
          <div className="bg-white rounded-md sm:p-2">
            <BusinessTypeContext.Provider
              value={{ businessType, setBusinessType }}
            >
              {props.children}
            </BusinessTypeContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
