import React, { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import locationIcon from "../assets/images/google_location.svg";
interface Props {
  selected: (e: any) => void;
  address: string;
}
export default function GoogleSearch({ selected, address }: Props) {
  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    onPlaceSelected: (place: any) => {
      setValue(place.formatted_address);
      selected(place);
    },
    options: {
      types: ["address"],
      fields: ["all"],
    },
  });
  useEffect(() => {
    if (address === "deletethefield") setValue("");
    else {
      setValue(address);
    }
  }, [address]);
  const [value, setValue] = useState("");
  return (
    <div className="flex space-x-3 rounded-full items-center bg-[#251D2A] h-full w-full p-3">
      <div className="flex items-center">
        <span className="text-white flex">
          <img src={locationIcon} alt="map" height={30} width={30} />
        </span>
      </div>

      <input
        ref={ref}
        className=" focus:outline-none placeholder-grey-300"
        placeholder="Search for street "
        style={{ width: "90%", background: "inherit", color: "white" }}
        defaultValue="Amsterdam"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      />
    </div>
  );
}
