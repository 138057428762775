/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "@iconify/react";
import React, { Key, useState } from "react";
import { InputValidationError } from "../InputValidationError";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { RadioInput } from "../Base/RadioInput";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Textarea } from "../Base/Textarea";
import ServiceImage from "../ServiceImage";
import Tabs from "../ServiceTabs/Tabs";
import ModalContainer from "./ModalContainer";
import axios from "axios";
import useFileUpload from "../../hooks/useFileUpload";
import Spinner from "../Base/Spinner";
import { useAppSelector } from "../../store/hooks";
import { getBusinessCurrency } from "../../features/business/business";
import { ICRMClient } from "../../interfaces/crm";
import { Toast } from "../Base/toast";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  clients: ICRMClient[];
}
const CampaignModal = (props: Props) => {
  const { clients } = props;
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const headerMessage =
    clients.length === 1
      ? `Send a message to ${clients[0].customer.firstName} ${clients[0].customer.lastName}`
      : "Send a message to your clients";

  const sendCampaign = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.post(`/api/crm/mail`, {
        customerIds: clients.map((c) => c.customer.id),
        subject,
        content,
      });
      Toast("Campaign sent successfully", "success");
      props.closeModal();
      setSubject("");
      setContent("");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="small"
    >
      <div className="space-y-3">
        <p className="text-lg font-semibold">{headerMessage}</p>
        <form onSubmit={sendCampaign} className="space-y-4">
          <Input
            label="Campaign Subject"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            required
            bottomSpace={false}
          />
          <Textarea
            rows={10}
            label="Campaign Message"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <Button type={"submit"} isSubmitting={loading}>
            Send Message
          </Button>
        </form>
      </div>
    </ModalContainer>
  );
};

export default CampaignModal;
