import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import ModalContainer from "../Modals/ModalContainer";
import { Progress } from "flowbite-react";
import PlanHeader from "../PlanHeader";
import OverlaySpinner from "../OverlaySpinner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getactiveBusiness,
  selectBusiness,
} from "../../features/business/business";

import { DateTime } from "ts-luxon";
import { Button } from "../Base/Button";
import Toggle from "../Toggle";
import { Toast } from "../Base/toast";
interface statsType {
  bookingsCount: number;
  reviewsCount: number;
  staffProfiles: {
    total: number;
    used: number;
  };
}
const Subscription = () => {
  const [plans, setPlans] = useState<any>([]);
  const [resources, setResources] = useState<any>([]);
  const [customStaff, setCustomStaff] = useState(4);
  const [customPrice, setCustomPrice] = useState(0);
  const [customYearPrice, setCustomYearPrice] = useState(0);
  const [showCustomFee, setShowCustomFee] = useState(false);
  const [disabledCount, setDisableCount] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [customResources, setCustomResources] = useState<any[]>([
    { resource: { name: "Bookings and Appointments", id: 1 } },
    { resource: { name: "Commentary and Review Management", id: 3 } },
  ]);
  const [stats, setStats] = useState<statsType>({
    bookingsCount: 0,
    reviewsCount: 0,
    staffProfiles: {
      total: 0,
      used: 0,
    },
  });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const activeBusiness = useAppSelector(selectBusiness);
  const [isDeleting, setIsDeleting] = useState(false);
  const [period, setPeriod] = useState<"yearly" | "monthly">("monthly");
  const getPlans = async () => {
    try {
      setIsloading(true);
      const endpoints = [
        `/api/subscription/plans`,
        `/api/subscription/resources`,
        `/api/analytics/plan-stats`,
      ];
      const [plans, resources, stats] = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint))
      );
      if (plans.data) {
        setPlans(plans.data.data);
      }
      if (resources.data) {
        setResources(resources.data.data);
      }
      if (stats.data) {
        setStats(stats.data.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const formattedEndDate = () => {
    if (activeBusiness.subscription) {
      const newDate = DateTime.fromISO(
        activeBusiness.subscription.end
      ).toFormat("DDD");
      return newDate;
    } else {
      return " jj";
    }
  };
  useEffect(() => {
    getPlans();
    dispatch(getactiveBusiness());
  }, []);
  useEffect(() => {}, [customResources]);
  const handleCheck = (plan: any, resource: any) => {
    const formattedPlan = plan.resources.map((plan: any) => plan.resource.name);
    if (formattedPlan.includes(resource.name)) {
      return true;
    } else {
      return false;
    }
  };
  const handleCustom = (resource: any, checked: boolean) => {
    const price = parseFloat(parseFloat(resource.pricePerMonth).toFixed(2));
    const annaulPrice = parseFloat(
      parseFloat(resource.pricePerYear).toFixed(2)
    );
    if (checked) {
      setCustomPrice(customPrice + price);
      setCustomYearPrice(customYearPrice + annaulPrice);
      setCustomResources([
        ...customResources,
        { resource: { name: resource.name, id: resource.id } },
      ]);
      if (resource.name === "Online Payment Accessibility") {
        setShowCustomFee(true);
      }
    } else {
      setCustomPrice(customPrice - price);
      setCustomYearPrice(customYearPrice - annaulPrice);

      setCustomResources((prev) =>
        prev.filter((item) => item.resource.name !== resource.name)
      );
      if (resource.name === "Online Payment Accessibility") {
        setShowCustomFee(false);
      }
    }
  };
  const handleStaffProfile = (checked: boolean) => {
    if (checked) {
      setCustomResources([
        ...customResources,
        { resource: { name: "Staff Profiles" } },
      ]);

      if (customStaff) {
        setCustomPrice(customPrice + customStaff * 4.5);
        setCustomYearPrice(customYearPrice + customStaff * 50);
      }
      setDisableCount(true);
    } else {
      setCustomResources((prev) =>
        prev.filter((item) => item.resource.name !== "Staff Profiles")
      );

      if (customStaff > 0) {
        setCustomPrice(customPrice - customStaff * 4.5);
        setCustomYearPrice(customYearPrice - customStaff * 50);
      }
      setDisableCount(false);
    }
  };
  const handleCancel = () => {
    setShowCancelModal(true);
  };
  const closeModal = () => {
    setShowCancelModal(false);
  };
  const dispatch = useAppDispatch();

  return (
    <OverlaySpinner isLoading={isloading}>
      <div className="w-full subscriptionFont my-8 overflow-x-auto ">
        <div className="flex justify-between">
          <div className="bg-main duration-500  rounded-lg w-[65%]  mr-5 p-5 text-white">
            <div className="text-2xl font-normal"> Active Subscription</div>
            <div className="text-5xl mt-4 font-semibold text-uppercase">
              {" "}
              {activeBusiness?.subscription?.plan.name}
            </div>
            {activeBusiness?.subscription?.plan.name !== "Free" && (
              <div className="flex items-end justify-between  min-h-[50px]">
                <div>
                  {activeBusiness?.subscription?.reccuring
                    ? "Renews "
                    : "Expires "}
                  on the {formattedEndDate()}
                </div>
                {/* hide cancel button for custom plans  */}
                {activeBusiness?.subscription?.plan.key !== "Custom" && (
                  <div>
                    <button
                      className="bg-red-700 py-3 px-5 rounded-lg"
                      onClick={handleCancel}
                    >
                      <span className="font-bold">Cancel Plan</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="bg-main duration-500 interFamily rounded-lg w-[35%]   p-5 text-white">
            <div className="text-xl font-normal subscriptionFont">
              {" "}
              Plan Stats
            </div>
            <div className="flex items-end justify-between mt-3 interFamily">
              <div className="flex flex-col">
                <span className="text-sm">Bookings</span>
                <span className="font-bold text-xl">{stats.bookingsCount}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">Reviews</span>
                <span className="font-bold text-xl">{stats.reviewsCount}</span>
              </div>
            </div>
            <div>
              <span className="text-sm ">Staff Profiles</span>
            </div>
            <div className="flex flex-row items-center mt-2">
              <div className=" w-[30%]">
                <span className="font-semibold">
                  {stats.staffProfiles.used}/{stats.staffProfiles.total} used
                </span>
              </div>
              <div className=" w-[70%]">
                <Progress
                  progress={
                    (stats.staffProfiles.used / stats.staffProfiles.total) * 100
                  }
                  size="md"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 overflow-x-auto border-solid rounded-lg border border-slate-300">
          <div className=" relative">
            {isloading ? (
              <div className="flex justify-center items-center h-[300px]"></div>
            ) : (
              <table className="w-full bg-white text-sm text-left">
                <thead className=" text-black">
                  <tr className=" border-b  border-slate-400 border-dashed">
                    <th scope="col" className="py-4 pl-6">
                      <div className="min-h-[140px]">
                        <p className="text-[1.15rem] font-[600] pt-1 interFamily ">
                          Billing Cycle
                        </p>
                        <div className=" mt-[4.7rem] w-[200px] flex justify-start">
                          <Toggle
                            toggle={(e: string) => {
                              if (e === "monthly") {
                                setPeriod("monthly");
                              } else if (e === "yearly") {
                                setPeriod("yearly");
                              }
                            }}
                          />
                        </div>
                      </div>
                    </th>

                    {plans.map((plan: any, planIndex: number) => (
                      <th key={planIndex} scope="col" className="py-3 px-6">
                        <PlanHeader
                          plan={plan}
                          showFee={true}
                          period={period}
                        />
                      </th>
                    ))}
                    <th scope="col" className="py-3 px-6">
                      <PlanHeader
                        plan={{
                          name: "Custom",
                          pricePerMonth: customPrice,
                          pricePerYear: customYearPrice,
                          fee: "4.5",
                          resources: customResources,
                          id: 5,
                        }}
                        showFee={showCustomFee}
                        StaffCount={customStaff}
                        period={period}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resources.map(
                    (
                      resource: { key: string; name: string },
                      resourceIndex: number
                    ) => (
                      <tr key={resourceIndex} className="bg-white border-b">
                        <th className="py-4   px-5 font-medium text-gray-900 dark:text-white">
                          <div className="underline  w-[120px] decoration-dashed break-words ">
                            {resource.name}
                          </div>
                        </th>
                        {plans.map((plan: any, planIndex: number) => (
                          <td key={planIndex} className="py-4  px-6 mx-10">
                            {resource.name === "Staff Profiles" ? (
                              <div className="p-0 m-0 text-center flex justify-center ">
                                <span className="text-green-500">
                                  <Icon icon="akar-icons:check" width={20} />
                                </span>
                                <span className=" text-black  text-xs ml-1">
                                  {
                                    plan.resources.find(
                                      (plan: any) => plan.resourceId === 4
                                    ).limit
                                  }{" "}
                                  Profiles
                                </span>
                              </div>
                            ) : (
                              <div className="p-0 m-0 text-center flex justify-center ">
                                {handleCheck(plan, resource) ? (
                                  <span className="text-green-500">
                                    <Icon icon="akar-icons:check" width={20} />
                                  </span>
                                ) : (
                                  <span className="text-red-500">
                                    <Icon icon="iconoir:cancel" width={20} />
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                        ))}
                        <td className="py-4  px-6 mx-10">
                          {resource.name === "Staff Profiles" ? (
                            <div className="p-0 m-0 text-center flex justify-center text-green-500">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                value=""
                                onChange={(e) => {
                                  handleStaffProfile(e.target.checked);
                                }}
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                              />{" "}
                              <span className="underline text-black  text-xs ml-1">
                                <input
                                  type="number"
                                  value={customStaff}
                                  onChange={(e) => {
                                    setCustomStaff(parseInt(e.target.value));
                                  }}
                                  disabled={disabledCount}
                                  className="min-w-[20px] max-w-[30px] underline border-none h-[10px] text-center px-2 py-2 text-sm  placeholder:text-gray-400 border-darkergrey bg-lightergrey focus:outline-0 focus:border-blue-300"
                                />
                                Profiles
                              </span>
                            </div>
                          ) : (
                            <div className="p-0 m-0 text-center flex justify-center text-green-500">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                onChange={(e) =>
                                  handleCustom(resource, e.target.checked)
                                }
                                defaultChecked={[
                                  "Commentary and Review Management",
                                  "Bookings and Appointments",
                                ].includes(resource.name)}
                                disabled={[
                                  "Commentary and Review Management",
                                  "Bookings and Appointments",
                                ].includes(resource.name)}
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ModalContainer modalOpen={showCancelModal} closeModal={closeModal}>
        <div className="interFamily">
          <p className="text-xl mb-3 ">
            {" "}
            Are you sure you want to cancel your subscription
          </p>

          <div>
            <p>
              You will continue to have access to all
              <span className="font-bold uppercase">
                {" "}
                {activeBusiness?.subscription?.plan.name}{" "}
              </span>
              {`features untill the ${formattedEndDate()}, after which your subsciption will not renew`}
            </p>
          </div>

          <div className="flex justify-end space-x-5 mt-5">
            <Button
              onClick={async () => {
                try {
                  setIsDeleting(true);
                  await axios.delete(`/api/subscription`);
                  Toast("Subscription cancelled successfully", "success");
                  dispatch(getactiveBusiness());

                  closeModal();
                } catch (e) {
                  console.error(e);
                } finally {
                  setIsDeleting(false);
                }
              }}
              disabled={isDeleting}
            >
              YES{" "}
            </Button>
            <Button variant="bg-danger" onClick={closeModal}>
              NO{" "}
            </Button>
          </div>
        </div>
      </ModalContainer>
    </OverlaySpinner>
  );
};

export default Subscription;
