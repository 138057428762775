import React from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Button } from "../../components/Base/Button";
import { Input } from "../../components/Base/Input";
import { InputValidationError } from "../../components/InputValidationError";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { changePassword } from "../../features/auth/auth";

const ChangePassword = () => {
  const location = useLocation().search;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Please enter your password")
      .min(6, "Passwords must be 6 or more characters"),

    confirmPassword: yup
      .string()
      .required("Please enter your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  return (
    <div className="flex flex-col items-center">
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          (async () => {
            try {
              setSubmitting(true);
              const code = new URLSearchParams(location).get("oobCode");
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const key = new URLSearchParams(location).get("apiKey");
              const forStore = { code, newPassword: data.password };
              const response = await dispatch(changePassword(forStore));
              if (response.payload === "success") {
                setTimeout(() => {
                  navigate("/auth/login");
                }, 4000);
              }
            } catch (e) {
              console.error(e);
            } finally {
              setSubmitting(false);
            }
          })();
        }}
        validationSchema={schema}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="p-5 space-y-5 font-roboto bg-white rounded-md w-full sm:w-[340px]">
            <div>
              <Field
                type="password"
                name="password"
                as={Input}
                label="Password"
                placeholder="********"
              />
              {errors.password && touched.password ? (
                <InputValidationError text={errors.password} />
              ) : null}
            </div>

            <div>
              <Field
                type="password"
                name="confirmPassword"
                as={Input}
                label="Confirm Password"
                placeholder="********"
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <InputValidationError text={errors.confirmPassword} />
              ) : null}
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                variant="bg-lightblue"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
