import React from "react";
import { Rating } from "flowbite-react";

const SkeletonImage = () => {
  return (
    <div>
      <div
        role="status"
        className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
      >
        <div className="flex items-center justify-center w-2/6 h-40 bg-gray-300 rounded ">
          <svg
            className="w-12 h-12 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path>
          </svg>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export const SkeletonImageFull = () => {
  return (
    <div>
      <div
        role="status"
        className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
      >
        <div className="flex items-center justify-center w-full h-24 bg-gray-300 rounded ">
          <svg
            className="w-12 h-12 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path>
          </svg>
        </div>

        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const SkeletonFAQ = () => {
  return (
    <>
      <div className="">
        <div className="flex flex-col rounded-lg">
          <div className="py-3 space-y-1 bg-white border border-black rounded-lg">
            <div className="p-3 xl:p-3">
              <p className="text-[9px] text-left py-1">
                <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              </p>
              <p className="text-[9px] text-left  pt-1">
                <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              </p>
            </div>
            <div className="flex justify-end px-3 h-8 bg-main"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export const SkeletonReviews = () => {
  return (
    <>
      <div className="">
        <div className="flex flex-col rounded-lg">
          <div className="p-3 space-y-1 rounded-t-lg xl:p-3 bg-basegrey">
            <Rating>
              <Rating.Star filled={false} />
              <Rating.Star filled={false} />
              <Rating.Star filled={false} />
              <Rating.Star filled={false} />
              <Rating.Star filled={false} />
            </Rating>
            <div className="pt-2">
              <div className="h-2 bg-gray-200 rounded-full  mb-1"></div>

              <div className="mt-3">
                <div className="h-2 bg-gray-200 rounded-full  mb-1"></div>
              </div>
            </div>
          </div>
          <div className="flex justify-end p-3 rounded-b-lg bg-main">
            <div className="h-3 bg-gray-200 w-20 rounded-full  mb-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonImage;
