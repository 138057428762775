import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

// Define the initial state of the application
interface tourState {
  tour: any;
}
const initialState: tourState = {
  tour: {},
};
export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    SET_TOUR: (state, action: PayloadAction<unknown>) => {
      state.tour = action.payload;
      localStorage.setItem("varroe-tour", JSON.stringify(action.payload));
    },
  },
});

export const { SET_TOUR } = loaderSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectTour = (state: RootState) => state.tour.tour;

export default loaderSlice.reducer;
