import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import { faqType } from "../interfaces/faq";
import DeleteModal from "./Modals/DeleteModal";
interface Props {
  faq: faqType;
  index: Number;
  refresh: () => void;
}
const Faq = (props: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsloading] = useState(false);
  const handleDelete = async () => {
    try {
      setIsloading(true);
      await axios.delete(`/api/faq/${props.faq.id}`);
      props.refresh();

      setDeleteModalOpen(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsloading(false);
    }
  };
  return (
    <>
      <div className="">
        <div className="flex flex-col rounded-lg">
          <div className="pb-3 pt-1 space-y-1 bg-white border border-black rounded-lg">
            <div className="p-3 xl:p-3">
              <p className="text-sm text-left py-1 truncate">
                <span className="pr-1 font-bold">Q: </span>
                {props.faq.question}
              </p>
              <p className="text-sm text-left  pt-1 truncate">
                <span className="pr-1 font-bold">A: </span> {props.faq.answer}
              </p>
            </div>
            <div className="flex justify-between items-center px-3 py-2 bg-main">
              <p className="text-white font-semibold font-outfit">
                #{String(+props.index + 1)}
              </p>
              <div
                className="flex items-center text-xs lg:text-[15px] text-right rounded bg-white text-danger p-1 cursor-pointer font-semibold"
                onClick={() => setDeleteModalOpen(true)}
              >
                <Icon icon="ci:trash-empty" width="10" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modalOpen={deleteModalOpen}
        closeModal={() => {
          setDeleteModalOpen(false);
        }}
        delete={handleDelete}
        type="FAQ"
        size="small"
      />
    </>
  );
};

export default Faq;
