import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useState } from "react";
import DeleteModal from "./Modals/DeleteModal";
import ServiceModal from "./Modals/ServiceModal";
import ImagePlaceholder from "../assets/images/homeIcon.svg";
import useCurrency from "../hooks/useCurrency";

interface Props {
  service: any;
  refresh?: any;
}

const Service = (props: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const deleteService = async (id: number) => {
    try {
      setSubmit(true);
      await axios.delete(`/api/business/service/${id}`);
      setSubmit(false);
      props.refresh(true);
    } catch (error) {
      console.error(error);
      setSubmit(false);
    }
  };

  return (
    <>
      <div
        className="cursor-pointer w-full"
        onClick={() => setServiceModalOpen(true)}
      >
        <div className="flex flex-col rounded-lg relative">
          {props.service.isHome && (
            <div className="absolute -right-3 -top-3 w-9 h-9 rounded-full bg-main flex items-center justify-center">
              <img
                src={ImagePlaceholder}
                alt=""
                className="object-contain h-5 "
              />
            </div>
          )}

          <div className="h-32 p-3 space-y-2 rounded-t-lg xl:p-5 bg-basegrey">
            <div className="space-y-1">
              <p className="text-xs lg:text-[16px] font-semibold text-left capitalize">
                {props.service.name}
              </p>

              <div className="flex items-center justify-end space-x-1">
                <p className="text-[9px] text-left">
                  {props.service.isLive ? "Listed" : "Unlisted"}
                </p>
                <div
                  className={` ${
                    props.service.isLive ? "bg-green-700" : "bg-red-700"
                  } rounded-full p-[3px]`}
                ></div>
              </div>
            </div>

            <p className="text-[9px] text-left  flex items-center">
              {props.service.description}
            </p>
          </div>
          <div className="flex items-center justify-between p-4 rounded-b-lg bg-main">
            <div
              className="p-1 bg-white rounded cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModalOpen(true);
              }}
            >
              <span className="text-danger text-[9px]">
                <Icon icon="charm:bin" />
              </span>
            </div>
            <h2 className="text-xs lg:text-[16px]  text-white font-semibold">
              {useCurrency(props.service.price)}
            </h2>
          </div>
        </div>
      </div>
      <DeleteModal
        modalOpen={deleteModalOpen}
        closeModal={() => {
          setDeleteModalOpen(false);
        }}
        title={props.service.name}
        type="Service"
        size="small"
        delete={() => deleteService(props.service.id)}
        isSubmitting={submit}
      />
      <ServiceModal
        closeModal={() => setServiceModalOpen(false)}
        modalOpen={serviceModalOpen}
        service={props.service}
        refresh={() => props.refresh(true)}
      />
    </>
  );
};

export default Service;
