import { useEffect, useState } from "react";
interface Props {
  items: any;
}
const ScheduleList = (props: Props) => {
  const [dates, setDates] = useState([]);
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  useEffect(() => {
    props.items.schedule && setDates(props.items.schedule.dates);
  }, []);
  return (
    <div className="space-x-4">
      {days.map((day, index) => (
        <span
          className={`${dates.map((date) =>
            date === day ? " bg-maingreen " : null
          )} bg-danger  schedulespan`}
          key={index}
        >
          {day !== "thursday" ? day.slice(0, 3) : day.slice(0, 5)}
        </span>
      ))}
    </div>
  );
};

export default ScheduleList;
