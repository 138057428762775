import { Rating } from "flowbite-react";

interface Props {
  rating: number;
  review: string;
  customername: string;
  date: string;
}

const DashboardReviews = (props: Props) => {
  return (
    <div className="flex items-center justify-between w-full space-x-3 overflow-y-scroll">
      <Rating>
        <Rating.Star filled={props.rating > 0} />
        <Rating.Star filled={props.rating > 1} />
        <Rating.Star filled={props.rating > 2} />
        <Rating.Star filled={props.rating > 3} />
        <Rating.Star filled={props.rating > 4} />
      </Rating>
      <p className="md:w-4/6  md:whitespace-normal text-[10px] md:text-xs pt-2">
        {props.review}
      </p>

      <div className="p-3 rounded bg-main w-">
        <p className="text-[10px] md:text-xs italic text-right text-white">
          {props.date}
        </p>
        <p className="text-[10px] md:text-xs font-semibold text-white">
          {props.customername}
        </p>
      </div>
    </div>
  );
};

export default DashboardReviews;
