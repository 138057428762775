import { DateTime } from "ts-luxon";

const timeFormat = () => {

    const TwentyFourHourFormat = (time: string)=>{
        const merideim = DateTime.fromISO(time).toFormat("a");
        return `${time} ${merideim}`
      }
      const formatTime = (time: string)=>{
      const newDate = DateTime.fromISO(time).toFormat("DDD");
        return newDate
      }
      const htmlDate = (time: string)=>{
        const newDate = DateTime.fromISO(time).toISODate();
        if(newDate){
          return newDate
  
        }
        else{
          return "2022-01-12"
        }
        }
    return { TwentyFourHourFormat,formatTime,htmlDate }

}
export default timeFormat