/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  business,
  businessCategory,
  CountryAttributes,
} from "../../interfaces/business";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { Select } from "../Base/Select";
import FileUpload from "../FileUpload";
import timeFormat from "../../hooks/useMeridem";
import axios from "axios";
import useFileUpload from "../../hooks/useFileUpload";
import OverlaySpinner from "../OverlaySpinner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getactiveBusiness } from "../../features/business/business";
import { Textarea } from "../Base/Textarea";
interface Props {
  business: business;
}
const BusinessProfile = (props: Props) => {
  const [update, setUpdate] = useState(false);
  const [update2, setUpdate2] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [name, setName] = useState("");
  const [initialName, setInitialName] = useState("");

  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [date, setDate] = useState("");
  const [logo, setLogo] = useState("");
  const [street, setStreet] = useState("");
  const [initialStreet, setInitialStreet] = useState("");
  const [province, setProvince] = useState<any>(0);
  const [initialProvince, setInitialProvince] = useState<any>(0);

  const [city, setCity] = useState("");
  const [initailCity, setInitialCity] = useState("");
  const [country, setCountry] = useState<any>(1);
  const [initialCountry, setInitialCountry] = useState<any>(1);

  const [isLoading, setIsloading] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [allData, setAllData] = useState<any>();
  const [countryOptions, setCountryOptions] = useState<
    Array<{ text: string; value: number | string }>
  >([]);

  const [startDate, setStartDate] = useState("2020-02-12");
  const [initialStartDate, setInitialStartDate] = useState("2020-02-12");
  const [allClassification, setAllClassification] = useState<any>();
  const [website, setWebsite] = useState("");
  const [initialWebsite, setInitialWebsite] = useState("");
  const [type, setType] = useState("");
  const [initialType, setInitialType] = useState("");
  const [size, setSize] = useState("");
  const [initialSize, setInitialSize] = useState("");

  const [language, setLanguage] = useState("");
  const [initialLanguage, setInitialLanguage] = useState("");

  const [isLoadingClass, setIsLoadingClass] = useState(false);
  const dispatch = useAppDispatch();
  const [allProvinces, setAllProvinces] = useState<
    Array<{ text: string; value: number | string }>
  >([]);

  const [categories, setCategories] = useState<businessCategory[]>([]);
  const [defaultCategory, setDefaultCategory] = useState(1);

  const { submitFile } = useFileUpload();

  const { htmlDate } = timeFormat();
  const handleUpdateButton = () => {
    const list = [
      initialName === name,
      initialEmail === email,
      initialProvince === province,
      initialDescription === description,
      initailCity === city,
      initialStreet === street,
    ];
    if (list.some((value) => !value)) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  };
  const handleSecondUpdateButton = () => {
    const list = [
      initialStartDate === startDate,
      initialWebsite === website,
      initialType === type,
      initialSize === size,
      initialLanguage === language,
    ];
    if (list.some((value) => !value)) {
      setUpdate2(true);
    } else {
      setUpdate2(false);
    }
  };
  useEffect(() => {
    handleUpdateButton();
  }, [name, email, province, street, city, description]);
  useEffect(() => {
    handleSecondUpdateButton();
  }, [startDate, website, type, size, language]);
  const setValues = (data: any) => {
    setAllData(data);
    setName(data.name);
    setInitialName(data.name);
    setDescription(data.description ?? "");
    setInitialDescription(data.description ?? "");
    setEmail(data.email);
    setInitialEmail(data.email);
    const formattedDate = htmlDate(data.createdAt);
    setDate(formattedDate);
    setLogo(data.logo);
    setStreet(data.address.street);
    setInitialStreet(data.address.street);
    setProvince(data.address.stateId);
    setInitialProvince(data.address.stateId);
    setCity(data.address.city);
    setInitialCity(data.address.city);
    setCountry(data.address.country);
    setInitialCountry(data.address.country);
    // setCountryOptions((prev)=> [...prev,data.address.country])
  };
  const setClassifications = (data: any) => {
    const classification = data.value;
    setStartDate(classification.date);
    setInitialStartDate(classification.date);
    setAllClassification(data);
    setWebsite(classification.website);
    setInitialWebsite(classification.website);
    setType(classification.type);
    setInitialType(classification.type);
    setSize(classification.staffSize);
    setInitialSize(classification.staffSize);
    setLanguage(classification.language);
    setInitialLanguage(classification.language);
  };
  const StoreBusinessId = useAppSelector((state) => state.business.business.id);

  const handleUpdate = async () => {
    try {
      setIsloading(true);
      const payload = {
        ...allData,
        email,
        name,
        description,
        logo,
        address: {
          street,
          stateId: province.id ? province.id : province,
          city,
        },
        categoryId: defaultCategory,
      };
      delete payload.id;
      delete payload.live;
      delete payload.code;
      delete payload.userId;
      delete payload.addressId;
      delete payload.isVerified;
      delete payload.createdAt;
      delete payload.deletedAt;
      delete payload.updatedAt;
      delete payload.category;
      delete payload.slug;

      await axios.put(`/api/business/${props.business.id}`, payload);
      dispatch(getactiveBusiness());
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const updateClassification = async () => {
    try {
      setIsLoadingClass(true);
      const payload = {
        date: startDate,
        type,
        website,
        language,
        staffSize: size,
        closingTime: allClassification.value.closingTime,
        openingTime: allClassification.value.openingTime,
      };
      await axios.put(`/api/business/parameter/classification`, payload);
      dispatch(getactiveBusiness());
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingClass(false);
    }
  };

  const pushFilesToArray = async (file: any) => {
    try {
      setIsloading(true);

      const url = await submitFile(file, "logo");
      setLogo(url);
      setSelectedFile([...selectedFile, file]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };

  const getBusiness = async () => {
    try {
      setIsloading(true);
      const endpoints = [
        `/api/business/${StoreBusinessId}`,
        `/api/business/parameter/classification`,
        `/api/address/country`,
        `/api/business/meta`,
      ];
      const [profile, classification, country, meta] = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint))
      );
      if (profile.data) {
        setValues(profile.data.data);
        setDefaultCategory(profile.data.data.categoryId);
      }
      if (classification.data) {
        setClassifications(classification.data.data);
      }
      if (country.data) {
        setCountryOptions(
          country.data.data.map((country: CountryAttributes) => ({
            text: country.name,
            value: country.id,
          }))
        );
        setCountry(country.data.data[0].id);
      }
      if (meta.data) {
        setCategories(meta.data.data.categories);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const getProvince = async () => {
    try {
      setAllProvinces([]);
      const {
        data: { data },
      } = await axios.get(`/api/address/state/${country}`);
      setAllProvinces(
        data.map((country: CountryAttributes) => ({
          text: country.name,
          value: country.id,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getProvince();
  }, [country]);
  useEffect(() => {
    getBusiness();
  }, [fetch]);
  return (
    <div className="h-auto overflow-y-auto">
      <div className="flex space-x-10 p-2 h-[80vh]">
        <OverlaySpinner isLoading={isLoading}>
          <div className="w-[25rem] px-8 pt-4 pb-10 my-4 overflow-y-auto bg-white dropshadow rounded-xl">
            <form className="sm:space-y-4 font-roboto">
              <div className="flex flex-col justify-between py-2 space-x-3 sm:flex-row sm:flex-nowrap">
                <Input
                  placeholder="eg. Varroe"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  label="Business Name"
                />
                <div className="flex w-full flex-col">
                  <label className="mb-2 text-[10px] text-left">
                    Business Category
                  </label>
                  <div className="">
                    <select
                      name="businessCategory"
                      className={`w-full cursor-pointer appearance-none text-sm py-2 px-3 rounded-md text-black h-auto border border-solid border-darkergrey bg-lightergrey focus:outline-0  focus:border-blue-300`}
                      value={defaultCategory}
                      onChange={(e) => {
                        console.log("value", e.target.value);
                        setDefaultCategory(Number(e.target.value));
                      }}
                    >
                      {categories.map((value, index) => (
                        <option value={value.id} key={index}>
                          {value.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-x-3 justify-between py-2 sm:py-0">
                <div className="w-[80%]">
                  <Input
                    type="email"
                    placeholder="Business Email"
                    label="Business Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="w-[20%]">
                  <FileUpload
                    label="Logo"
                    text="Logo"
                    pushFilesToArray={pushFilesToArray}
                  />
                </div>
              </div>
              <div className="w-full py-2 sm:py-0">
                <Textarea
                  placeholder="Business Description"
                  label="Business Description"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              <div className="text-xs font-semibold h6">Business Address</div>
              <div className="grid grid-cols-2 gap-4 pb-5">
                <div className="w-full py-2 sm:py-0">
                  <Select
                    options={countryOptions}
                    label="Country"
                    disabled={true}
                    value={country}
                    onChange={(e) => {
                      setCountry(Number(e.target.value));
                    }}
                  />
                </div>
                <div className="w-full py-2 sm:py-0">
                  <Select
                    options={allProvinces}
                    label="Province"
                    value={province}
                    onChange={(e) => {
                      setProvince(Number(e.target.value));
                    }}
                  />
                </div>

                <div className="w-full py-2 sm:py-0">
                  <Input
                    label="City"
                    placeholder="114 Buildover close"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full py-2 sm:py-0">
                  <Input
                    label="Street"
                    placeholder="114 Buildover close"
                    value={street}
                    onChange={(e) => {
                      setStreet(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Button
                onClick={handleUpdate}
                disabled={isLoading}
                auth={update ? " bg-main text-white " : " bg-gray-200  "}
              >
                {isLoading ? "Loading..." : "Update"}
              </Button>
            </form>
          </div>
        </OverlaySpinner>
        <OverlaySpinner isLoading={isLoading}>
          <div className="w-[25rem] px-8 pt-4 pb-10 my-4 overflow-y-auto bg-white  dropshadow rounded-xl">
            <form className="sm:space-y-4 font-roboto">
              <div className="flex flex-col  py-2 sm:flex-row sm:flex-nowrap">
                <Input
                  placeholder="Business Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  label="Business Start Date"
                />
              </div>

              <div className="w-full py-2 sm:py-0">
                <Input
                  type="text"
                  placeholder="eg. varroe.com"
                  label="Website"
                  value={website}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </div>

              <div className="w-full py-2 ">
                <Select
                  options={[
                    "Professionals Services",
                    "Software Services",
                    "Training Services",
                  ]}
                  label="Type"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
              </div>

              <div className="w-full py-2  ">
                <Select
                  options={[
                    "1 to 5 people",
                    "2 to 20 people",
                    "20 to 100 people",
                    "100 to 1000 people",
                  ]}
                  label="Staff Size"
                  value={size}
                  onChange={(e) => {
                    setSize(e.target.value);
                  }}
                />
              </div>
              <div className="w-full py-2  ">
                <Select
                  options={["English", "French"]}
                  label="Language"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                />
              </div>

              <Button
                onClick={updateClassification}
                disabled={isLoadingClass}
                auth={update2 ? " bg-main text-white " : " bg-gray-200  "}
              >
                {isLoadingClass ? "Loading..." : "Update"}
              </Button>
            </form>
          </div>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default BusinessProfile;
