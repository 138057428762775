import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import Dropdown from "./Dropdown";
import LineChart from "./Graphs/LineChart";
import OverlaySpinner from "./OverlaySpinner";

const Ratings = () => {
  const {
    dropDownOptions,
    dropDownText,
    duration,
    fetch,
    getValue,
    isloading,
    getData,
    noData,setNoData
  } = useAnalyticsHook();
  const [ratings, setRatings] = useState<any>({
    duration: { start: "", end: "" },
    chart: [
      {
        yaxis: 0,
        day: "",
        label: "",
      },
    ],
  });
  const getRatings = async () => {
    
    const data = await getData(`/api/analytics/ratings?interval=${duration}`);
    // const data = await getData(`/api/review/analytics?interval=${duration}`);
    if(data.error)return
    const empty = data.chart.every((el:any)=>el.yaxis === 0)
    setNoData(empty)
    setRatings(data);
  };
  useEffect(() => {
    getRatings();
  }, [fetch]);
  return (
    <div className="flex justify-between h-[330px] p-6 overflow-auto bg-basegrey rounded-xl">
      <div className="w-full">
        <div className="flex justify-between items-center mb-2">
          <p className="font-semibold md:text-lg pb-1">Ratings</p>
          <div className="flex justify-between items-center">
            <div className="mr-3 text-[10px]">
              Ratings from {ratings.duration.start} to {ratings.duration.end}{" "}
            </div>
            <Dropdown
              text={dropDownText}
              values={dropDownOptions}
              getValue={getValue}
            />
          </div>
        </div>
        <OverlaySpinner isLoading={isloading} noData={noData}>
          <div className="flex">
            <div className="w-full p-3 overflow-hidden bg-white h-60 rounded-xl">
              <div className="w-full">
                <LineChart data={ratings} />
              </div>
            </div>
          </div>
        </OverlaySpinner>
      </div>
    </div>
  );
};

export default Ratings;
