import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { auth } from "../config/firebase";
export interface IAuthRouteProps {
  children: JSX.Element[] | JSX.Element | React.ReactNode;
  path: string;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children, path } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    !!localStorage.getItem("merchant-api-token")
  );
  const currentLocation = useLocation();
  const userRole = useAppSelector((state) => state.auth.role);
  const subStatus = useAppSelector(
    (state) => state.business.business.subscription
  );
  window.addEventListener("storage", (_) => {
    setToken(!!localStorage.getItem("merchant-api-token"));
  });
  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, (user) => {
      if ((user && token) ?? path.includes("auth")) {
        // authorized
        setLoading(false);
      } else {
        // unauthorized
        navigate("/auth/login");
      }
    });

    return () => AuthCheck();
  }, [auth, token]);

  useEffect(() => {
    const staffOnlyPages = [
      "/dashboard/home",
      "/dashboard/appointments",
      "/auth/register",
    ];
    if (
      userRole === "freelancer" &&
      currentLocation.pathname.includes("staff")
    ) {
      navigate("/dashboard/home");
    }
    if (
      userRole === "staff" &&
      !staffOnlyPages.includes(currentLocation.pathname)
    ) {
      if (!currentLocation.pathname.includes("/auth")) {
        navigate("/dashboard/home");
      }
    }
    if (currentLocation.pathname.includes("crm")) {
      if (subStatus && subStatus?.plan.name.toLowerCase() === "free") {
        navigate("/dashboard/home");
      }
    }
  }, [currentLocation, navigate]);

  if (loading) return <p>loading ...</p>;

  return <>{children}</>;
};

export default AuthRoute;
