import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/Base/PageHeader";
import FaqModal from "../components/Modals/FaqModal";
import Faqs from "../components/Faqs";
import axios from "axios";
import { SkeletonFAQ } from "../components/SkeletonImage";
import NewEmptyState from "../components/NewEmptyState";
import ImagePlaceholder from "../assets/images/emptyFaq.svg";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectFAQ, SET_FAQ } from "../features/pages/pages";

const Settings = () => {
  const storeFAQ = useAppSelector(selectFAQ);
  const dispatch = useAppDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [fetch, setFetch] = useState(false);
  const getFaqs = async () => {
    try {
      !storeFAQ.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/faq`);
      dispatch(SET_FAQ(data));
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  const refresh = () => {
    setFetch((prev) => !prev);
  };
  useEffect(() => {
    getFaqs();
  }, [fetch]);
  return (
    <>
      <div className="h-screen overflow-x-hidden">
        <PageHeader
          text="Your FAQs"
          buttontext="create faq"
          buttonevent={() => setModalOpen(true)}
        />

        {isLoading ? (
          <div className="grid grid-cols-4 gap-5 my-5">
            {[1, 1, 1, 1, 1].map((_faq, faqIndex) => (
              <SkeletonFAQ key={faqIndex} />
            ))}
          </div>
        ) : !storeFAQ.length ? (
          <NewEmptyState
            text=" You do not have any FAQs"
            buttonText="Create your first FAQ"
            handleClick={() => setModalOpen(true)}
            image={ImagePlaceholder}
          />
        ) : (
          <div className="grid grid-cols-4 gap-5 my-5">
            {storeFAQ.map((faq, faqIndex) => (
              <Faqs
                key={faqIndex}
                faq={faq}
                index={faqIndex}
                refresh={refresh}
              />
            ))}
          </div>
        )}
        <FaqModal
          modalOpen={modalOpen}
          closeModal={() => {
            setModalOpen(!modalOpen);
          }}
          refresh={refresh}
        />
      </div>
    </>
  );
};

export default Settings;
