import React from "react";
import ImagePlaceholder from "../assets/images/empty.png";

export default function EmptyReviews() {
  return (
    <div className="h-full  flex items-center justify-center">
      <div className=" pb-20 w-[20rem] text-center ">
        <img
          src={ImagePlaceholder}
          alt=""
          className="object-contain mx-auto "
        />
        <p className="font-semibold mt-5">
          Your business currently has no reviews
        </p>
        <p className="text-sm mt-4">
          Your reviews will show up as soon as your clients begin reviewing your
          business
        </p>
      </div>
    </div>
  );
}
