import axios from "axios";
import { useEffect } from "react";
import { DateTime } from "ts-luxon";
import ImagePlaceholder from "../../assets/images/ApproveAppointment.svg";
import useMeridem from "../../hooks/useMeridem";
import { appointmentsType } from "../../interfaces/appointments";
import { Button } from "../Base/Button";
import ModalContainer from "./ModalContainer";
interface Props {
  closeModal: any;
  modalOpen: boolean;
  refresh: () => void;
  approveModalProps?: appointmentsType;
  staffId?: number;
}
const ApproveAppointmentModal = (props: Props) => {
  useEffect(() => {}, [props.staffId]);
  const { TwentyFourHourFormat } = useMeridem();
  const handleYes = async () => {
    try {
      const payload = {
        accepted: true,
        staffId: props.staffId,
      };
      !props.staffId && delete payload.staffId;
      await axios.patch(
        `/api/appointment/${props.approveModalProps?.id}/handle`,
        payload
      );
    } catch (e) {
      console.error(e);
    } finally {
      props.closeModal();
      props.refresh();
    }
  };
  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="small"
    >
      <div className="mt-3 sm:text-left">
        <div className="flex flex-col-reverse items-start justify-between sm:flex-row">
          <div className="flex flex-col justify-between sm:w-3/6">
            <div className="flex items-center sm:mr-0 sm:justify-start">
              <div className="w-full space-y-3 md:mr-0 sm:w-auto">
                <p className="text-lg font-normal text-left"> Approve</p>
                <p className="text-sm sm:text-[14px] break-words text-left md:text-xl font-semibold">
                  {props.approveModalProps?.customer.firstName +
                    " " +
                    props.approveModalProps?.customer.lastName +
                    "'s " +
                    props.approveModalProps?.service.name +
                    " Appointment?"}
                </p>

                <div className="pt-6">
                  <p className="text-xs font-semibold text-left break-words sm:text-base">
                    {props.approveModalProps &&
                      DateTime.fromISO(props.approveModalProps.date).toFormat(
                        "DDDD"
                      )}
                  </p>
                  <p className="text-xs text-left break-words sm:text-base ">
                    {props.approveModalProps &&
                      TwentyFourHourFormat(props.approveModalProps.timeSlot)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end w-20 rounded-lg">
            <img
              src={ImagePlaceholder}
              alt=""
              className="object-contain w-full"
            />
          </div>
        </div>

        <div className="flex pt-5 md:justify-end lg:pt-0">
          <Button variant="bg-green-600" onClick={() => handleYes()}>
            Yes
          </Button>
          <Button
            variant="bg-main ml-4"
            onClick={() => {
              props.closeModal();
            }}
          >
            No
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ApproveAppointmentModal;
