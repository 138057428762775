import axios from "axios";
import { useEffect, useState } from "react";
import FeaturesBox from "./FeaturesBox";

const HomeFeatures = () => {
  useEffect(() => {
    getRequirements();
  }, []);
  const [requirements, setRequirements] = useState<any>([]);
  const getRequirements = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/me/business/live`);
      console.log("data", data);
      setRequirements(data);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="flex items-center pb-2 space-x-3">
      {requirements &&
        requirements.map(
          (
            feature: {
              key: string;
              link: string;
              message: string;
              logo: string;
            },
            index: number
          ) => (
            <FeaturesBox
              path={feature.link}
              text={feature.message}
              icon={feature.logo}
              key={index}
            />
          )
        )}
    </div>
  );
};

export default HomeFeatures;
