import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

export interface LoyalCustomerI {
  customer: {
    fullName: string;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    uid: string;
    code: string;
    avatarUrl: null | string;
    phoneNumber: string;
    createdAt: string;
    updatedAt: string;
  };
  lastVisit: null | string;
  averageSpend: string;
  appointmentCount: number;
  pointsTotal: number;
}

export interface BannerRedeemedCustomerI {
  id: number;
  businessId: number;
  appointmentId: number;
  point: number;
  isRedeemed: boolean;
  redeemedAt: null | string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  appointment: {
    id: number;
    businessId: number;
    serviceId: number;
    customerId: number;
    staffId: number | null;
    addressId: string | null;
    serviceSelection: {};
    status: "RESERVED";
    timeSlot: string;
    price: string;
    date: string;
    state: "APPROVED";
    recurringId: null | number;
    optionalNote: string | null;
    images: null | string[];
    createdAt: string;
    updatedAt: string;
    customer: {
      fullName: string;
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      uid: string;
      code: string;
      avatarUrl: null | string;
      phoneNumber: string;
      createdAt: string;
      updatedAt: string;
    };
    service: {
      label: string;
      id: number;
      businessId: number;
      name: string;
      price: string;
      duration: number;
      selectStaff: boolean;
      isHome: boolean;
      isLive: false;
      description: "Borders input Health";
      createdAt: string;
      updatedAt: string;
      deletedAt: null;
    };
    staff?: {
      firstName: string;
      lastName: string;
    };
  };
}

// Define the initial state of the application
interface LoyaltyProgramState {
  point: number;
}
const initialState: LoyaltyProgramState = {
  point: 0,
};

export const loyaltyProgramSlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    SET_LOYALTY_POINT: (state, action: PayloadAction<number>) => {
      state.point = action.payload;
    },
  },
});

export const { SET_LOYALTY_POINT } = loyaltyProgramSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoyaltyPoints = (state: RootState) => state.loyalty.point;

export default loyaltyProgramSlice.reducer;
