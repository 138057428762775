import React from "react";
import { TourProvider } from "@reactour/tour";
import "./App.scss";
import RouteCombiner from "./router/RouteCombiner";
import "./axios/global";
import ToastContainer from "./components/Base/toast";
import ScreenLimiter from "./views/ScreenLimiter";
import steps from "./config/steps";
function App() {
  return (
    <div className="App">
      <TourProvider
        steps={steps}
        showBadge={false}
        showNavigation={false}
        padding={0}
        showCloseButton={false}
        onClickMask={() => {}}
        disableInteraction={true}
        position={({ right, top }) => [right + 60, top - 30]}
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: "10px",
            padding: "25px",
          }),
        }}
      >
        <div className="hidden lg:block">
          <RouteCombiner />
        </div>
        <ScreenLimiter />
        <ToastContainer />
      </TourProvider>
    </div>
  );
}

export default App;
