import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { getCurrencySymbol } from "../../hooks/useCurrency";
import { revenueGrowthType } from "../../interfaces/analytics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,

  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        boxWidth: 0,
        font: {
          weight: "bold",
        },
      },
    },
  },
  tension: 0.3,

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: true,
      grid: {
        drawOnChartArea: false,
      },
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        callback: (value: any) => {
          return getCurrencySymbol() + value;
        },
      },
    },
  },
};

interface Props {
  data?: revenueGrowthType[];
}
const BarChart = (props: Props) => {
  const [coordinates, setCoordinates] = useState<revenueGrowthType[]>([
    {
      revenue: 0,
      year: "",
    },
  ]);
  useEffect(() => {
    props.data && setCoordinates([...props.data]);
  }, [props.data]);
  const data = {
    labels: coordinates.map((data) => data.year),
    datasets: [
      {
        label: "Revenue",
        data: coordinates.map((data) => data.revenue),
        backgroundColor: "#001C43",
        borderRadius: 5,
      },
    ],
  };
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
