import React, { useEffect, useState } from "react";

interface InputProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  accept?: string;
  id?: string;
  name?: string;
  bottomSpace?: boolean;
  value?: string | number;
  min?: number;
}

export const InputDecimal: React.FC<InputProps> = ({
  label,
  placeholder = label,
  onChange,
  type = "text",
  disabled,
  required,
  accept,
  id,
  name,
  bottomSpace,
  value,
  min,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [valuelocal, setValue] = useState("");
  const changeHere = (e: any) => {
    const start = e.target.selectionStart;
    let final: string | number | null = 0;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    if (!match) return;
    const value = match[1] + match[2];
    e.target.value = value;
    final = value;
    if (val.length > 0) {
      e.target.value = Number(value).toFixed(2);
      e.target.setSelectionRange(start, start);
      final = Number(value).toFixed(2);
    }
    setValue(final);
  };
  useEffect(() => {
    if (value) {
      setValue(`${value.toString()}.00`);
    }
  }, []);
  return (
    <>
      <div className="flex flex-col w-full">
        <label
          className={
            (!bottomSpace && " mb-2 ") + "  text-[10px] text-left text-black"
          }
        >
          {label}
        </label>
        <input
          className="w-auto h-auto px-2 py-2 text-sm border border-solid rounded-md placeholder:text-gray-400 border-darkergrey bg-lightergrey focus:outline-0 focus:border-blue-300"
          placeholder={placeholder}
          onChange={(e) => {
            onChange && onChange(e);
            changeHere(e);
          }}
          type="text"
          disabled={disabled}
          required={required}
          accept={accept}
          defaultValue={value}
          value={valuelocal}
          id={id}
          min={min}
          name={name}
        />
      </div>
    </>
  );
};
