import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "../Base/Button";
import Skeleton from "../Skeleton";
import DeleteModal from "./DeleteModal";
import EditStaffModal from "./EditStaffModal";
import ModalContainer from "./ModalContainer";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  openAppointment?: any;
  staffId: number;
  refresh: () => void;
}

const StaffModal = (props: Props) => {
  const [data, setData] = useState<{
    id: 0;
    active: true;
    role: "staff";
    email: "";
    firstName: "";
    lastName: "";
    phone: null;
    profilePic: "";
    businessId: 1;
    userId: 1;
    appointmentCount: 1;
    services: [];
  }>();
  const [submit, setSubmit] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const getStaff = async () => {
    try {
      const response = await axios.get(`/api/staff/${props.staffId}`);
      setData(response.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getStaff();
  }, [refresh]);

  const deleteStaff = async () => {
    setSubmit(true);
    try {
      await axios.delete(`/api/staff/${props.staffId}`);
      setDeleteModalOpen(false);
    } catch (err) {
      setSubmit(false);
      console.error(err);
      setDeleteModalOpen(false);
    }
  };
  return (
    <ModalContainer modalOpen={props.modalOpen} closeModal={props.closeModal}>
      <div className="mt-3 sm:mt-0 sm:text-left">
        {data ? (
          <div className="flex flex-col justify-between sm:flex-row">
            {editModalOpen && (
              <EditStaffModal
                modalOpen={editModalOpen}
                closeModal={() => {
                  setEditModalOpen(false);
                  props.closeModal();
                }}
                refresh={() => {
                  setRefresh((prev) => !prev);
                  props.refresh();
                }}
                size="large"
                staffId={data.id}
              />
            )}
            <div className="flex w-48 md:h-64 sm:justify-start">
              {!!data.profilePic && (
                <img
                  src={data.profilePic}
                  alt="staff"
                  className="object-cover rounded-lg"
                />
              )}
            </div>

            <div className="sm:w-96">
              <div className="p-2 sm:p-3 md:p-5 bg-[#f1f1f1] rounded-lg mr-3 sm:mr-0 sm:h-2/5 lg:h-32 flex items-center justify-center sm:justify-start">
                <div className="w-full my-2 mr-5 md:mr-0 sm:w-auto">
                  <p className="text-sm font-semibold break-words md:text-xl">
                    {data.firstName} {data.lastName}
                  </p>

                  <p className="mt-3 text-xs break-words sm:text-sm">
                    {data.email}
                  </p>
                  <p className="my-3 text-xs break-words sm:text-sm">
                    {data.phone}
                  </p>
                </div>
              </div>

              <div className="">
                <h2 className="mt-4 mb-2 mr-3 text-xs font-semibold">
                  Services Offered
                </h2>
                <div className="flex flex-wrap">
                  {data?.services &&
                    data?.services.map((service: any) => (
                      <div key={service.id}>
                        {service.service && (
                          <div className="p-2 md:px-4 md:py-2 cursor-pointer my-2 bg-[#579AF5] text-white rounded-3xl mr-3">
                            <p className="text-xs capitalize ">
                              {service.service?.name}
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Skeleton />
        )}
        <div className="flex pt-10 space-x-3 md:pt-16">
          <Button
            onClick={() => {
              setEditModalOpen(!editModalOpen);
            }}
          >
            Edit
          </Button>
          {data?.role && !["merchant"].includes(data.role) && (
            <Button
              variant="bg-danger"
              onClick={() => setDeleteModalOpen(!deleteModalOpen)}
            >
              REMOVE FROM BUSINESS
            </Button>
          )}
        </div>
      </div>

      {deleteModalOpen && (
        <DeleteModal
          modalOpen={deleteModalOpen}
          closeModal={() => {
            setDeleteModalOpen(false);
          }}
          type="Staff"
          size="small"
          delete={deleteStaff}
          title={data?.firstName + " " + data?.lastName}
          isSubmitting={submit}
        />
      )}
    </ModalContainer>
  );
};

export default StaffModal;
