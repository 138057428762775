import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { paymentType } from '../../interfaces/analytics';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



interface Props {
  data?: any;
}

const LineChart = (props: Props) => {
  const [coordinates, setCoordinates] = useState<paymentType[]>([{
    yaxis:0,day:'',label:''
  }]);

  const chartData = {
    labels: coordinates.map((data) => data.day),

    datasets: [
      {
        label: '',
        data: coordinates.map((data) => data.yaxis),
        backgroundColor: 'rgba(67, 93, 130, 0.2)',
        borderColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, '#0d1623');
          gradient.addColorStop(0.5, '#0066F1 ');
          return gradient;
        },
        borderWidth: 2,
        fill: true,

      },
    ],
  };

  useEffect(() => {
    props.data && setCoordinates([...props.data.chart]);
    // setChartData(props.data)
  }, [props.data]);
  const options = {
    responsive: true,
  
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 0,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  
    tension: 0.3,
    scaleShowVerticalLines: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: {
          display: true,
        },
        display: true,
        grid: {
          display: true,
        },
      },
    },
  };
  return (
    <div className="w-full">
      <Line options={options} data={chartData} />
    </div>
  );
};

export default LineChart;
