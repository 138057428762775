import React, { useEffect, useState } from "react";
import { businessScheduleType } from "../interfaces/business";
import { Input } from "./Base/Input";
interface Props {
  schedule: businessScheduleType;
  update: (e: any) => void;
}
export default function CustomizationScheduleList(props: Props) {
  const [open, setOpen] = useState(true);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  useEffect(() => {
    setStart(props.schedule.start);
    setEnd(props.schedule.end);
    setOpen(props.schedule.active);
  }, []);

  useEffect(() => {
    // PushDetails to parent
    props.update({ start, end, open });
  }, [open, start, end]);

  return (
    <div className="flex flex-col items-center justify-between space-y-4 lg:flex-row lg:space-y-0">
      <p className="text-[11px] font-semibold w-2/6 capitalize">
        {props.schedule.date}
      </p>

      <div className="flex justify-between w-5/6 space-x-3">
        <Input
          label="Opening Time"
          value={start}
          onChange={(e) => setStart(e.target.value)}
          type="time"
        />
        <Input
          label="Closing Time"
          value={end}
          onChange={(e) => setEnd(e.target.value)}
          type="time"
        />
      </div>

      <div
        className="flex justify-end w-2/6 cursor-pointer"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        {open ? (
          <span className="px-3 py-1 text-xs text-white bg-green-600 rounded-2xl">
            Open
          </span>
        ) : (
          <span className="px-3 py-1 text-xs text-white bg-red-600 rounded-2xl">
            Closed
          </span>
        )}
      </div>
    </div>
  );
}
