import { Icon } from "@iconify/react";
import React, { createContext, useEffect, useState } from "react";
import { Button } from "../Base/Button";
import AddOns from "./AddOns";
import CustomizationOptions from "./CustomizationOptions";

interface Props {
  service?: any;
  id?: number;
  setValue?: any;
  isEdit?: boolean;
  addData?: any;
  addCustomisation?: any;
  delete?: any;
  updateValue?: any;
}
type customisationType = Array<{
  name: string;
  option: boolean;
  value: any;
}>;
interface ContextType {
  ContextAddons: [];
  setContextAddons: (addOnServices: any) => void;
  ContextCustomisation: customisationType;
  setContextCustomisation: (customizationServices: any) => void;
}
export const DataContext = createContext<ContextType>({
  ContextAddons: [],
  setContextAddons: () => {},
  ContextCustomisation: [{ name: "", option: false, value: [] }],
  setContextCustomisation: () => {},
});

const Tabs = (props: Props) => {
  const [customizationServices, setCustomizationServices] =
    useState<customisationType>([{ name: "", option: false, value: [] }]);
  const [addOnServices, setAddOnServices] = useState<any>([
    { name: "", price: 0 },
  ]);

  const [editAddOns, setEditAddOns] = useState<any>([]);
  const [editCustomizations, setEditCustomizations] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [scrollDiv, setScrollDiv] = useState(false);
  const addItem = () => {
    if (props.isEdit) {
      setEditCustomizations([
        ...editCustomizations,
        { name: "", option: false, value: [] },
      ]);
    } else {
      setCustomizationServices([
        ...customizationServices,
        { name: "", option: false, value: [] },
      ]);
    }
    setScrollDiv((prev) => !prev);
  };

  const addItemToAddOns = () => {
    if (props.isEdit) {
      setEditAddOns([...editAddOns, { name: "", price: 0 }]);
    } else {
      setAddOnServices([...addOnServices, { name: "", price: 0 }]);
    }
    setScrollDiv((prev) => !prev);
  };
  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (props.isEdit) {
      setEditAddOns([...props.service.addons]);
      setEditCustomizations([...props.service.customisations]);
    }
  }, []);

  return (
    <div className="Tabs">
      <div className="flex justify-between">
        <div>
          <Button
            type="button"
            variant={`${
              activeTab === "tab1" ? "bg-main" : "bg-[#D6D3D8] text-main"
            } capitalize !font-medium`}
            onClick={() => handleTab("tab1")}
          >
            Add Ons
          </Button>
          <Button
            variant={`ml-3 ${
              activeTab === "tab2" ? "bg-main" : "bg-[#D6D3D8] text-main"
            } capitalize !font-medium`}
            onClick={() => handleTab("tab2")}
          >
            Customization Options
          </Button>
        </div>
        <div
          className="flex items-center justify-center w-10 px-1 ml-3 text-main rounded-md cursor-pointer bg-[#D6D3D8]"
          onClick={() => (activeTab === "tab1" ? addItemToAddOns() : addItem())}
        >
          <Icon icon="akar-icons:plus" width="12" height="12" />
        </div>
      </div>
      <div className="">
        <DataContext.Provider
          value={{
            ContextAddons: addOnServices,
            setContextAddons: setAddOnServices,
            ContextCustomisation: customizationServices,
            setContextCustomisation: setCustomizationServices,
          }}
        >
          <AddOns
            isEdit={props.isEdit}
            services={props.service ? editAddOns : addOnServices}
            addOnServices={addOnServices}
            editAddOnServices={editAddOns}
            addData={props.addData}
            appear={activeTab === "tab1"}
            add={() => (activeTab === "tab1" ? addItemToAddOns() : addItem())}
            delete={(i: any) => {
              if (props.isEdit) {
                setEditAddOns((current: any) =>
                  current.filter((fruit: any, index: number) => index !== i)
                );
              }
            }}
            scroll={scrollDiv}
          />

          <CustomizationOptions
            isEdit={props.isEdit}
            addCustomization={editCustomizations}
            addCustomisation={props.addCustomisation}
            services={
              props.service ? editCustomizations : customizationServices
            }
            appear={activeTab === "tab2"}
            add={() => (activeTab === "tab1" ? addItemToAddOns() : addItem())}
            delete={(i: any) => {
              if (props.isEdit) {
                setEditCustomizations((current: any) =>
                  current.filter((fruit: any, index: number) => index !== i)
                );
              }
            }}
            scroll={scrollDiv}
          />
        </DataContext.Provider>
      </div>
    </div>
  );
};

export default Tabs;
