import { Icon } from "@iconify/react";

interface Props {
  icon: string;
  type: string;
  value: string | number | undefined;
  smallertext?: boolean;
  date?: string;
}

const Statistic = (props: Props) => {
  return (
    <div className="space-y-1">
      <div className="flex items-center p-2 w-7 h-7 bg-main rounded-3xl">
        <Icon icon={props.icon} color="white" width={20} />
      </div>
      <p className="text-xs md:text-sm interFamily text-[#A9A9A9] font-semibold  whitespace-nowrap mt-3 capitalize">
        {props.type}
      </p>

      <h3
        className={
          (props.smallertext ? "text-sm  lg:text-2xl" : "text-lg lg:text-5xl") +
          " outfitFont font-semibold  whitespace-nowrap text-left capitalize text-main h-8 md:h-12"
        }
      >
        {props.value}
      </h3>
      {/* <p className="text-xs md:text-sm text-[#A9A9A9] font-semibold  whitespace-nowrap capitalize">
        {props.date}
      </p> */}
    </div>
  );
};

export default Statistic;
