import axios from "axios";
import { useEffect, useState } from "react";
import { ICRMClient, ICRMSingleClient } from "../../interfaces/crm";
import ModalContainer from "../Modals/ModalContainer";
import CRMAppointments from "./CRMAppointments";
import CRMOverview from "./CRMOverview";
interface Props {
  setModalOpen: (e: boolean) => void;
  modalOpen: boolean;
  clientDetails?: ICRMClient;
}
export default function ClientModal({
  setModalOpen,
  modalOpen,
  clientDetails,
}: Props) {
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [modalProps, setModalProps] = useState<ICRMSingleClient | undefined>();
  useEffect(() => {
    const getClient = async () => {
      try {
        setIsLoading(true);
        const {
          data: { data },
        } = await axios.get(`/api/crm/client/${clientDetails?.customer?.id}`);
        setModalProps({ ...clientDetails, ...data });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    modalOpen && getClient();
  }, [clientDetails, refresh, modalOpen]);
  return (
    <ModalContainer
      modalOpen={modalOpen}
      closeModal={() => {
        setModalProps(undefined);
        setActiveTab("overview");
        setIsLoading(true);
        setModalOpen(false);
      }}
      size="crm"
      crm
    >
      <div className="">
        <div className="flex space-x-4 mb-5">
          {["overview", "appointments"].map((tab: string, tabIndex: number) => {
            return (
              <div
                key={tabIndex}
                className={
                  " cursor-pointer text-white p-2 px-5 rounded-md text-sm hover:bg-[#579AF5] capitalize font-medium " +
                  (activeTab === tab ? " bg-[#579AF5] " : " bg-[#A9A9A9]")
                }
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                {tab}
              </div>
            );
          })}
        </div>
        {activeTab === "overview" ? (
          <CRMOverview
            gotoAppointments={() => setActiveTab("appointments")}
            details={modalProps}
            isLoading={isLoading}
          />
        ) : activeTab === "appointments" ? (
          <CRMAppointments
            details={modalProps}
            refresh={() => setRefresh(!refresh)}
          />
        ) : (
          <div>others</div>
        )}
      </div>
    </ModalContainer>
  );
}
