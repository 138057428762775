import axios from "axios";
import { useEffect, useState } from "react";
import Statistic from "./Statistic";
interface statisticsType {
  appointmentsCount: number;
  leastBookedService: string;
  mostBookedService: string;
  totalHours: number;
}
const Appointment = () => {
  const [statistics, setStatistics] = useState<statisticsType>({
    appointmentsCount: 0,
    leastBookedService: "",
    mostBookedService: "",
    totalHours: 0,
  });
  useEffect(() => {
    getAnalytics();
  }, []);
  const getAnalytics = async () => {
    const {
      data: { data },
    } = await axios.get(`/api/analytics/dashboard/staff`);
    setStatistics(data);
  };
  return (
    <div className="z-10 h-[92vh] p-3 my-3 mr-3 bg-white lg:p-6 dropshadow rounded-xl w-72">
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-semibold text-left lg:text-base">
          Statistics
        </h2>

        {/* <Dropdown text="This Week" values={[]} getValue={undefined} /> */}
      </div>
      <div className="mt-6 space-y-10">
        <div className="flex justify-between">
          <Statistic
            icon="carbon:task-asset-view"
            type=" Appointments"
            value={statistics.appointmentsCount}
            date="This week"
          />
          <Statistic
            icon="ant-design:clock-circle-outlined"
            type=" Hours"
            value={statistics.totalHours}
            date="This week"
          />
        </div>

        <Statistic
          icon="ic:outline-local-offer"
          type="Your Most Booked Service"
          value={statistics.mostBookedService}
          smallertext
          date="This week"
        />

        <Statistic
          icon="ic:outline-local-offer"
          type="Your Least Booked Service"
          value={statistics.leastBookedService}
          smallertext
          date="This week"
        />
      </div>
    </div>
  );
};

export default Appointment;
