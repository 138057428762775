import React from "react";
import loadingstate from "./../assets/images/loadingstate.svg";
const EmptyState = (props: { text?: string; fetching?: boolean }) => {
  return (
    <div className="relative flex items-center justify-center p-8 text-center">
      <p className="mt-4 text-sm text-gray-500"></p>

      <div className="flex items-center justify-center my-10 w-96 h-96">
        <div>
          <img src={loadingstate} alt="" className="object-contain w-9/12" />
          <h2 className="py-5 text-sm font-medium text-center ">
            {props.text}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
