import React, { useState } from "react";
interface Props {
  toggle: any;
}
export default function Toggle(props: Props) {
  const [yearly, setYearly] = useState(false);
  const [monthly, setMonthly] = useState(true);
  const className = " bg-blue-600 text-white ";
  const handleActive = (e: string) => {
    props.toggle(e);

    if (e === "monthly") {
      setYearly(false);
      setMonthly(true);
    } else if (e === "yearly") {
      setMonthly(false);
      setYearly(true);
    }
  };
  return (
    <div className="   ">
      <div className=" bg-white text-black flex justify-between  py-3  rounded-full">
        <button
          className={(monthly && className) + "rounded-full py-2 px-5"}
          onClick={() => {
            handleActive("monthly");
          }}
        >
          Monthly
        </button>
        <button
          className={(yearly && className) + "rounded-full  py-2 px-5"}
          onClick={() => {
            handleActive("yearly");
          }}
        >
          Yearly
        </button>
      </div>
    </div>
  );
}
