import React from "react";

interface InputProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  accept?: string;
  value?: string | number;
  id?: string;
  name?: string;
  bottomSpace?: boolean;
}

export const Input: React.FC<InputProps> = ({
  label,
  placeholder = label,
  onChange,
  type = "text",
  disabled,
  required,
  accept,
  value,
  id,
  name,
  bottomSpace,
}) => {
  return (
    <>
      <div className="flex flex-col w-full">
        <label
          className={
            (!bottomSpace && " mb-2 ") +
            "  text-[10px] text-left text-black font-bold"
          }
        >
          {label}
        </label>
        <input
          className="w-auto h-auto px-2 py-2 text-sm border border-solid rounded-md placeholder:text-gray-400 border-darkergrey bg-lightergrey focus:outline-0 focus:border-blue-300"
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          disabled={disabled}
          required={required}
          accept={accept}
          value={value}
          id={id}
          name={name}
        />
      </div>
    </>
  );
};
