import { store } from "../store/store";
import getSymbolFromCurrency from "currency-symbol-map";

const useCurrency = (amount: number | string) => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  const symbol = getCurrencySymbol();

  return `${symbol}${Number(amount).toLocaleString(userLocale, {
    maximumFractionDigits: 2,
  })}`;
};

export const getCurrencySymbol = (): string => {
  const currency =
    store
      .getState()
      .business.business.address?.state.country.currency?.toUpperCase() ??
    "CAD";

  const symbol = getSymbolFromCurrency(currency);
  if (symbol === undefined) {
    return "#";
  }
  return symbol;
};

export default useCurrency;
