import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/Base/PageHeader";
import CartRetention from "../components/CartRetention";
// import ComingSoon from "../components/ComingSoon";
import PeakBookingTimes from "../components/PeakBookingTimes";
import ProfileTraffic from "../components/ProfileTraffic";
import Ratings from "../components/Ratings";
import Revenue from "../components/Revenue";
import RevenueGrowth from "../components/RevenueGrowth";
import { useAppSelector } from "../store/hooks";
const Analytics = () => {
  const [subscriptionStatus, setsubscriptionStatus] = useState<boolean>(false);
  const subStatus = useAppSelector(
    (state) => state.business.business.subscription
  );
  useEffect(() => {
    subStatus &&
      !["Free", "Basic"].includes(subStatus.plan.name) &&
      setsubscriptionStatus(subStatus.active);
  }, [subStatus]);
  return (
    <>
      {/* <div className="flex items-center justify-center">
        <ComingSoon
          text="The analytics feature"
          subtext="View your statistics, business growth and rating average in analytics."
        />
      </div> */}
      {true && (
        <div className="overflow-y-auto h-screen">
          <PageHeader text="Analytics" />

          <Revenue />

          <div className="flex gap-8 mb-8">
            {subscriptionStatus && (
              <div className="md:w-[510px]">
                <CartRetention />
              </div>
            )}

            <div className="md:w-[510px]">
              <Ratings />
            </div>

            <div className="col-span-1">
              <div className="">
                <ProfileTraffic />
              </div>
            </div>
          </div>
          {subscriptionStatus && (
            <>
              <div className="my-10">
                <PeakBookingTimes />
              </div>
              <div className="md:w-[510px]">
                <RevenueGrowth />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Analytics;
