import { Routes, Route, NavLink } from "react-router-dom";
import { PageHeader } from "../components/Base/PageHeader";
import BusinessProfile from "../components/BusinessPages/BusinessProfile";
import BusinessDocumentation from "../components/BusinessPages/BusinessDocumentation";
import BusinessOwnerDetails from "../components/BusinessPages/BusinessOwnerDetails";
import Subscription from "../components/BusinessPages/Subscription";
import BusinessCustomization from "../components/BusinessPages/BusinessCustomization";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { business as businessType } from "../interfaces/business";
const BusinessInfo = () => {
  const [business, setBusiness] = useState<businessType>({
    addressId: 0,
    categoryId: 0,
    code: "",
    createdAt: "",
    deletedAt: "",
    email: "",
    id: 0,
    isVerified: false,
    live: false,
    logo: "",
    name: "",
    updatedAt: "",
    userId: 0,
  });
  const StoreBusiness = useAppSelector((state) => state.business);
  useEffect(() => {
    setBusiness(StoreBusiness.business);
  }, []);
  useEffect(() => {}, [business]);

  const tabs = [
    {
      tabTitle: "Profile",
      content: <BusinessProfile business={business} />,
      path: "/dashboard/businessinfo",
      routepath: "/",
    },
    {
      tabTitle: "Documentation",
      content: <BusinessDocumentation />,
      path: "documentation",
    },
    {
      tabTitle: "Owner Details",
      content: <BusinessOwnerDetails business={business} />,
      path: "ownerdetails",
    },
    {
      tabTitle: "Customization",
      content: <BusinessCustomization />,
      path: "customization",
    },
    {
      tabTitle: "Subscription",
      content: <Subscription />,
      path: "subscription",
    },
  ];

  const navLinkStyles = ({ isActive }: { isActive: Boolean }) => {
    return {
      borderBottom: isActive ? "2px solid #000" : "none",
      fontWeight: isActive ? "600" : "",
    };
  };
  return (
    <div className="">
      <PageHeader text="Business Info" />
      <div className="flex pt-5 space-x-8 overflow-x-auto text-sm lg:space-x-14 lg:text-base">
        {tabs.map((tab, i) => (
          <NavLink
            to={tab.path}
            end={true}
            style={navLinkStyles}
            key={i}
            className="pb-1"
          >
            {tab.tabTitle}
          </NavLink>
        ))}
      </div>
      <div className="overflow-auto h-[80vh]">
        <Routes>
          {tabs.map((tab, i) => (
            <Route
              path={tab.routepath ? tab.routepath : tab.path}
              key={i}
              element={tab.content}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export default BusinessInfo;
