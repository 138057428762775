import { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Toast } from "../Base/toast";
import { Button } from "../Base/Button";
import { useAppDispatch } from "../../store/hooks";
import { getactiveBusiness } from "../../features/business/business";

interface Props {
  close: any;
}
const CheckoutForm = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isloading, setIsloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    try {
      setIsloading(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const { error } = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_WEBSITE}/dashboard/success`,
        },
      });
      // return_url: "https://merchant-web.vercel.app/dashboard/success",

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        if (error.message) {
          Toast(error.message, "error");
        }
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (e) {
      console.error(e);
    } finally {
      props.close();

      setIsloading(false);
      dispatch(getactiveBusiness());
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="flex justify-end space-x-5 mt-5">
          <Button disabled={isloading} type="Submit">
            {isloading ? "loading..." : "Submit"}{" "}
          </Button>

          <Button
            variant="bg-danger"
            onClick={() => {
              props.close();
            }}
          >
            CLOSE{" "}
          </Button>
        </div>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};

export default CheckoutForm;
