import { useEffect, useState } from "react";
import { PageHeader } from "../../components/Base/PageHeader";
import { useNavigate } from "react-router-dom";
import giftVendor1 from "../../assets/images/amazonLogo.jpeg";
import giftVendor2 from "../../assets/images/204FuelLogo.png";
import giftVendor3 from "../../assets/images/bestBuyLogo.jpeg";
import giftVendor4 from "../../assets/images/homeDepotLogo.jpeg";
import giftVendor5 from "../../assets/images/ronaLogo.jpeg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import axios from "axios";
import Spinner from "../../components/Base/Spinner";
import {
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";

interface GiftVendor {
  name: string;
  image: string;
}

const GiftLoyaltyProgram = () => {
  const giftVendorImages: GiftVendor[] = [
    { name: "Amazon", image: giftVendor1 },
    { name: "204 Fuels", image: giftVendor2 },
    { name: "Best Buy", image: giftVendor3 },
    { name: "Home Depot", image: giftVendor4 },
    { name: "Rona", image: giftVendor5 },
  ];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);
  const [loading, setIsloading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<GiftVendor | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getProgramPoints = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`);
      dispatch(SET_LOYALTY_POINT(data));
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getProgramPoints();
  }, []);

  const handleRedeemClick = () => {
    if (selectedVendor) {
      setShowModal(true); // Show modal if a vendor is selected
    } else {
      alert("Please select a gift card vendor before redeeming."); // Alert if no vendor selected
    }
  };

  const confirmRedemption = () => {
    // Add logic to handle redemption
    setShowModal(false);
    setShowSuccessModal(true);
  };

  return (
    <div className="h-screen w-full flex flex-col">
      {/* Flex container to align header text and arrow in one line */}
      <div className="flex items-center">
        {/* Back Arrow */}
        <span
          className="text-2xl font-bold cursor-pointer mr-2"
          onClick={() => navigate(-1)} // Go back to the previous page
        >
          ⬅ {/* Thicker left arrow character */}
        </span>

        {/* Header Text */}
        <PageHeader text="Redeem as Gift Card" />
      </div>

      <div className="flex text-2xl font-bold">
        <p className="text-lg">Gift Card Total</p>
      </div>

      <div className="flex text-2xl font-bold mt-3">
        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          `$${(loyaltyPoints / 10).toLocaleString()}`
        )}{" "}
      </div>

      <div className="flex text-lg font-bold mt-6">
        <span>Select Gift Card Vendor</span>
      </div>
      {/* Vendor Grid */}
      <div className="flex flex-wrap justify-between gap-y-6 gap-x-2 mt-6">
        {giftVendorImages.map((vendor, index) => (
          <div
            key={index}
            className={`w-[45%] h-[80px] bg-gray-200 flex items-center justify-start text-gray-800 font-bold shadow-md px-4 overflow-hidden cursor-pointer ${
              selectedVendor?.name === vendor.name
                ? "border-2 border-blue-500"
                : ""
            }`}
            onClick={() => setSelectedVendor(vendor)}
          >
            <img
              src={vendor.image}
              alt={vendor.name}
              className="w-[75px] h-[75px] object-contain mr-4"
            />
            <span className="text-gray-800 text-lg font-semibold">
              {vendor.name}
            </span>
          </div>
        ))}
      </div>

      {/* Button Container */}
      <div className="w-full bg-white p-10 flex justify-end">
        <button
          onClick={handleRedeemClick}
          className="rounded w-40 p-3 bg-[#001C43] text-center text-sm font-bold text-white shadow-lg"
        >
          <span>Redeem</span>
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
            <div className="flex flex-col items-center">
              <p className="text-center text-md font-semibold mb-4">
                Are you sure you want to redeem your gift card with{" "}
                {selectedVendor?.name}?
              </p>
              <img
                src={selectedVendor?.image}
                alt={selectedVendor?.name}
                className="w-[80px] h-[80px] object-contain mb-4"
              />
              <div className="flex justify-between gap-4">
                <button
                  onClick={confirmRedemption}
                  className="w-[100px] px-4 py-2 bg-green-500 font-bold rounded-lg"
                >
                  YES
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="w-50 px-4 py-2 bg-red-500 font-bold rounded-lg"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
              onClick={() => setShowSuccessModal(false)}
            >
              ✖
            </button>
            <div className="flex flex-col items-center">
              <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-100 mb-4">
                <span className="text-green-500 text-5xl">✔</span>
              </div>
              <p className="text-center text-md font-semibold">
                Gift Card has been redeemed successfully
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftLoyaltyProgram;
