import axios from "axios";
import { useEffect, useState } from "react";

import { PageHeader } from "../components/Base/PageHeader";
import EmptyReviews from "../components/EmptyReviews";
import Review from "../components/Review";
import { SkeletonReviews } from "../components/SkeletonImage";
import { selectReviews, SET_REVIEWS } from "../features/pages/pages";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const Reviews = () => {
  const dispatch = useAppDispatch();
  const storeReviews = useAppSelector(selectReviews);
  const [isLoading, setIsloading] = useState(false);

  const getReviews = async () => {
    try {
      !storeReviews.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/review`);
      dispatch(SET_REVIEWS(data));
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <div className="h-screen ">
      <div className="h-full overflow-x-hidden ">
        <PageHeader text="Reviews" />
        {isLoading ? (
          <div className="grid grid-cols-3 gap-5">
            {[...Array(8)].map((_, i) => {
              return <SkeletonReviews key={i} />;
            })}
          </div>
        ) : !storeReviews.length ? (
          <EmptyReviews />
        ) : (
          <div className="grid grid-cols-3 gap-5">
            {storeReviews.map((review, reviewIndex) => (
              <Review review={review} key={reviewIndex} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
