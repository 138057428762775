/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../components/BusinessPages/CheckoutForm";
import { useAppSelector } from "../store/hooks";
import { selectSecret } from "../features/payments/payments";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

let stripePromise: any;
if (publicKey && !stripePromise) {
  stripePromise = loadStripe(publicKey);
}

interface Props {
  close: any;
}
const SubscriptionCheckout = (props: Props) => {
  const clientSecret = useAppSelector(selectSecret);

  const options = {
    clientSecret,
    // Fully customizable with appearance API.
    // see docs here https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements&html-or-react=react#web-create-intent
    appearance: {
      /* ... */
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm close={props.close} />
    </Elements>
  );
};
export default SubscriptionCheckout;
