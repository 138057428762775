import { Icon } from "@iconify/react";
import React from "react";
interface Props {
  from: number;
  to: number;
  records: number;
  prev: () => void;
  next: () => void;
  currentPage: number;
}
export default function StaffPagination({
  from,
  to,
  records,
  prev,
  next,
  currentPage,
}: Props) {
  return (
    <div className="flex items-center space-x-5">
      {currentPage > 1 && (
        <div
          className="flex items-center text-gray-400 text-xs space-x-1 cursor-pointer"
          onClick={() => {
            prev();
          }}
        >
          <Icon icon="akar-icons:arrow-left" />
          <span>Prev</span>
        </div>
      )}

      <div>
        Showing {from}-{to} of {records} results
      </div>

      {!(to >= records) && (
        <div
          className="flex items-center text-black text-xs space-x-1 cursor-pointer"
          onClick={() => {
            next();
          }}
        >
          <span>Next</span>
          <Icon icon="akar-icons:arrow-right" />
        </div>
      )}
    </div>
  );
}
