/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "@iconify/react";
import { CheckBox } from "../Base/Checkbox";
import { Input } from "../Base/Input";
import { Formik, Field, Form } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { CustomizationInput } from "../Base/CustomizationInput";
import { DataContext } from "./Tabs";
import { Button } from "../Base/Button";
interface Props {
  services: [];
  addCustomization?: any;
  isEdit?: boolean;
  addCustomisation?: any;
  appear?: boolean;
  add?: any;
  delete?: (e: any) => void;
  scroll?: boolean;
}

interface arrayType {
  name: string;
  option: boolean;
  value: string[];
}

const CustomizationOptions = (props: Props) => {
  const { ContextCustomisation, setContextCustomisation } =
    useContext(DataContext);

  // const [array, setArray] = useState<arrayType[]>([]);
  const [checkOption, setCheckOption] = useState(false);
  const [valueArray, setValueArray] = useState<string[]>([]);
  const [localValue, setLocalValue] = useState("");
  const [clearState, setClearState] = useState(false);

  const hanldeAddCustomisation = () => {
    props.addCustomisation(ContextCustomisation);
  };
  useEffect(() => {
    setTimeout(() => {
      scrolltoBottom();
    }, 300);
  }, [props.scroll]);
  useEffect(() => {
    if (checkOption) {
      const formatted = ContextCustomisation.map(
        (service: any, index: number) => {
          return {
            ...service,
            value: [],
          };
        }
      );
      setContextCustomisation(formatted);
    }
  }, [checkOption]);
  useEffect(() => {
    hanldeAddCustomisation();
  }, [ContextCustomisation]);
  useEffect(() => {
    props.isEdit && setContextCustomisation([...props.addCustomization]);
  }, [props.addCustomization]);

  const handleName = (e: string, index: number) => {
    const formated = ContextCustomisation;
    formated[index].name = e;
    setContextCustomisation([...formated]);
  };

  const handleLocalValue = (value: string, index: number) => {
    setLocalValue(value);
  };
  const deleteValue = (parentIndex: number, childIndex: number) => {
    const formatted = ContextCustomisation.map(
      (service: any, index: number) => {
        if (index === parentIndex) {
          return {
            ...service,
            value: service.value.filter(
              (_val: any, i: number) => i !== childIndex
            ),
          };
        } else return service;
      }
    );
    setContextCustomisation(formatted);
  };
  const itemToDelete = (index: number) => {
    setContextCustomisation((item: arrayType[]) =>
      item.filter((item: arrayType, i: number) => i !== index)
    );
    props.delete && props.delete(index);
  };
  const bottomRef2 = useRef<null | HTMLDivElement>(null);
  const scrolltoBottom = () => {
    bottomRef2.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={`min-h-[210px] p-4 mt-5 space-y-4 overflow-y-scroll bg-white rounded-md max-h-56 ${
        props.appear ? "block" : "hidden"
      }`}
    >
      {ContextCustomisation.length ? (
        ContextCustomisation.map((service: any, index: number) => {
          const toggle = (checked: boolean) => {
            if (checked) {
              const newService = {
                ...service,
                option: !service.option,
                value: [],
              };
              const formatted = ContextCustomisation.map(
                (service: any, serviceIndex: number) => {
                  if (serviceIndex === index) {
                    return newService;
                  } else {
                    return service;
                  }
                }
              );
              setContextCustomisation(formatted);
            } else {
              const newService = { ...service, option: !service.option };
              const formatted = ContextCustomisation.map(
                (service: any, serviceIndex: number) => {
                  if (serviceIndex === index) {
                    return newService;
                  } else {
                    return service;
                  }
                }
              );
              setContextCustomisation(formatted);
            }
          };
          const addValueToArray = async (valIndex: number) => {
            if (service.option) return;
            if (localValue) {
              const formated = ContextCustomisation;
              // formated[valIndex].value.push(localValue);
              formated[valIndex] = {
                ...formated[valIndex],
                value: [...formated[valIndex].value, localValue],
              };
              setContextCustomisation([...formated]);

              await setValueArray([...valueArray, localValue]);
              setClearState((prev) => !prev);
            }
          };
          // here
          return (
            <Formik
              initialValues={{
                name: service ? service.name : "",
                stringValue: service ? "" : "",
                option: service ? service.option : checkOption,
              }}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
              }}
              key={index}
            >
              {({ isSubmitting, errors, submitForm, touched, values }) => (
                <Form>
                  <div className=" w-full  bg-[#D6D3D8] rounded p-3">
                    <div className="flex items-center justify-between">
                      <div className="w-44">
                        <Field
                          name="name"
                          as={Input}
                          label="Option Name"
                          placeholder="Hair Type"
                          value={service.name ? service.name : ""}
                          onChange={(e: any) => {
                            handleName(e.target.value, index);
                            setTimeout(() => {
                              submitForm();
                            });
                          }}
                        />
                      </div>
                      <div className="w-40">
                        <Field
                          name="stringValue"
                          as={CustomizationInput}
                          label="Value"
                          placeholder="3D"
                          clearState={clearState}
                          disabled={service.option}
                          onChange={(e: any) => {
                            handleLocalValue(e.target.value, index);
                          }}
                        />
                      </div>
                      <div
                        onClick={async () => await addValueToArray(index)}
                        className="flex items-center justify-center h-6 px-2 mt-4 ml-3 text-white rounded-md cursor-pointer bg-main"
                      >
                        <Icon icon="akar-icons:plus" width="10" />
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                      <div className="flex flex-wrap">
                        {service.value
                          ? service.value.map(
                              (value: string, serviceIndex: number) => (
                                <div
                                  key={serviceIndex}
                                  className="flex items-center justify-between h-6 px-3 mx-2 mt-4 text-white cursor-pointer rounded-xl bg-main"
                                >
                                  <span className="text-[8px]">{value}</span>
                                  <span
                                    className="ml-2 text-danger"
                                    onClick={() => {
                                      deleteValue(index, serviceIndex);
                                    }}
                                  >
                                    <Icon
                                      icon="akar-icons:circle-x"
                                      width="12"
                                    />
                                  </span>
                                </div>
                              )
                            )
                          : ContextCustomisation.map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center h-6 px-3 mx-2 mt-4 space-x-3 text-white cursor-pointer rounded-xl bg-main"
                              >
                                <span className="text-[8px] text-white">
                                  {item.value}
                                </span>
                                <span className="ml-2 text-danger">
                                  <Icon icon="akar-icons:circle-x" width="12" />
                                </span>
                              </div>
                            ))}
                      </div>

                      <div className="flex items-center ">
                        <div className="relative flex cursor-pointer items-center customcheckbox">
                          <Field
                            id={index}
                            name="option"
                            as={CheckBox}
                            label="Accept User Input"
                            placeholder="Hair Type"
                            value={service.option}
                            onChange={(e: any) => {
                              // setCheckOption(e.target.checked);
                              toggle(e.target.checked);
                            }}
                          />
                        </div>
                        <div
                          onClick={() => itemToDelete(index)}
                          className="flex items-center justify-center h-6 px-1  ml-3 text-white rounded-sm cursor-pointer bg-danger"
                        >
                          <Icon icon="ci:trash-empty" width="12" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          );
        })
      ) : (
        <div className="flex justify-center items-center h-[160px] text-center">
          <div className="">
            <p className="py-2 font-[500]">
              You have no <br /> customisations
            </p>
            <Button
              onClick={() => {
                props.add();
              }}
            >
              <span>ADD CUSTOMISATION</span>
            </Button>
          </div>
        </div>
      )}
      <div ref={bottomRef2}></div>
    </div>
  );
};

export default CustomizationOptions;
