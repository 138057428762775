import { Route, Routes } from "react-router-dom";
import BusinessClassification from "../../components/Register/BusinessClassification";
import BusinessIdentification from "../../components/Register/BusinessIdentification";
import Documentation from "../../components/Register/Documentation";

const Signup = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<BusinessIdentification />} />
        <Route path="classification" element={<BusinessClassification />} />
        <Route path="documentation" element={<Documentation />} />
      </Routes>
    </>
  );
};

export default Signup;
