import React from "react";
import { Button } from "../components/Base/Button";
interface PropsType {
  text: string;
  buttonText: string;
  image: any;
  handleClick: any;
}
export default function NewEmptyState(props: PropsType) {
  return (
    <div className=" h-[85%] flex items-center justify-center">
      <div className="text-center ">
        <img src={props.image} alt="" className="object-contain mx-auto" />
        <p className="py-2 font-[00] interFamily">
          {props.text} <br /> available
        </p>
        <Button className="bg-secondary" onClick={props.handleClick}>
          <span className="text-xs my-1 font-semibold subscriptionFont">
            {props.buttonText}
          </span>
        </Button>
      </div>
    </div>
  );
}
