import { createContext, Dispatch, SetStateAction } from 'react';

interface ThemeContext {
  businessType: string;
  setBusinessType?: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  businessType: 'merchant',
};
export const BusinessTypeContext = createContext<ThemeContext>(defaultState);
