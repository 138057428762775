import { useState, useRef } from "react";
import { Button } from "../Base/Button";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Input } from "../Base/Input";
import ModalContainer from "./ModalContainer";
import { InputValidationError } from "../InputValidationError";
import useFileUpload from "../../hooks/useFileUpload";
import axios from "axios";
import Spinner from "../Base/Spinner";
import { Toast } from "../Base/toast";
interface Props {
  closeModal: any;
  title?: string;
  modalOpen: boolean;
}

const AddStaffModal = (props: Props) => {
  const { submitFile } = useFileUpload();
  const inputFileRef = useRef<any>(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [submitingStaff, setSubmitingStaff] = useState(false);
  const [submittingFile, setSubmittingFile] = useState(false);

  const onChange = async (event: any) => {
    setSubmittingFile(true);
    const file = event.target.files[0];
    const imageUrl = await submitFile(file, "staffProfile");
    if (imageUrl) setSelectedFile(imageUrl);
    setSubmittingFile(false);
  };

  const sendInvite = async (form: any) => {
    setSubmitingStaff(true);
    try {
      await axios.post(`/api/staff/invitation`, {
        email: form.email,
        designation: form.designation,
        accessDetails: {
          firstName: form.firstName,
          lastName: form.lastName,
          profilePic: selectedFile,
          phone: String(form.number),
        },
      });
      setSubmitingStaff(false);
      setSelectedFile("");
      Toast("Invite sent successfully", "success");
    } catch (error: any) {
      console.error("error", error.response.data.errors);
      setSubmitingStaff(false);
    } finally {
      props.closeModal(false);
    }
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Please enter the staffs email")
      .email("Please enter a valid email address"),
    number: yup.string().required("Please enter the staffs phone number"),
    firstName: yup.string().required("Please enter the staffs first name"),
    lastName: yup.string().required("Please enter the staffs last name"),
    designation: yup.string().required("Please enter the staffs designation"),
  });

  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={() => {
        props.closeModal();

        setSelectedFile("");
      }}
      size="small"
    >
      <Formik
        initialValues={{
          email: "",
          number: "",
          firstName: "",
          lastName: "",
          designation: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          (async () => {
            await sendInvite(data);
          })();
        }}
        validationSchema={schema}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="w-full h-full p-5">
            <div className="p-2 sm:py-5 px-8 bg-[#f1f1f1] rounded-lg  ">
              <div>
                <div className="flex items-center my-3 space-x-3">
                  <input
                    type="file"
                    name="image"
                    multiple
                    hidden
                    ref={inputFileRef}
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />

                  {selectedFile ? (
                    <img
                      src={selectedFile}
                      alt="staff profile pic"
                      className="object-contain rounded-lg w-14 h-14"
                    />
                  ) : (
                    <div className="flex items-center justify-center border-2 rounded-lg w-14 h-14">
                      {submittingFile && (
                        <div className="pl-2 h-2/6">
                          <Spinner />
                        </div>
                      )}
                    </div>
                  )}

                  <Button
                    onClick={() => {
                      inputFileRef.current.click();
                    }}
                  >
                    Upload Image
                  </Button>
                </div>

                <div className="w-full">
                  <div className="py-2">
                    <Field
                      name="email"
                      type="email"
                      as={Input}
                      label="Email Address"
                      placeholder="isiahfree@gmail.com"
                    />

                    {errors.email && touched.email && (
                      <InputValidationError text={errors.email} />
                    )}
                  </div>
                  <div className="py-2">
                    <Field
                      type="number"
                      name="number"
                      as={Input}
                      label="Phone Number"
                      placeholder="+1 443-7364-838"
                    />

                    {errors.number && touched.number && (
                      <InputValidationError text={errors.number} />
                    )}
                  </div>
                  <div className="py-2">
                    <div className="flex flex-col justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row ">
                      <div className="md:w-[46%]">
                        <Field
                          name="firstName"
                          as={Input}
                          label="First Name"
                          placeholder="Isiah"
                        />

                        {errors.firstName && touched.firstName && (
                          <InputValidationError text={errors.firstName} />
                        )}
                      </div>
                      <div className="md:w-[46%]">
                        <Field
                          name="lastName"
                          as={Input}
                          label="Last Name"
                          placeholder="Freeman"
                        />

                        {errors.lastName && touched.lastName && (
                          <InputValidationError text={errors.lastName} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <Field
                      name="designation"
                      as={Input}
                      label="Designation"
                      placeholder="Barber"
                    />

                    {errors.designation && touched.designation && (
                      <InputValidationError text={errors.designation} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end pt-8">
              <Button
                type="submit"
                isSubmitting={submitingStaff}
                disabled={submitingStaff}
              >
                SEND INVITATION
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default AddStaffModal;
