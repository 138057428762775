import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'
import { PaymentState } from '../../interfaces/state'

// Define the initial state of the application
const initialState: PaymentState = {
    clientSecret: '',
}

export const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
     
        changeClientSecret:(state,action:PayloadAction<string>)=>{
            state.clientSecret = action.payload
        }
    },
    

})

export const { changeClientSecret } = paymentSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSecret = (state: RootState) => state.payments.clientSecret

export default paymentSlice.reducer