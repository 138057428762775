import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'
import { GlobalState } from '../../interfaces/state'

// Define the initial state of the application
const initialState: GlobalState = {
    requestInProgress: false,
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        toggleRequestInProgress: (state, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                state.requestInProgress = !state.requestInProgress
            } else {
                state.requestInProgress = action.payload;

            }
        },
    },

})

export const { toggleRequestInProgress } = loaderSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.loader.requestInProgress

export default loaderSlice.reducer