import { useEffect, useState } from "react";
import useAnalyticsHook from "../hooks/useAnalyticsHook";
import useCurrency from "../hooks/useCurrency";
import { revenueType } from "../interfaces/analytics";
import Dropdown from "./Dropdown";
import FullChart from "./Graphs/FullChart";
import OverlaySpinner from "./OverlaySpinner";

const Revenue = () => {
  const {
    dropDownOptions,
    dropDownText,
    duration,
    fetch,
    getValue,
    isloading,
    getData,
    noData,
    setNoData,
  } = useAnalyticsHook();
  const [wholeData, setWholeData] = useState<any>({
    duration: { start: "", end: "" },
  });
  const [revenue, setRevenue] = useState<revenueType>({
    percentage: 0,
    total: 0,
    chart: [
      {
        yaxis: 0,
        day: "",
        label: "",
      },
    ],
  });
  const getRevenue = async () => {
    const data = await getData(`/api/analytics/revenue/?interval=${duration}`);
    //  const data = await getData(`/api/appointment/analytics/?interval=${duration}`)
    if (!data.error) {
      // const revenue = data.revenue;
      setRevenue(data);
      setWholeData(data);

      const empty = data.chart.every((el: any) => el.yaxis === 0);
      setNoData(empty);
    }
  };
  useEffect(() => {
    getRevenue();
  }, [fetch]);
  return (
    <div>
      <div className="flex justify-between w-full h-full p-4 my-8 overflow-scroll md:my-5 md:p-8 bg-basegrey rounded-xl">
        <div className="w-full overflow-auto">
          <div className="flex flex-row justify-between space-x-8 md:mb-5 lg:space-x-0 lg:w-10/12">
            <p className="text-lg font-semibold">Revenue</p>

            <div className="flex-1 lg:flex-none">
              <div className="flex justify-between items-center">
                <div className="pr-3">
                  Showing Revenue from {wholeData.duration.start} till{" "}
                  {wholeData.duration.end}
                </div>
                <div>
                  <Dropdown
                    text={dropDownText}
                    values={dropDownOptions}
                    getValue={getValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <OverlaySpinner isLoading={isloading} noData={noData}>
            <div className="flex flex-col-reverse w-full space-x-5 overflow-x-scroll md:flex-row">
              <div className="flex-1 p-5 overflow-scroll bg-white md:p-10 xl:flex-none lg:w-10/12 rounded-xl h-96">
                <div className="">
                  <FullChart data={revenue} />
                </div>
              </div>

              <div className="flex items-end mt-5 space-x-10 overflow-x-scroll md:flex-row md:space-y-0">
                <div className="space-y-2">
                  <p className="text-xs md:text-sm font-semibold text-[#6F6F6F]">
                    Total <span className="md:block"> Revenue</span>
                  </p>
                  <p className="text-base font-semibold md:text-3xl">
                    {revenue.total && useCurrency(revenue.total)}
                  </p>
                  {revenue.percentage ? (
                    <p
                      className={
                        "pb-5  font-bold md:pb-0" +
                        (revenue.percentage < 0
                          ? " text-red-600 "
                          : " text-green-600 ")
                      }
                    >
                      {revenue.percentage && Math.floor(revenue.percentage)}%
                    </p>
                  ) : (
                    "0%"
                  )}
                </div>
              </div>
            </div>
          </OverlaySpinner>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
